import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";
import { device } from "theme";

export const SMain = styled.main`
  padding-top: 64px;
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 48px 20px;
  width: 100%;
`;

export const SWrap = styled.section`
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;

  @media ${device.tabletPlus} {
    flex-flow: row nowrap;
    gap: 32px;
  }
`;

export const SInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 6px;

  @media ${device.tabletPlus} {
    gap: 32px;
  }
`;

export const SItem = styled.div<{ isEmpty?: boolean }>`
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;

  & > div {
    width: 100%;
    display: flex;
    padding: ${(props) => (props.isEmpty ? "12px 12px 12px 12px" : "36px 12px 12px 12px")};
    align-items: center;
    gap: 5px;
    cursor: pointer;

    & > svg {
      & path {
        fill: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
      }
    }

    & > span {
      color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    }
  }

  @media ${device.tabletPlus} {
    & > div {
      padding: 24px;

      & > span {
        color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
      }
    }
  }
`;

export const SHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const SControl = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;

  display: flex;
  align-items: center;
  gap: 32px;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    cursor: pointer;
  }

  & > svg {
    width: 16px;
    height: 16px;
    fill: ${(props) => props.theme.mtsColor.grey.greyMedium};
    cursor: pointer;
  }

  @media ${device.tabletPlus} {
    position: relative;
    right: auto;
    top: auto;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const SInfoList = styled.ul`
  padding: 0 12px 12px 12px;

  & > li {
    display: flex;
    gap: 24px;

    &:not(:last-child) {
      padding-bottom: 5px;
      margin-bottom: 6px;
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }

    & > span {
      text-align: left;
      flex: 1 1 auto;

      &:not(:first-child) {
        text-align: right;
        flex: 1 1 auto;
      }
    }
  }

  @media ${device.tabletPlus} {
    padding: 24px;

    & > li {
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
        border: none;
      }

      & > span {
        text-align: left !important;

        &:first-child {
          flex: 0 0 225px;
        }
      }
    }
  }
`;

export const SOrder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;

  @media ${device.tabletPlus} {
    gap: 32px;
  }
`;

export const SContain = styled.div`
  padding: 12px;
  border: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;

  & > span {
    display: block;
    text-align: left;
  }

  @media ${device.tabletPlus} {
    padding: 24px;
  }
`;

export const SProdList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media ${device.tabletPlus} {
    gap: 16px;
  }
`;

export const SProdItem = styled.li`
  display: flex;
  gap: 15px;

  & > span {
    white-space: nowrap;
  }

  & > div {
    display: flex;
    flex: 1;
    text-align: left;

    & > span {
      white-space: nowrap;
      & span {
        padding-left: 10px;
        color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
      }
    }
  }

  &:not(:last-of-type) {
    padding-bottom: 3px;
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  }

  @media ${device.tabletPlus} {
    padding: 0;
    border: 0;
  }
`;

export const SCheque = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};

  & > section {
    margin-bottom: 32px;
  }

  @media ${device.tabletPlus} {
    padding: 32px 24px;
  }
`;

export const SPromoWrap = styled.div`
  margin-bottom: 20px;

  @media ${device.tabletPlus} {
    margin-bottom: 32px;
  }
`;

export const SSale = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};

  & > span:first-child {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }

  @media ${device.tabletPlus} {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
`;

export const STotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SPriceOld = styled.div`
  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    text-decoration: line-through;
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;

  & button {
    width: 100%;
  }
`;

export const SFieldBlock = styled.div`
  margin-bottom: 24px;

  & > label {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  & a {
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;

export const SCheckoutItem = styled.li<{ isDocument?: boolean }>`
  ${(props) =>
    props.isDocument
      ? `
		& > span:last-child {
			cursor: pointer;
			color: ${props.theme.mtsColor.text.primaryLink.lightMode}
		}
	`
      : ``};
`;

export const SDisclaime = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
