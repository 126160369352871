import { FC } from "react";
import Icon from "components/icons";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SBtns, SIcon, SWrap } from "./styles";

interface ModalNotifyBodyProps {
  title: string;
  isError?: boolean;
  isSuccess?: boolean;
  isNotAvailable?: boolean;
  isNotRegistration?: boolean;
  textDescrioption?: string; // если используется текст под заголовком без ссылки
  textBtn?: string;
  isShowBtnAlways?: boolean;
  isPrimaryBtn?: boolean;
  close?: () => void;
  onClick?: () => void;
}

export const ModalNotifyBody: FC<ModalNotifyBodyProps> = ({
  title,
  isError,
  isSuccess,
  textDescrioption,
  textBtn = "Закрыть",
  isPrimaryBtn = false,
  isShowBtnAlways = false,
  close,
  onClick,
}) => {
  const { isMobile } = useBreakpoint();
  const alwaysShowBtn = (isShowBtnAlways || isMobile()) && textBtn;

  return (
    <SWrap>
      <SIcon>
        {isError ? (
          <Icon.ErrorShaped sx={{ width: 80, height: 80 }} />
        ) : isSuccess ? (
          <Icon.CheckSucc sx={{ width: 80, height: 80 }} />
        ) : null}
      </SIcon>
      <CustomTypography variant="h4_bold" font="comp">
        {title}
      </CustomTypography>
      {textDescrioption && (
        <CustomTypography variant="p4_regular" font="comp">
          {textDescrioption}
        </CustomTypography>
      )}
      {alwaysShowBtn ? (
        <SBtns>
          <Spacer value="20px" />
          <MTSButton
            size="M"
            variant={isPrimaryBtn ? "primary" : "secondary"}
            onClick={() => (onClick ? onClick() : close?.())}
          >
            {textBtn}
          </MTSButton>
        </SBtns>
      ) : null}
    </SWrap>
  );
};
