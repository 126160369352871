/**
 * Преобразовывает телефон из маскированного ввода в 10 цифр.
 * +7 (912) 345-67-89 -> 9991234567
 */
export const unformatPhone = (formattedPhone: string | undefined): string => {
  return formattedPhone?.replace(/\D/g, "").substring(1) ?? "";
};

/**
 * Преобразовывает из сырых цифр в маскированный телефон
 * 79123456789 -> +7 (912) 345-67-89
 */

export const getPhoneWithSpaces = (value: string) => {
  if (value.length === 0) return "";

  let basePhone: string = value;

  if (value.substr(0, 2) === "+7") {
    basePhone = value.substr(2);
  } else if (value.length > 10 && value.substr(0, 1) === "8") {
    basePhone = value.substr(1);
  }

  let phone = basePhone.replace(/[^0-9]/g, "");

  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `+7 $1 $2 $3 $4`);
};

export const getNumberWithSpaces = (num: string | number): string | number => {
  let number = String(num);

  return number.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
};
