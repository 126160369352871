import { IHttpMethods } from "stream-constants/server-constants";
import {
  GET_SHOP_CHECKOUT,
  USER_BANK_INFO,
  USER_FEEDBACK_INFO,
  PLACE_ORDER,
  GET_PROMO,
  DELETE_USER_INFO,
  USER_INFO_UL,
  USER_INFO_FL,
  DOWNLOAD_FILE_ORG,
} from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";
import { IBankInfo, IDeleteInfo, IFeedbackInfo, IInformation, IPlaceOrder } from "interface/common";

export const getShopCheckout = async (): Promise<any> => {
  return fetchData({
    url: GET_SHOP_CHECKOUT,
    method: IHttpMethods.GET,
    name: "getShopCheckout",
    emptyResponse: false,
  });
};

export const addBankInfo = async (data: IBankInfo) => {
  return fetchData({
    url: USER_BANK_INFO,
    method: IHttpMethods.POST,
    name: "addBankInfo",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const addFeedbackInfo = async (data: IFeedbackInfo) => {
  return fetchData({
    url: USER_FEEDBACK_INFO,
    method: IHttpMethods.POST,
    name: "addFeedbackInfo",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const addInformationFl = async (data: IInformation) => {
  return fetchData({
    url: USER_INFO_FL,
    method: IHttpMethods.POST,
    name: "addInformationFizik",
    body: data,
    emptyResponse: false,
    contentType: "none",
  });
};

export const addInformationUl = async (data: IInformation) => {
  return fetchData({
    url: USER_INFO_UL,
    method: IHttpMethods.POST,
    name: "addInformationUrik",
    body: data,
    emptyResponse: false,
    contentType: "none",
  });
};

export const deleteInfo = async (data: IDeleteInfo) => {
  return fetchData({
    url: DELETE_USER_INFO,
    method: IHttpMethods.DELETE,
    name: "deleteInfo",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const placeOrder = async (data: IPlaceOrder) => {
  return fetchData({
    url: PLACE_ORDER,
    method: IHttpMethods.POST,
    name: "placeOrder",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const getCheckPromocode = async (promo: string) => {
  return fetchData({
    url: `${GET_PROMO}/${promo}`,
    method: IHttpMethods.GET,
    name: "getCheckPromocode",
    emptyResponse: false,
  });
};

export const getDownloadFileOrg = async (file: string) => {
  return await fetchData({
    url: `${DOWNLOAD_FILE_ORG}/${file}`,
    method: IHttpMethods.GET,
    name: "getDownloadFileOrg",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = file;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};
