import styled from "@emotion/styled";

export const SFieldFiles = styled.div<{ active: boolean; hasError: boolean | undefined }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px dashed ${(props) => props.theme.mtsColor.grey.greyMedium};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease all;

  ${(props) =>
    props.active
      ? `
	border-color: ${props.theme.mtsColor.text.primaryLink.lightMode};
	transition: 0.3s ease all;
	  `
      : ``}

  ${(props) =>
    props.hasError
      ? `
		border-color: ${props.theme.mtsColor.accent.negative.lightMode};
		transition: 0.3s ease all;
		  `
      : ``}

  &:hover {
    border-color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    transition: 0.3s ease all;
  }

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) =>
      props.hasError
        ? props.theme.mtsColor.accent.negative.lightMode
        : props.theme.mtsColor.text.primaryLink.lightMode};
    z-index: 0;
  }

  & > input {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 12px;
    z-index: 1;
    cursor: pointer;
  }
`;

export const SUploadSuccess = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;

    & span:last-child {
      color: ${(props) => props.theme.mtsColor.grey.greyDark};
    }
  }

  & > svg {
    fill: ${(props) => props.theme.mtsColor.grey.greyDark};
    cursor: pointer;
  }
`;
