import styled from "@emotion/styled";
import { MTS_COMPACT } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 80px 0 80px 0;
  text-align: left;
`;

export const SParag = styled.div`
  & > .MuiTypography-root {
    margin-bottom: 20px;
    display: block;

    & > span {
      font-family: ${MTS_COMPACT};
    }
  }
`;
