import styled from "@emotion/styled";
import theme, { device } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: inherit;
`;

export const SContainer = styled.section`
  max-width: 1304px;
  margin: 0 auto;
  padding: 78px 20px;
  width: 100%;
  display: flex;
  gap: 32px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  text-align: left;

  @media ${device.tabletPlus} {
    width: 75%;
  }

  & > img {
    max-height: 400px;
    width: 100%;
  }
`;

export const SAside = styled.aside`
  display: none;
  width: 25%;
  max-width: 292px;
  text-align: left;
  position: relative;

  @media ${device.tabletPlus} {
    display: block;
  }

  & > section {
    position: sticky;
    top: 88px;

    & > span {
      display: inline-block;
      margin-bottom: 16px;
    }

    & > ul {
      & > li {
        padding: 8px 0;
        cursor: pointer;

        &:hover {
          & > span {
            color: ${(props) => props.theme.mtsColor.text.headline.lightMode};
          }
        }

        &.active {
          & > span {
            color: ${(props) => props.theme.mtsColor.text.headline.lightMode};
          }
        }

        & > span {
          font-weight: 500;
          text-decoration: none;
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }
      }
    }
  }
`;

export const STitle = styled.h2`
  margin-bottom: 32px;
`;

export const SSubTitle = styled.h3`
  margin-bottom: 24px;
`;

export const SParag = styled.p`
  display: inline-block;
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  & a {
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    cursor: pointer;
  }
`;
export const SBlock = styled.div`
  margin-bottom: 48px;
`;

export const SList = styled.ul`
  margin-left: 10px;
  margin-bottom: 24px;
`;

export const SItem = styled.li`
  position: relative;
  padding-left: 15px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    height: 5px;
    width: 5px;
    border-radius: 3px;
    background-color: ${theme.mtsColor.background.primary.darkMode};
  }

  &:not(:last-child) {
    margin-bottom: 22px;
  }
`;

export const SBanner = styled.div<{ bannerImg: string }>`
  padding: 32px;
  background-image: url(${(props) => props.bannerImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
`;

export const SContent = styled.div`
  max-width: 625px;
  & > span {
    display: block;

    &:not(:last-of-type) {
      color: ${(props) => props.theme.mtsColor.text.headline.darkMode};
    }

    &:last-of-type {
      color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  & > div,
  & > a {
    display: flex;
    width: 100%;

    @media ${device.miniTabletPlus} {
      flex: 1 1 249px;
      max-width: 249px;
    }

    & button {
      width: 100%;
    }
  }
  & > a {
    & > div {
      width: 100%;
    }
  }
`;

export const SLink = styled.span`
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  cursor: pointer;
`;
