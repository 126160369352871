import { useContext } from "react";

import { ROUTE_MAILTO } from "stream-constants/route-constants";
import { ContextSettings } from "context/context-settings";
import { LIST_ROLES } from "utils/roles";
import { handleLogout, login, redirectToLk } from "services/auth";
import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MobMenuAccordion } from "components/mob-menu-accordion/mob-menu-accordion";
import { mobileMenuLinks } from "./const";
import { SBtns, SItem, SMenu, SOutBtn, SOverlay } from "./styles";

export const MobileMenu = ({ isOpen, setOpen }) => {
  const { isAuthorized, setIsAuthorized, roles } = useContext(ContextSettings);
  const isCustomer = roles.includes(LIST_ROLES.CUSTOMER);

  return (
    <>
      {isOpen ? <SOverlay /> : null}
      <SMenu isOpen={isOpen}>
        <MobMenuAccordion data={mobileMenuLinks} close={() => setOpen(false)} isArrowIcon />
        <SItem key={mobileMenuLinks.length}>
          <a href={ROUTE_MAILTO}>
            <CustomTypography variant="p3_regular">Связаться с поддержкой</CustomTypography>
          </a>
        </SItem>
        <SBtns>
          <MTSButton size="L" onClick={() => setOpen(true)}>
            Заказать консультацию
          </MTSButton>
          {!isAuthorized ? (
            <MTSButton size="M" variant="outline_dark" onClick={login}>
              Войти
            </MTSButton>
          ) : isCustomer ? (
            <SOutBtn
              size="M"
              variant="outline_dark"
              onClick={() => handleLogout(setIsAuthorized)}
              icon={<Icon.LogOut sx={{ width: 24, height: 24 }} />}
            >
              Выйти
            </SOutBtn>
          ) : (
            <MTSButton size="M" variant="outline_dark" onClick={redirectToLk}>
              Личный кабинет
            </MTSButton>
          )}
        </SBtns>
      </SMenu>
    </>
  );
};
