import { Link } from "react-router-dom";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SWrap, SIcon } from "./styles";
import { renderIcons } from "./const";

export interface IAdditInfoProp {
  title: string;
  icon: "check" | "router" | "ruble" | "cloud";
  link: string;
}

interface IProp {
  data: IAdditInfoProp[];
}

export const AdditInfoList = ({ data }: IProp) => (
  <SWrap>
    {data.map((item) => (
      <Link to={item.link} key={item.title}>
        <SIcon>{renderIcons(item.icon)}</SIcon>
        <CustomTypography variant="p3_regular" font="comp">
          {item.title}
        </CustomTypography>
      </Link>
    ))}
  </SWrap>
);
