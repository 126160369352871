import { useState } from "react";

import { EUserInfoCard, IBankInfo, IFeedbackInfo, IInformation } from "interface/common";
import { useBreakpoint } from "hooks/useBreakpoint";
import Icon from "components/icons";
import { MTSModal } from "components/mts-modal/mts-modal";
import { DeleteUserInfo } from "components/forms/shop-checkout/delete-user-info";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SControl, SHeader, SInfoList, SItem } from "./styles";
import { RenderItemsChekoutCards } from "./const";

interface IProp {
  title: string;
  data?: IBankInfo | IFeedbackInfo | IInformation | undefined | null;
  isEmpty?: boolean;
  typeCard: keyof typeof EUserInfoCard;
  isUrik?: boolean;
  onOpenForm: () => void;
}

export const ItemInfo = ({ title, data, isEmpty, typeCard, onOpenForm, isUrik }: IProp) => {
  const { isTablet } = useBreakpoint();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModal((s) => !s);
  };

  return (
    <>
      <SItem isEmpty={isEmpty}>
        {isEmpty ? (
          <div onClick={onOpenForm}>
            <Icon.Plus sx={{ width: 22, height: 22 }} />
            <CustomTypography
              variant={isTablet() ? "p3_medium" : "h4_medium"}
              font="comp"
              styles={{ textAlign: "left" }}
            >
              {title}
            </CustomTypography>
          </div>
        ) : (
          <>
            <SHeader>
              <CustomTypography
                variant={isTablet() ? "p3_medium" : "h4_medium"}
                styles={{ textAlign: "left" }}
              >
                {title}
              </CustomTypography>
              <SControl>
                <CustomTypography variant="c1_regular" onClick={onOpenForm} font="comp">
                  Редактировать
                </CustomTypography>
                <Icon.Close onClick={toggleDeleteModal} />
              </SControl>
            </SHeader>
            <SInfoList>{RenderItemsChekoutCards(data, typeCard, isUrik)}</SInfoList>
          </>
        )}
      </SItem>
      <MTSModal open={showDeleteModal} close={toggleDeleteModal}>
        <DeleteUserInfo onClose={toggleDeleteModal} typeInfo={typeCard} />
      </MTSModal>
    </>
  );
};
