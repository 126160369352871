import React from 'react';

interface ISpacerProps {
  value?: string | 0;
  style?: React.CSSProperties;
}

export const Spacer = React.forwardRef<HTMLDivElement, ISpacerProps>(
  ({ value = '20px', style = {} }, ref) => {
    return <div ref={ref} style={{ height: value, ...style }} />;
  }
);
