import { CustomTypography } from "components/customTypography/custom-typography";
import { SCard, SDescript, SIcon } from "./styles";

interface GridInfoCardProps {
  title?: string;
  description?: string;
  icon?: any;
}

export const GridInfoCard = (props: GridInfoCardProps) => {
  const { icon, title, description } = props;

  return (
    <SCard>
      <SIcon>
        <img src={icon} alt={title} />
      </SIcon>
      <h3>
        <CustomTypography variant="h4_medium" font="comp">
          {title}
        </CustomTypography>
      </h3>
      <SDescript>
        <CustomTypography variant="p4_regular" font="comp">
          {description}
        </CustomTypography>
      </SDescript>
    </SCard>
  );
};
