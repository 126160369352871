import { useContext } from "react";
import {
  SAbout,
  SAboutDescript,
  SAboutTitle,
  SBottom,
  SContainer,
  SCopyright,
  SIcon,
  SInfo,
  SItemLink,
  SLinks,
  SListItem,
  SText,
  STop,
  SWrap,
} from "./styles";
import Icon from "components/icons";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SectionTitle } from "components/section-title/section-title";
import { ContextSettings } from "context/context-settings";
import { CURRENT_YEAR } from "stream-constants/keys-and-constants";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { footerLinks } from "./const";

export const FooterFull = () => {
  const context = useContext(ContextSettings);

  return (
    <SWrap>
      <SContainer>
        <STop>
          <SText>
            <SectionTitle title="Остались вопросы?" isWhite={true} styles={{ margin: 0 }} />
            <Spacer value="16px" />
            <CustomTypography variant="p3_regular">
              Свяжитесь с нами и мы ответим на возникшие вопросы
            </CustomTypography>
            <Spacer value="48px" />
            <div>
              <MTSButton
                size="L"
                variant="primary"
                onClick={() => context.changeStateFormConsultation(true)}
              >
                Заказать консультацию
              </MTSButton>
            </div>
          </SText>
          <SIcon>
            <Icon.Faq />
          </SIcon>
        </STop>
      </SContainer>
      <SBottom>
        <SContainer>
          <SInfo>
            <SAbout>
              <SAboutTitle>
                <CustomTypography variant="p3_medium">EnergyTool</CustomTypography>
              </SAboutTitle>
              <SAboutDescript>
                <CustomTypography variant="p4_regular">
                  Комплекс программных решений, которые помогут оптимизировать энергетические
                  расходы вашей организации, выявить подозрительную активность абонентов и
                  автоматизировать процессы сбора и анализа данных
                </CustomTypography>
              </SAboutDescript>
            </SAbout>
            <SLinks>
              <ul>
                {footerLinks.map((item, index) => {
                  return (
                    <SListItem key={index}>
                      <SItemLink to={item.link}>
                        <CustomTypography variant="p4_regular">{item.name}</CustomTypography>
                      </SItemLink>
                    </SListItem>
                  );
                })}
              </ul>
            </SLinks>
          </SInfo>
          <SCopyright>
            <CustomTypography variant="p4_regular">
              © {CURRENT_YEAR} ENERGYTOOL. Все права защищены
            </CustomTypography>
          </SCopyright>
        </SContainer>
      </SBottom>
    </SWrap>
  );
};
