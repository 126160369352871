import { useContext } from "react";
import styled from "@emotion/styled";

import { useShopCatalog } from "hooks/hooks";
import Icon from "components/icons";
import { ContextSettings } from "context/context-settings";
import { ROUTE_SHOP_CART } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SCircle, SCont } from "./styles";

const SCart = ({ className = "" }) => {
  const { cart } = useContext(ContextSettings);
  const { data: catalog = [] } = useShopCatalog();

  const cartAmount = Object.entries(cart)
    ?.filter(([key, val]) => catalog.find((c) => c.id === parseInt(key)) != null)
    ?.map(([key, val]) => val)
    ?.reduce((tot, amount) => tot + amount.qty, 0);

  return (
    <SCont className={className} to={ROUTE_SHOP_CART}>
      <Icon.Cart sx={{ width: 16, height: 16 }} />
      <SCircle length={cartAmount}>
        <CustomTypography variant="c1_medium" font="comp">
          {cartAmount > 99 ? "99+" : cartAmount}
        </CustomTypography>
      </SCircle>
    </SCont>
  );
};

export const Cart = styled(SCart)``;
