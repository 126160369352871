import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ErrorShaped = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68597 1.68743C0.373095 3.00031 0.275748 4.3083 0.0810531 6.92429C0.0305833 7.60242 0 8.30194 0 9.00146C0 9.70099 0.0305834 10.4005 0.0810532 11.0786C0.275748 13.6946 0.373095 15.0026 1.68597 16.3155C2.99884 17.6284 4.30684 17.7257 6.92282 17.9204C7.60095 17.9709 8.30048 18.0015 9 18.0015C9.69952 18.0015 10.399 17.9709 11.0772 17.9204C13.6932 17.7257 15.0012 17.6284 16.314 16.3155C17.6269 15.0026 17.7243 13.6946 17.9189 11.0786C17.9694 10.4005 18 9.70099 18 9.00146C18 8.30194 17.9694 7.60242 17.9189 6.92429C17.7243 4.3083 17.6269 3.00031 16.314 1.68743C15.0012 0.37456 13.6932 0.277213 11.0772 0.082518C10.399 0.0320482 9.69952 0.00146484 9 0.00146484C8.30048 0.00146484 7.60095 0.0320482 6.92282 0.082518C4.30684 0.277213 2.99884 0.37456 1.68597 1.68743ZM12.2071 5.79241C12.5976 6.18293 12.5976 6.81609 12.2071 7.20662L10.414 8.99968L12.2071 10.7927C12.5976 11.1833 12.5976 11.8164 12.2071 12.207C11.8166 12.5975 11.1834 12.5975 10.7929 12.207L8.99983 10.4139L7.20711 12.2066C6.81658 12.5971 6.18342 12.5971 5.79289 12.2066C5.40237 11.8161 5.40237 11.1829 5.79289 10.7924L7.58562 8.99968L5.7929 7.20695C5.40237 6.81643 5.40237 6.18327 5.7929 5.79274C6.18342 5.40222 6.81658 5.40222 7.20711 5.79274L8.99983 7.58547L10.7929 5.79241C11.1834 5.40188 11.8166 5.40188 12.2071 5.79241Z"
        fill="#F95721"
      />
    </SvgIcon>
  );
};

export default ErrorShaped;
