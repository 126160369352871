import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
  max-width: 82%;

  @media ${device.desktopNeg} {
    max-width: 100%;
  }

  @media ${device.tabletNeg} {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }

  & > a {
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1 1 215px;
    text-decoration: none;

    @media ${device.tabletNeg} {
      flex: 1 1 auto;
    }

    & > .MuiTypography-root {
      color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
      text-align: left;
      white-space: pre-wrap;
    }
  }
`;

export const SIcon = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.mtsColor.base.blueberry}; ;
`;
