import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import Icon from "components/icons";
import { SDrop, SHead, SInner, SMain, SRow, SSub } from "./styles";
import { heading, mainOptions, subsByMain, urlsToCheck } from "./const";
import { CustomTypography } from "components/customTypography/custom-typography";

const SDeskMenu = ({ className = "" }) => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [main, setMain] = useState<string | undefined>(undefined);

  const close = () => {
    setOpen(false);
    setMain(undefined);
  };

  const handleEnter = (e) => setMain(e.target.id);

  return (
    <SDrop
      className={className}
      isActive={urlsToCheck.includes(location.pathname.split("/")[1])}
      onClick={() => {
        setOpen(!isOpen);
        setMain(undefined);
      }}
      isOpen={isOpen}
    >
      <span>{heading}</span>
      <Icon.ArrowDown sx={{ width: 16 }} />
      <SInner isOpen={isOpen} onMouseLeave={close}>
        <SHead>
          <CustomTypography variant="h4_medium" font="wide">
            {heading}
          </CustomTypography>
        </SHead>
        <SRow hasSelected={main != null && subsByMain[main] != null}>
          <div>
            {mainOptions.map((a) => (
              <SMain
                to={a.route}
                onMouseEnter={handleEnter}
                id={a.route}
                key={`main_${a.route}_${a.name}`}
                onClick={close}
              >
                <CustomTypography variant="p3_regular" font="comp">
                  {a.name}
                </CustomTypography>
                <Icon.ArrowRight sx={{ width: 24 }} />
              </SMain>
            ))}
          </div>
          <div>
            {main != null
              ? subsByMain[main]?.map((a) => (
                  <SSub to={a.route} key={`sub_${a.name}_${a.route}`}>
                    <CustomTypography variant="p3_regular" font="comp">
                      {a.name}
                    </CustomTypography>
                  </SSub>
                ))
              : null}
          </div>
        </SRow>
      </SInner>
    </SDrop>
  );
};

export const DeskMenu = styled(SDeskMenu)``;
