import React, { useState } from "react";

import { Radio } from "components/radio/radio.component";
import { Select } from "components/select/select";
import { Spacer } from "components/spacer/spacer.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MTSButton } from "components/mts-button/mts-button";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SFltBtn } from "./styles";
import Icon from "components/icons";

interface IProp {
  items: { val: string; friendlyName: string }[];
  onChange: Function;
  selected: string;
}

export const ShopSort: React.FC<IProp> = ({ onChange, items, selected }) => {
  const { isTablet } = useBreakpoint();
  const [isModOpen, setModOpen] = useState(false);

  return isTablet() ? (
    <>
      <SFltBtn onClick={() => setModOpen(true)}>
        <Icon.Sort sx={{ width: 24, height: 24 }} />
      </SFltBtn>
      <MTSModal open={isModOpen} close={() => setModOpen(true)}>
        <CustomTypography variant="h4_medium" font="comp">
          Сортировать список
        </CustomTypography>
        <Spacer value="24px" />
        <div style={{ padding: "0 0 0 22px" }}>
          {items.map((a, ind, self) => (
            <React.Fragment key={a.val}>
              <Radio
                id={a.val}
                name={a.val}
                value={a.val}
                checked={a.val === selected}
                onChange={() => onChange(a.val)}
                text={a.friendlyName}
                color="black"
              />
              {self.length - 1 !== ind ? <Spacer value="29px" /> : null}
            </React.Fragment>
          ))}
        </div>
        <Spacer value="22px" />
        <MTSButton
          size="S"
          variant="secondary"
          onClick={() => setModOpen(false)}
          style={{ width: "100%" }}
        >
          Отмена
        </MTSButton>
      </MTSModal>
    </>
  ) : (
    <>
      <Select
        size="S"
        initValue={items.find((a) => a.val === selected)?.friendlyName ?? "Не выбрано"}
        optionsList={items}
        onChange={onChange}
      />
    </>
  );
};
