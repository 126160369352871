import { CSSProperties, useRef } from "react";
import { CSSObject } from "@emotion/react";

import useOnClickOutside from "hooks/useOnClickOutside";
import Icon from "components/icons";

import { SBack, SClose, SCont, SHeader, defaultMaxWidth } from "./styles";
import { CustomTypography } from "components/customTypography/custom-typography";
import { useBreakpoint } from "hooks/useBreakpoint";

interface ISlidein {
  isOpen: boolean;
  close: () => void;
  headerChildren?: React.ReactNode;
  title?: string;
  zIndex?: number;
  direction?: "left" | "right";
  style?: CSSObject & CSSProperties;
  withArrow?: boolean;
  withCloseHook?: boolean;
  maxWidth?: number;
}
export const SlideIn: React.FC<ISlidein> = ({
  children,
  isOpen,
  close,
  headerChildren,
  title,
  zIndex,
  direction = "right",
  style,
  withArrow = true,
  withCloseHook = true,
  maxWidth = defaultMaxWidth,
}) => {
  const { isTablet } = useBreakpoint();

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, withCloseHook ? close : () => undefined);

  return (
    <SCont
      isOpen={isOpen}
      ref={containerRef}
      zIndex={zIndex}
      direction={direction}
      style={style}
      maxWidth={maxWidth}
    >
      {!withArrow ? (
        <SClose onClick={close}>
          <Icon.Close sx={{ width: 24, height: 24 }} />
        </SClose>
      ) : (
        <SHeader>
          <SBack onClick={close}>
            <Icon.ArrowBack sx={{ width: 21, height: 16 }} />
            <CustomTypography variant="p4_regular">Назад</CustomTypography>
          </SBack>
          <div>{headerChildren}</div>
        </SHeader>
      )}
      {title ? (
        <h2>
          <CustomTypography variant={isTablet() ? "h4_medium" : "h3_medium"}>
            {title}
          </CustomTypography>
        </h2>
      ) : null}
      {children}
    </SCont>
  );
};
