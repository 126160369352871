import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { get } from "lodash";

export const wrap = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SRadioLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const colorToUse = {
  red: "red",
  black: "background.inverted.lightMode",
};

export const SHelper = styled.div<{ leftPhr?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) =>
    props.leftPhr
      ? css`
          left: auto;
          right: 0;
        `
      : css`
          right: auto;
          left: 0;
        `}

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  transition: border 0.2s linear;

  &:after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: transparent;
    transition: background-color 0.2s linear;
  }
`;
export const SRadioButton = styled.input<{
  hasError?: boolean;
  leftPlhlr?: boolean;
  color: "black" | "red";
}>`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  ${(props) =>
    props.leftPlhlr
      ? css`
          margin-left: 12px;
        `
      : css`
          margin-right: 12px;
        `}
  cursor: pointer;

  &:hover:not(:checked) ~ ${SHelper} {
    border-color: ${(props) => get(props.theme.mtsColor, colorToUse[props.color])};

    &:after {
      background: transparent;
    }
  }

  ${(props) =>
    props.checked &&
    `
    &:checked + ${SHelper} {
      border-color: ${get(props.theme.mtsColor, colorToUse[props.color])};

      &:after {
        background: ${get(props.theme.mtsColor, colorToUse[props.color])};
      }
    }
  `}

  ${(props) =>
    props.hasError &&
    css`
      & ~ ${SHelper} {
        border-color: ${get(props.theme.mtsColor, colorToUse[props.color])} !important;
      }
    `}
`;

export const SLabelWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 20px;
  margin-bottom: 20px;
`;

export const SLabel = styled.span`
  min-width: 0;
  min-height: 0;
  flex: 0 0 auto;
  display: inline-block;
  font-size: 14px;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  white-space: nowrap;
  user-select: none;
`;

export const SPlace = styled.div<{ flexOrder?: number; checked?: boolean }>`
  font-size: 14px;
  line-height: 0.6;
  color: ${(props) => props.theme.mtsColor.text.headline.lightMode};
  user-select: none;
  order: ${(props) => props.flexOrder};
`;
