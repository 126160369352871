import { ROUTE_COMMERCIAL_LOSSES_PAGE, ROUTE_ASKUE_PAGE } from "stream-constants/route-constants";
import { IAccordionCustom } from "interface/common";
import productImg1 from "./images/product-1.png";
import productImg2 from "./images/product-2.png";
import advantageImg8 from "./images/advantage-8.svg";
import advantageImg9 from "./images/advantage-9.svg";
import advantageImg10 from "./images/advantage-10.svg";

export const advantageData: Array<{ icon: any; title: string; description: string }> = [
  {
    icon: advantageImg8,
    title: "Анализ параметров электросети",
    description:
      "Источником данных выступают системы учета электроэнергии (АСКУЭ), внешние системы (кадастры, метеоданные и т.д)",
  },
  {
    icon: advantageImg9,
    title: "Обработка информации",
    description: "Составляется тепловая карта расположения майнинговых ферм",
  },
  {
    icon: advantageImg10,
    title: "Формирование отчета",
    description:
      "Для удобного анализа информации предоставляется подробный отчет в текстовом формате с картографическими вложениями",
  },
];

export const productsCard: Array<{
  title: string;
  description?: string;
  nameBtn: string;
  isCallbackBlock?: boolean;
  img?: string;
  link?: string;
}> = [
  {
    title: "Поиск коммерческих потерь",
    description:
      "Программная платформа позволит быстро и\u00A0своевременно обнаружить точки коммерческих потерь и\u00A0выявить конкретные участки сети, на\u00A0которых происходит хищение",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg1,
    link: ROUTE_COMMERCIAL_LOSSES_PAGE,
  },
  {
    title: "Облачная платформа учета",
    description:
      "Облачное решение, которое упрощает сбор показаний и\u00A0значительно уменьшает затраты энергосбытовой компаний при\u00A0учете потребления электроэнергии",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg2,
    link: ROUTE_ASKUE_PAGE,
  },
];

export const accordionInfo: IAccordionCustom[] = [
  {
    title: "Что такое тепловая карта?",
    text: "На тепловой карте мы показываем концентрацию аномальных событий выделяя районы цветом.",
  },
  {
    title: "На сколько проблема актуальна?",
    text: "В 2021 году Россия стала третьей в мире по объемам майнинга биткоина — доля страны достигла 11,2%. Майнинговые фермы распределены по территории РФ неравномерно с явным перекосом в сторону Иркутской области, однако в каждом регионе страны количество майнинговых ферм исчисляется минимум сотнями.",
  },
  {
    title: "Что с майнинговыми фермами вообще не так?",
    text: "Если деятельность ведется «в белую», то ничего. К сожалению, зачастую допускаются значительные нарушения, например, незаконные подключения к электрическим сетям.",
  },
];

export const miningList: Array<{
  item: string;
  sublist?: Array<string>;
}> = [
  { item: "Тепловая карта" },
  { item: "Динамика по месяцам" },
  { item: "Приоритезация локаций" },
];

export const seo = {
  title: "Выявление майнинговых ферм с EnergyTool от МТС",
  description:
    "Поиск майнинговых ферм позволяет, при помощи искусственного интеллекта, анализируя данные расхода электроэнергии, полученные с АСКУЭ заказчика, находить майнинговые фермы.",
};
