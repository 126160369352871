import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Success = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M32 0C14.3648 0 0 14.3648 0 32C0 49.6352 14.3648 64 32 64C49.6352 64 64 49.6352 64 32C64 14.3648 49.6352 0 32 0ZM32 6.4C46.1764 6.4 57.6 17.8236 57.6 32C57.6 46.1764 46.1764 57.6 32 57.6C17.8236 57.6 6.4 46.1764 6.4 32C6.4 17.8236 17.8236 6.4 32 6.4Z" fill="#12B23F"/>
      <path d="M44.9009 22.3883C45.9218 21.3758 47.5772 21.3758 48.5982 22.3883C49.6191 23.4007 49.6191 25.0423 48.5982 26.0547L32.9119 41.6103C31.8909 42.6228 30.2356 42.6228 29.2146 41.6103L20.5 32.9683C19.479 31.9558 19.479 30.3143 20.5 29.3018C21.521 28.2894 23.1763 28.2894 24.1973 29.3018L31.0632 36.1106L44.9009 22.3883Z" fill="#12B23F"/>
    </SvgIcon>
  );
};

export default Success;