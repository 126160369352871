import { Helmet } from "react-helmet";
import _ from "lodash";

import { CustomTypography } from "components/customTypography/custom-typography";
import { seo, text } from "./const";
import { SArticle, SContainer, SMain, SList, SItem, SParag } from "./styles";
import { SectionTitle } from "components/section-title/section-title";

export const UserAgreement = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <SectionTitle title="Согласие на обработку персональных данных" />
            <div>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_1}
                </CustomTypography>
              </SParag>
              <SList>
                {text.list.map((elem) => {
                  return (
                    <SItem>
                      {_.isArray(elem) ? (
                        elem.map((el, i) => (
                          <>
                            <CustomTypography variant="p3_regular" font="comp">
                              {el}
                            </CustomTypography>
                            <br />
                          </>
                        ))
                      ) : (
                        <CustomTypography variant="p3_regular" font="comp">
                          {elem}
                        </CustomTypography>
                      )}
                    </SItem>
                  );
                })}
              </SList>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_2}
                </CustomTypography>
              </SParag>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_3}
                </CustomTypography>
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
