import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SCont = styled.div`
  align-self: flex-start;
  display: flex;

  & .MuiSvgIcon-root {
    width: auto !important;
    height: auto !important;
  }
  & .label__icon {
    position: relative;
    top: -2px;
    left: 2px;
  }
`;

export const SLabel = styled(CustomTypography)<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  &.MuiTypography-root {
    color: ${(props) =>
      props.hasError
        ? props.theme.mtsColor.accent.negative.lightMode
        : props.theme.mtsColor.text.secondary.lightMode};
    position: relative;
    bottom: 2px;
  }
`;
