import { IAdditInfoProp } from "components/additional-info-list/additional-info";
import { IAccordionCustom } from "interface/common";
import {
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_OPP,
  ROUTE_ASKUE_PAGE,
  ROUTE_SHOP,
} from "stream-constants/route-constants";

export const seo = {
  title: "Стоимость внедрения АСКУЭ",
  description: "Предлагаем комплексные решения на базе ПК «Энергосфера»",
};

export const accordionInfo: IAccordionCustom[] = [
  {
    title:
      'Какие преимущества есть у Облачной платформы учета на базе ПК "Энергосфера 8.1" перед другими системами АСКУЭ?',
    text: "Основными преимуществами предлагаемого ПК Энергосфера перед аналогами являются:",
    list: [
      "Комплексный учет энергоресурсов",
      "Поддержка большого количества отечественных и зарубежных приборов учета, УСПД, концентраторов (более 300 типов)",
      "Поддержка российских стандартов и требований к системам учета, а также российских протоколов обмена данными (СПОДЭС, ПОДИС), обязательных к применению на розничном рынке электроэнергии",
      "Интеграция с системами учета других производителей (более 15 типов)",
      "Гибкость предоставления данных пользователям с помощью различных видов интерфейсов (десктопные приложения, web-интерфейс, мобильные приложения)",
      "Наличие подсистемы контроля качества электрической энергии в соответствии с ГОСТ 32144-2013",
      "Наличие подсистемы метрологического сопровождения средств измерений, применяемых в АИИС (кабинет Метролога)",
      "Наличие подсистемы автоматического управления паролями (АУП) приборов учета",
      "Наличие подсистемы прогнозирования генерации ВИЭ",
    ],
  },
  {
    title: "Какие типы связи поддерживает облачное АСКУЭ?",
    text: "На данный момент GPRS (TCP/IP) и NB-IoT (UDP). CSD, PLC и другие типы связи по запросу.",
  },
  {
    title: "Возможно ли управлять нагрузкой счетчика удаленно?",
    text: "Да, если такой функционал поддерживает сам прибор учета. В веб-версии через оператора, в desktop версии через программный комплекс сам заказчик управляет приборами.",
  },
  {
    title: "Мы работаем только с конкретными электросчётчиками?",
    text: 'Мы работаем с различными ПУ которые поддерживает Облачная платформа учета на базе ПК "Энергосфера 8.1". Приборы учета "Меркурий" с технологией NB-IoT максимально просто заводятся на платформу и стабильно работают.',
  },
  {
    title: "Возможность применения для других видов сбыта (воды, газа и др)?",
    text: "На текущий момент законодательно регламентировано только применение интеллектуальных приборов в электроэнергетике. Платформа может работать с ПУ электричества, воды, газа и др. В целом, платформа может работать с большим количеством типов счетчиков (более 300 наименований).",
  },
  {
    title:
      'Есть ли у Облачной платформы учета на базе ПК "Энергосфера 8.1" передача показаний счетчиков макетом XML80020?',
    text: "Да, есть! Другие макеты тоже есть, их использование по запросу. ",
  },
  {
    title: "Соответствует ли система требованиям ФЗ №522 и ПП №890?",
    text: "Да. Наше решение полностью соответствует законодательству РФ.",
  },
  {
    title: "Где хранятся данные с приборов учета?",
    text: "Данные после опроса приборов учета хранятся на географически разнесенных серверах в ЦОДах МТС.",
  },
  {
    title: "Доступен ли пробный период?",
    text: "Доступен. Пробный период предоставляется на 1 месяц c прибором учета Меркурий NB-IoT",
  },
];

interface ITariffCard {
  id: number;
  title: string;
  subtitle: string;
  text: string[];
  price: string;
}

export const tariffsAskue: ITariffCard[] = [
  {
    id: 0,
    title: "Энергосфера в облаке МТС",
    subtitle: "по абонентской  модели",
    text: [
      'Все настройки в части заведения ПУ на платформу, разработки расчетных схем, настройки сервера опроса и т.д. находятся на стороне и во внутреннем контуре МТС и выполняются сотрудниками компании. В доступ выдается web-кабинет "Абонента" без доступа к Администрированию',
    ],
    price: "60",
  },
  {
    id: 1,
    title: "Энергосфера в облаке МТС",
    subtitle: "по операторской  модели",
    text: [
      'В зоне ответственности МТС развертывание сервера опроса и программного комплекса "Энергосфера" на облачных ресурсах под Заказчика. В данной модели предоставляется удаленный доступ к АСКУЭ для администрирования и оперирования программного комплекса.',
      "От 1000 приборов учета",
    ],
    price: "30",
  },
  {
    id: 2,
    title: "Расходомеры",
    subtitle: "тепло- и газосчетчики",
    text: [
      'Все настройки в части заведения ПУ на платформу, разработки расчетных схем, настройки сервера опроса и т.д. находятся на стороне и во внутреннем контуре МТС и выполняются сотрудниками компании. В доступ выдается web-кабинет "Абонента" без доступа к Администрированию',
    ],
    price: "120",
  },
];

interface IProductsCard {
  id: number;
  title: string;
  price: string;
  link: string[];
  priceText?: string;
  anchor?: boolean;
}

export const productsAskue: IProductsCard[] = [
  {
    id: 0,
    title: "Приборы учета электроэнергии",
    price: "от 14 190 ₽",
    link: ["Перейти в магазин", ROUTE_SHOP],
  },
  {
    id: 1,
    title: "Автоматизированная система учета  (АСКУЭ)",
    price: "от 30 ₽",
    link: ["Возможности платформы", ROUTE_ASKUE_OPP],
  },
  {
    id: 2,
    title: "Access Point Name  трафик  (APN)",
    price: "Бесплатно",
    priceText: "при комплексном внедрении",
    link: ["Подробнее", "#apn"],
    anchor: true,
  },
];

interface IApnContent {
  title: string;
  text: string;
}

export const APNContent: IApnContent[] = [
  {
    title: "Скорость приема/передачи данных",
    text: "128 кб/с",
  },
  {
    title: "Стоимость без НДС",
    text: "Входит в стоимость сервиса «АСКУЭ в облаке» при комплексном внедрении",
  },
  {
    title: "Объем данных",
    text: "Без ограничений",
  },
];

export const additionalInfo: IAdditInfoProp[] = [
  {
    title: "Облачная платформа\u00A0учета",
    icon: "cloud",
    link: `/${ROUTE_ASKUE_PAGE}`,
  },
  {
    title: "Какие возможности предоставляет\u00A0платформа",
    icon: "check",
    link: `/${ROUTE_ASKUE_OPP}`,
  },
  {
    title: "Поддерживаемые виды связи\u00A0и\u00A0оборудование",
    icon: "router",
    link: `/${ROUTE_ASKUE_EQUIPMENT}`,
  },
];
