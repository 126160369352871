import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";

import { ShopSort } from "components/shop/shopSort/shop-sort";
import { MTSInput } from "components/mts-input";
import { STitle } from "components/intro-section/styles";
import { CardDevice, CardService } from "components/shop/shopCard/shopCards.component";
import { PageFilter } from "components/pageFilter/pageFilter.component";
import { Loader } from "components/spinner";
import { IShopProduct, IShopRequest } from "interface/common";
import { useShopCatalog } from "hooks/hooks";
import { useDebounce } from "hooks/useDebounce";
import { useBreakpoint } from "hooks/useBreakpoint";
import { CustomTypography } from "components/customTypography/custom-typography";
import { serviceCategory } from "components/shop/const";
import {
  SHOP_SCHEME_HARDCODED,
  SHOP_SORT_DEFAULT,
  calcDefFilter,
  calcSort,
  composeBackendFilter,
  hardcodedDirection,
  seo,
  subtext,
  title,
  titleByKey,
} from "./const";
import { SHead, SGrid, SSubtext, SSearch, SVitrina } from "./styles";

export const Shop = () => {
  let [searchParams] = useSearchParams();
  const { isTablet } = useBreakpoint();
  const [searchVal, setSearch] = useState("");
  const [sort, setSort] = useState(SHOP_SORT_DEFAULT);
  const [columnFilter, setColumnFilter] = useState<any[]>([]);

  const newFilt = composeBackendFilter<IShopRequest>(columnFilter);

  const { data: items, isLoading } = useShopCatalog(newFilt);
  const filtered = items?.filter((a) => !a.hide_flag);

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const handleSelect = (v) => {
    setSort(v);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      {isTablet() ? null : (
        <SHead>
          <div>
            <div>
              <STitle>
                <CustomTypography variant="promo2_bold" font="wide">
                  {title}
                </CustomTypography>
              </STitle>
              <SSubtext>
                <CustomTypography variant="p3_regular" font="comp">
                  {subtext}
                </CustomTypography>
              </SSubtext>
            </div>
          </div>
        </SHead>
      )}
      <SGrid>
        <div>
          {isTablet() ? null : (
            <PageFilter
              dataLength={filtered?.length ?? 0}
              onApplyFilter={handleColumnFilter}
              scheme={SHOP_SCHEME_HARDCODED}
              defaultFilter={calcDefFilter(searchParams)}
              titleByKey={titleByKey}
            />
          )}
        </div>
        <div>
          <SSearch>
            <MTSInput
              searchIcon
              placeholder="Поиск"
              onChange={handleInput}
              value={searchVal}
              size="XS"
            />
            {isTablet() ? (
              <PageFilter
                dataLength={filtered?.length ?? 0}
                onApplyFilter={handleColumnFilter}
                scheme={SHOP_SCHEME_HARDCODED}
                defaultFilter={calcDefFilter(searchParams)}
                titleByKey={titleByKey}
                isShowMobView
              />
            ) : null}
            <ShopSort selected={sort} items={hardcodedDirection} onChange={handleSelect} />
          </SSearch>
          {isLoading ? (
            <Loader isFullPage={false} />
          ) : !isEmpty(filtered) ? (
            <>
              <SVitrina>
                <RenderVitrina
                  products={filtered ?? []}
                  searchText={debouncedSearch}
                  sortProd={sort}
                />
                <RenderVitrina
                  products={filtered ?? []}
                  searchText={debouncedSearch}
                  sortProd={sort}
                  isDevice={false}
                />
              </SVitrina>
            </>
          ) : null}
        </div>
      </SGrid>
    </>
  );
};

interface IRendVit {
  products: IShopProduct[];
  searchText: string;
  sortProd: string;
  isDevice?: boolean;
}

const RenderVitrina = ({ products, searchText, sortProd, isDevice = true }: IRendVit) => {
  return (
    <>
      {products
        ?.sort((a, b) => calcSort(a, b, sortProd))
        .filter((itm) =>
          searchText === ""
            ? itm
            : itm.name.toLowerCase().includes(searchText.toLowerCase().trim()),
        )
        .map((a) => {
          return isDevice ? (
            a.category !== serviceCategory ? (
              <CardDevice product={a} key={`${a.category}_${a.id}`} />
            ) : null
          ) : a.category === serviceCategory ? (
            <CardService product={a} key={`${a.category}_${a.id}`} />
          ) : null;
        })}
    </>
  );
};
