import styled from "@emotion/styled";
import { css, SerializedStyles } from "@emotion/react";

import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";
import { device } from "theme";
import { customScrollVertical } from "components/table/utils";

export const SWrap = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  & ${MTSButton} {
    max-width: 100%;
    width: 100%;

    & button {
      max-width: 100%;
      width: 100%;
      height: 46px !important;
    }
  }
`;

export const SResult = styled.div`
  text-align: center;

  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondaryLink.lightMode};
  }
`;

export const SBoxWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
  text-align: left;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 250px;
  min-height: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 0,
      trackEnd: 0,
    })};
`;

export const STitle = styled(CustomTypography)`
  display: inline-block;
`;

export const tool_base = (theme) => css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d7dbe3;
  border-radius: 6px;
  background-color: ${theme.mtsColor.background.secondary.lightMode};
  cursor: pointer;
`;

export const SFltBtn = styled.div<{ withValue?: boolean; btnStyle?: SerializedStyles }>`
  ${(props) => tool_base(props.theme)};

  & svg {
    width: 20px;
    height: 20px;
  }

  & > .MuiSvgIcon-root {
    fill: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }

  ${(props) => props.btnStyle ?? ""};

  ${(props) =>
    props.withValue &&
    `
		background-color: ${props.theme.mtsColor.background.secondary.darkMode};
		border-color: ${props.theme.mtsColor.background.secondary.darkMode};

		& > .MuiSvgIcon-root path {
			fill: white;
		}
  `};

  @media ${device.tabletPlus} {
    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;
