import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InputError = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68597 16.314C0.373095 15.0012 0.275748 13.6932 0.0810531 11.0772C0.0305833 10.399 0 9.69952 0 9C0 8.30048 0.0305834 7.60095 0.0810532 6.92282C0.275748 4.30684 0.373095 2.99884 1.68597 1.68597C2.99884 0.373095 4.30684 0.275747 6.92282 0.0810528C7.60095 0.0305824 8.30048 0 9 0C9.69952 0 10.399 0.0305824 11.0772 0.0810528C13.6932 0.275747 15.0012 0.373095 16.314 1.68597C17.6269 2.99884 17.7243 4.30684 17.9189 6.92282C17.9694 7.60095 18 8.30048 18 9C18 9.69952 17.9694 10.399 17.9189 11.0772C17.7243 13.6932 17.6269 15.0012 16.314 16.314C15.0012 17.6269 13.6932 17.7243 11.0772 17.9189C10.399 17.9694 9.69952 18 9 18C8.30048 18 7.60095 17.9694 6.92282 17.9189C4.30684 17.7243 2.99884 17.6269 1.68597 16.314ZM9 9.99999C9.55229 9.99999 10 9.55227 10 8.99999L9.99999 5.00244C9.99999 4.45015 9.55227 4.00244 8.99998 4.00244C8.4477 4.00244 7.99999 4.45016 7.99999 5.00244L8 8.99999C8 9.55228 8.44772 9.99999 9 9.99999ZM10.2477 13.0011C10.2477 12.3114 9.68859 11.7523 8.99887 11.7523C8.30914 11.7523 7.75 12.3114 7.75 13.0011C7.75 13.6909 8.30914 14.25 8.99887 14.25C9.68859 14.25 10.2477 13.6909 10.2477 13.0011Z"
        fill="#F95721"
      />
    </SvgIcon>
  );
};

export default InputError;
