import { isEmpty } from "lodash";
import { IAskueEquipment, IAskueEquipments } from "./const";

export const composeSearchFilter = <T>(somearr): T =>
  somearr?.reduce(
    (sum, cur) => ({
      ...sum,
      ...(!isEmpty(cur.value) ? { [cur.column]: cur.value } : {}),
    }),
    {},
  );

export const constructTableData = (draft: IAskueEquipment[], category: string | undefined) =>
  draft?.map((d) => {
    return {
      ...d,
      categorys: [category],
    };
  });

export const getUniqueManufacturer = (data: IAskueEquipments) => {
  const allManufacturer: string[] = [];

  for (let item in data) {
    data[item].forEach((a) => allManufacturer.push(a.manufacturer));
  }
  return [...new Set(allManufacturer.sort((a, b) => a.localeCompare(b)))].map((item, i) => {
    return { id: i, name: item };
  });
};
