import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { device } from "theme";

import bgOne from "./images/bg_one.jpg";
import bgTwo from "./images/bg_two.jpg";
import { CustomTypography } from "components/customTypography/custom-typography";

const common = css`
  width: 100%;

  & > section {
    max-width: 1304px;
    margin: 0 auto;
    padding: 40px 20px 60px 20px;

    @media ${device.desktopPlus} {
      padding: 82px 20px 120px 20px;
    }
  }
`;

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

export const SWideHeader = styled(CustomTypography)`
  white-space: pre-wrap;
`;

export const SCompHeader = styled(CustomTypography)`
  white-space: pre-wrap;

  &:last-of-type {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SCaption = styled(CustomTypography)`
  position: relative;
  padding-left: 20px;
  color: ${(props) => props.theme.mtsColor.grey.greyDark};

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.mtsColor.red};
  }
`;

export const SBlockOne = styled.div`
  ${common};
  background: ${(props) => props.theme.mtsColor.background.primary.lightMode};

  & article {
    min-width: 0;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 32px;

    & > div {
      min-width: 0;
      display: flex;
      flex-flow: column nowrap;
      padding: 0 24px 48px 24px;
      background: white;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};

      & > img {
        width: 120px;
        height: 120px;
        margin-left: auto;
      }
    }
  }

  @media ${device.desktopPlus} {
    & article {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
    }
  }
`;

export const SBlockTwo = styled.div`
  ${common};
  background-color: white;

  & article {
    min-width: 0;
    display: flex;
    flex-flow: column nowrap;
  }

  @media ${device.desktopPlus} {
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url(${bgOne});

    & > section > div {
      padding-right: 19%;
    }
  }
`;

export const SBlockThree = styled.div`
  ${common}
  background-color: #040c26;

  & article {
    min-width: 0;
    display: flex;
    flex-flow: column nowrap;
  }

  & ${SWideHeader}, & ${SCaption} {
    color: #ffffff;
  }

  @media ${device.desktopPlus} {
    background-size: auto 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(${bgTwo});

    & > section > div {
      padding-left: 19%;
      padding-right: 162px;
    }
  }
`;

export const SBlockFour = styled.div`
  ${common};

  & article {
    min-width: 0;
    display: flex;
    flex-flow: column nowrap;
  }

  @media ${device.desktopPlus} {
    & article {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export const SItem = styled.div<{ bg?: string }>`
  position: relative;
  min-width: 0;
  min-height: 40px;
  padding: 132px 32px 56px 0;
  background: transparent;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 48px;
    width: 56px;
    height: 56px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.bg})`};
  }

  @media ${device.desktopPlus} {
    border-bottom: 1px solid #e2e2e2;

    &:not(:nth-child(3n)) {
      border-right: 1px solid #e2e2e2;
    }

    &:last-child,
    &:nth-last-child(2),
    &:nth-last-child(3) {
      border-bottom: none;
    }

    &:not(:nth-child(3n + 1)) {
      padding-left: 32px;

      &:before {
        left: 32px;
      }
    }
  }
`;
