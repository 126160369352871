import { isEmpty } from "lodash";
import { ICheckCartReq, IShopRequest } from "interface/common";
import { fetchData } from "services/services-utils/fetch-data";
import { GET_CART_CHECK, SHOP } from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";

type IProp = {
  [key: string]: string[];
};

const makeSearchParams = (data?: IProp) => {
  if (data && !isEmpty(data)) {
    const params = new URLSearchParams();
    Object.entries(data).forEach(([key, val]) => val.forEach((a) => params.append(key, a)));
    return `?${params}`;
  }

  return "";
};

export const getShopCatalogue = async (data?: IShopRequest) => {
  return fetchData({
    url: `${SHOP}${makeSearchParams(data as any)}`,
    method: IHttpMethods.GET,
    name: "getShopCatalogue",
    emptyResponse: false,
  });
};

export const getShopItem = async (id: number | string) => {
  return await fetchData({
    url: `${SHOP}/${id}`,
    method: IHttpMethods.GET,
    name: "getShopItem",
    emptyResponse: false,
  });
};

export const shopCheckCart = async (data: ICheckCartReq) => {
  return await fetchData({
    url: `${GET_CART_CHECK}`,
    method: IHttpMethods.POST,
    name: "shopCheckCart",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};
