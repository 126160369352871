export const calcSeo = (val) => {
  switch (val) {
    case "13":
    case "14":
    case "15":
    case "5":
    case "19":
    case "20":
    case "21":
    case "22":
    case "23":
      return {
        title: "Счетчик - Меркурий",
        desc: "Приобретайте интеллектуальные счетчики для быстрого и простого подключения к АСКУЭ.",
      };
    case "6":
    case "8":
      return {
        title: "Счетчик – Меркурий с АСКУЭ на 12 месяцев",
        desc: "Приобретайте комплексное решение по интеллектуальному учету ресурсов, в которое включен счетчик электроэнергии и подключение к АСКУЭ без дополнительных платежей.",
      };
    case "7":
      return {
        title: "Модем – iRZ",
        desc: "Приобретайте удобный и простой GSM/GPRS модем для подключения приборов учета к АСКУЭ.",
      };
    case "4":
      return {
        title: "Антенна для модема – iRZ",
        desc: "Приобретайте выносную антенну для модема.",
      };
    case "18":
      return {
        title: "Преобразователь интерфейсов RS-485 в Ethernet",
        desc: "Приобретайте удобный и простой преобразователь интерфейсов для подключения приборов учета к АСКУЭ.",
      };
    default:
      return {
        title: "Купить по хорошей цене на на EnergyTool",
        desc: "Приобретайте оборудование для оптимизации энергетических расходов вашей организации",
      };
  }
};
