import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.25105 11.5774C4.91632 11.9028 4.91632 12.4305 5.25105 12.7559C5.58579 13.0814 6.1285 13.0814 6.46323 12.7559L10.7489 8.58926C11.0837 8.26382 11.0837 7.73618 10.7489 7.41074L6.46323 3.24408C6.1285 2.91864 5.58579 2.91864 5.25105 3.24408C4.91632 3.56951 4.91632 4.09715 5.25105 4.42259L8.93067 8L5.25105 11.5774Z" />
    </SvgIcon>
  );
};

export default ArrowRight;
