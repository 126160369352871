import { useNavigate } from "react-router-dom";
import theme from "theme";

import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SPlace, SInfo } from "./style";

interface IProp {
  type: "cart" | "success";
}

// FIXME сейчас здесь много хардкода
export const NotificationBlock: React.FC<IProp> = ({ type }) => {
  const navigate = useNavigate();
  const { isTablet } = useBreakpoint();

  const iconStyle = isTablet() ? { width: 48, height: 48 } : { width: 128, height: 128 };

  return type === "cart" ? (
    <SPlace>
      <Icon.Cart sx={iconStyle} />
      <Spacer value={isTablet() ? "16px" : "40px"} />
      <CustomTypography variant={isTablet() ? "p2_medium" : "h2_medium"} font="comp">
        Корзина пуста
      </CustomTypography>
      <Spacer value="16px" />
      <SInfo variant="p4_regular" font="comp">
        Воспользуйтесь каталогом, чтобы найти интересующие вас товары
      </SInfo>
      <Spacer value="40px" />
      <MTSButton
        size={isTablet() ? "S" : "L"}
        variant="outline"
        onClick={() => navigate(`/${ROUTE_SHOP}`)}
      >
        Вернуться на главную
      </MTSButton>
    </SPlace>
  ) : type === "success" ? (
    <SPlace iconColor="green">
      <Icon.Success sx={{ ...iconStyle, fill: theme.mtsColor.accent.positive.lightMode }} />
      <Spacer value={isTablet() ? "16px" : "40px"} />
      <CustomTypography variant={isTablet() ? "p2_medium" : "h2_medium"} font="comp">
        Заявка успешно отправлена
      </CustomTypography>
      <Spacer value="16px" />
      <SInfo variant="p4_regular" font="comp">
        Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00
      </SInfo>
      <Spacer value={isTablet() ? "16px" : "40px"} />
      <MTSButton
        size={isTablet() ? "S" : "L"}
        variant="outline"
        onClick={() => navigate(`/${ROUTE_SHOP}`)}
      >
        Вернуться в магазин
      </MTSButton>
    </SPlace>
  ) : null;
};
