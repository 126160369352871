import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { device } from "theme";

export const SCont = styled(NavLink)`
  position: relative;
  width: 32px;
  height: 32px;
  text-decoration: none;

  & svg {
    width: 100%;
    height: 100%;

    & path {
      transition: fill 0.3s linear;
      fill: #969fa8;
    }
  }

  @media ${device.tabletPlus} {
    &.active,
    &:hover {
      & svg {
        & path {
          fill: white;
        }
      }
    }
  }
`;

const calcBorder = (val) =>
  val < 10
    ? css`
        border-radius: 50%;
      `
    : css`
        border-radius: 20px;
      `;

const calcOffset = (val) =>
  val < 10
    ? css`
        right: 0;
      `
    : css`
        right: auto;
        left: 6px;
      `;

const calcSize = (val) =>
  val < 10
    ? css`
        padding: 0 3px;
      `
    : css`
        padding: 2px 6px;
      `;

export const SCircle = styled.div<{ length: number }>`
  ${(props) =>
    props.length === 0 &&
    css`
      display: none;
    `};
  position: absolute;
  top: 0px;
  ${(props) => calcOffset(props.length)}
  ${(props) => calcBorder(props.length)}
  width: auto;
  min-width: 16px;
  min-height: 16px;
  height: auto;
  background-color: red;
  border: 1px solid white;
  box-sizing: content-box;

  & > span {
    position: relative;
    display: inline-block;
    top: 0;
    ${(props) => calcSize(props.length)}
    line-height: 1;
    color: white;
  }
`;
