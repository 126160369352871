import Icon from "components/icons";
import theme from "theme";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SCheck, SWrap } from "./styles";

type IToast = {
  title: string;
  isError?: boolean;
};

export const Toast = (props: IToast) => {
  return (
    <SWrap>
      <SCheck isError={props.isError}>{!props.isError ? <Icon.Check /> : <Icon.Close />}</SCheck>
      <CustomTypography
        variant="p3_regular"
        styles={{ color: theme.mtsColor.text.primary.darkMode }}
        font="comp"
      >
        {props.title}
      </CustomTypography>
    </SWrap>
  );
};
