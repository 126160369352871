import { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { Checkbox } from "components/checkbox/checkbox";
import { Spacer } from "components/spacer/spacer.component";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { CustomTypography } from "components/customTypography/custom-typography";

import { FALLBACK_MSG, schema } from "./validation-schema";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { ROUTE_OFFER_LICENSE, ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { useSubmitFormConsultation } from "hooks/hooks";
import { unformatPhone } from "utils/formatters";

import { SBtns, SChecks, SFieldBlock, SLink, SSubtitle, STitle } from "./styles";

type CommonProps = {
  contact?: any;
  buttonTitle?: string;
  handleCancelClick?: any;
};

export const FormConsultation: FC<CommonProps> = ({ contact, handleCancelClick }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: contact || {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const mutation = useSubmitFormConsultation();

  const onSubmit = (sbmtData: any) => {
    mutation.mutate({
      ...sbmtData,
      phone: unformatPhone(sbmtData.phone),
    });
  };

  return (
    <>
      {mutation?.isSuccess ? (
        <ModalNotifyBody
          title="Заявка отправлена"
          textDescrioption="Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00"
          isSuccess
          close={handleCancelClick}
        />
      ) : mutation?.isError ? (
        <ModalNotifyBody
          title="Произошла ошибка"
          isError
          textDescrioption={(mutation?.error as any).message ?? FALLBACK_MSG}
          close={handleCancelClick}
        />
      ) : (
        <>
          <STitle>
            <CustomTypography variant="h3_medium" font="comp">
              Заявка на консультацию
            </CustomTypography>
          </STitle>
          <SSubtitle>
            <CustomTypography variant="p4_regular" font="comp">
              Оставьте свои данные для обратной связи
            </CustomTypography>
          </SSubtitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SFieldBlock>
              <MTSInput
                {...register("userName")}
                label="Ваше имя"
                errorMessage={errors?.userName?.message}
                control={control}
              />
            </SFieldBlock>
            <SFieldBlock>
              <InputMask mask={PHONE_MASK} {...register("phone")} defaultValue={contact?.phone}>
                {() => (
                  <MTSInput
                    {...register("phone")}
                    label="Номер телефона"
                    errorMessage={errors?.phone?.message}
                    control={control}
                  />
                )}
              </InputMask>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                {...register("email")}
                label="Электронная почта"
                placeholder="Не обязательно"
                control={control}
                errorMessage={errors?.email?.message}
              />
            </SFieldBlock>
            <SChecks>
              <Checkbox
                value="true"
                checked={true}
                onChange={(a) => {}}
                label={
                  <CustomTypography variant="c1_regular" font="comp">
                    Вы соглаcны с{" "}
                    <SLink target="_blank" rel="noreferrer" to={`/${ROUTE_OFFER_LICENSE}`}>
                      условиями публичной оферты
                    </SLink>
                  </CustomTypography>
                }
                color="red"
                size="S"
              />
              <Checkbox
                value="true"
                checked={true}
                onChange={(a) => {}}
                label={
                  <CustomTypography variant="c1_regular" font="comp">
                    Вы соглаcны на{" "}
                    <SLink target="_blank" rel="noreferrer" to={`/${ROUTE_USER_AGREEMENT}`}>
                      обработку персональных данных
                    </SLink>
                  </CustomTypography>
                }
                color="red"
                size="S"
              />
            </SChecks>
            <Spacer value="12px" />
            <SBtns>
              <MTSButton size="M" variant="primary" onClick={() => {}} type="submit">
                Отправить
              </MTSButton>
              <MTSButton size="M" variant="secondary" onClick={handleCancelClick}>
                Отмена
              </MTSButton>
            </SBtns>
          </form>
        </>
      )}
    </>
  );
};
