import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RubleSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5735_9250)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.96804 7.29421C8.6952 7.46035 8.46612 7.68943 8.29998 7.96227C8.00821 8.44144 8.00821 9.0927 8.00821 10.3952V11.0124C8.00757 11.0251 8.00725 11.0379 8.00725 11.0508L8.00724 12.0068L7.75723 12.0068C7.34301 12.0068 7.00723 12.3426 7.00723 12.7568C7.00722 13.171 7.34301 13.5068 7.75723 13.5068L8.00724 13.5068L8.00723 14.502H7.78628C7.37206 14.502 7.03628 14.8377 7.03628 15.252C7.03628 15.6662 7.37206 16.002 7.78628 16.002H8.00723L8.00723 16.7583C8.00722 17.1725 8.34301 17.5083 8.75722 17.5083C9.17144 17.5083 9.50722 17.1725 9.50723 16.7583L9.50723 16.002H13.7597C14.1739 16.002 14.5097 15.6662 14.5097 15.252C14.5097 14.8377 14.1739 14.502 13.7597 14.502H9.50723L9.50724 13.5102L12.8479 13.5102C13.1245 13.5102 13.392 13.5042 13.6478 13.4937C15.4781 13.4188 16.9961 12.2271 16.9961 10.3952C16.9961 8.42329 15.3527 7.06407 13.3815 7.00983C13.2081 7.00506 13.03 7.00244 12.8479 7.00244H11.401C10.0985 7.00244 9.4472 7.00244 8.96804 7.29421ZM11.0822 12.0068C11.1064 12.0068 11.1304 12.008 11.1541 12.0102L12.8479 12.0102C13.1028 12.0102 13.3497 12.0047 13.5864 11.995C14.8533 11.9431 15.4961 11.2019 15.4961 10.3952C15.4961 9.41093 14.6867 8.54631 13.3402 8.50926C13.18 8.50485 13.0156 8.50244 12.8479 8.50244H11.401C10.7208 8.50244 10.3155 8.50401 10.017 8.53225C9.83776 8.5492 9.7633 8.57122 9.7426 8.57882C9.67869 8.6189 9.62467 8.67292 9.58459 8.73683C9.57699 8.75753 9.55497 8.83199 9.53801 9.01126C9.50978 9.30975 9.50821 9.71508 9.50821 10.3952V12.0068L11.0822 12.0068ZM9.58731 8.73044L9.5869 8.73161L9.5852 8.7352C9.58651 8.73178 9.58726 8.73031 9.58731 8.73044Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68548 4.68963C3.37261 6.0025 3.27526 7.3105 3.08056 9.92649C3.0301 10.6046 2.99951 11.3041 2.99951 12.0037C2.99951 12.7032 3.0301 13.4027 3.08056 14.0808C3.27526 16.6968 3.37261 18.0048 4.68548 19.3177C5.99835 20.6306 7.30635 20.7279 9.92233 20.9226C10.6005 20.9731 11.3 21.0037 11.9995 21.0037C12.699 21.0037 13.3986 20.9731 14.0767 20.9226C16.6927 20.7279 18.0007 20.6306 19.3135 19.3177C20.6264 18.0048 20.7238 16.6968 20.9185 14.0808C20.9689 13.4027 20.9995 12.7032 20.9995 12.0037C20.9995 11.3041 20.9689 10.6046 20.9185 9.92648C20.7238 7.3105 20.6264 6.0025 19.3135 4.68963C18.0007 3.37676 16.6927 3.27941 14.0767 3.08472C13.3986 3.03425 12.699 3.00366 11.9995 3.00366C11.3 3.00366 10.6005 3.03425 9.92233 3.08472C7.30635 3.27941 5.99835 3.37676 4.68548 4.68963ZM11.9995 5.00366C11.3573 5.00366 10.7079 5.03178 10.0708 5.0792C7.32042 5.28389 6.80589 5.39764 6.09969 6.10384C5.39349 6.81004 5.27974 7.32457 5.07505 10.0749C5.02763 10.7121 4.99951 11.3614 4.99951 12.0037C4.99951 12.6459 5.02763 13.2952 5.07505 13.9324C5.27974 16.6828 5.39349 17.1973 6.09969 17.9035C6.80589 18.6097 7.32042 18.7234 10.0708 18.9281C10.7079 18.9755 11.3573 19.0037 11.9995 19.0037C12.6417 19.0037 13.2911 18.9755 13.9283 18.9281C16.6786 18.7234 17.1931 18.6097 17.8993 17.9035C18.6055 17.1973 18.7193 16.6828 18.924 13.9324C18.9714 13.2952 18.9995 12.6459 18.9995 12.0037C18.9995 11.3614 18.9714 10.7121 18.924 10.0749C18.7193 7.32457 18.6055 6.81004 17.8993 6.10384C17.1931 5.39764 16.6786 5.28389 13.9282 5.0792C13.2911 5.03178 12.6417 5.00366 11.9995 5.00366Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5735_9250">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RubleSquare;
