import { IDemoAskueCreateRequest } from "interface/common";
import { fetchData } from "services/services-utils/fetch-data";
import { ASKUE_DEMO } from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";

export const demoAskueCreate = async (data: IDemoAskueCreateRequest) => {
  return fetchData({
    url: `${ASKUE_DEMO}`,
    method: IHttpMethods.POST,
    name: "demoAskueCreate",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};
