import styled from "@emotion/styled";
import { device } from "theme";
import { Link } from "react-router-dom";

export const STitle = styled.h2`
  text-align: left;
  margin-bottom: 8px;
`;

export const SSubtitle = styled.div`
  text-align: left;
  margin-bottom: 24px;

  @media ${device.tabletNeg} {
    flex-direction: column;
  }
`;

export const SFieldBlock = styled.div`
  margin-bottom: 24px;
`;

export const SLink = styled(Link)`
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  white-space: normal;
`;

export const SBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & button {
    width: 100%;
  }

  @media ${device.tabletPlus} {
    & > div:last-child {
      display: none;
    }
  }
`;

export const SChecks = styled.div`
  & > label {
    display: flex;
  }
`;
