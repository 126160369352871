import { useContext } from "react";
import { FormConsultation } from "components/forms/form-consultation/form-consultation";
import { MTSModal } from "components/mts-modal/mts-modal";
import { ContextSettings } from "context/context-settings";

export const ModalFormConsultation = () => {
  const context = useContext(ContextSettings);

  return (
    <>
      <MTSModal
        open={context.isOpenFormConsultation ?? false}
        close={() => context.changeStateFormConsultation(false)}
      >
        <FormConsultation handleCancelClick={() => context.changeStateFormConsultation(false)} />
      </MTSModal>
    </>
  );
};
