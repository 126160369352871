import styled from "@emotion/styled";
import { customScrollVertical } from "./utils";
import { CustomTypography } from "components/customTypography/custom-typography";

export const STable = styled.div<{ height?: number; withHeader?: boolean; withStub?: boolean }>`
  display: block;
  max-width: 100%;
  min-height: 0;

  & .table_tr {
    display: flex;
    gap: 24px;
    padding: 12px 24px;
    width: 100% !important;
    max-width: 100%;
    height: 100%;

    &:nth-of-type(2n) {
      background-color: #f9f9fb;
    }

    & > div {
      text-align: left;
    }
  }
`;

export const STableTitle = styled(CustomTypography)`
  margin-bottom: 24px;
  display: block;
  text-align: left;
`;

export const SSearch = styled.div`
  display: block;
  position: relative;

  & ul {
    position: absolute;
    top: 46px;
    width: 100%;
    min-height: 10px;
    height: auto;
    max-height: 560px;
    overflow: auto;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    ${(props) =>
      customScrollVertical({
        theme: props.theme,
        trackStart: 8,
        trackEnd: 8,
      })};
    border-radius: 8px;
    background-color: white;
    text-align: left;

    & li {
      padding: 12px 8px;
      cursor: pointer;

      &:hover {
        background-color: #cecece;
      }
    }
  }
`;
