import styled from "@emotion/styled";

export const SPlus = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  border-radius: 6px;

  & span {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgMUMxNiAxLjU1MjI5IDE1LjU1MjMgMiAxNSAyTDEgMkMwLjQ0NzcxNSAyIC0xLjM3OTVlLTA4IDEuNTUyMjggMCAxQzEuMzc5NWUtMDggMC40NDc3MTUgMC40NDc3MTUgLTEuMzc5NWUtMDggMSAwTDE1IDMuNDk2OTJlLTA3QzE1LjU1MjMgMy42MzQ4N2UtMDcgMTYgMC40NDc3MTYgMTYgMVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;

    &.active {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMTVDNyAxNS41NTIzIDcuNDQ3NzIgMTYgOCAxNkM4LjU1MjI5IDE2IDkgMTUuNTUyMyA5IDE1VjlIMTVDMTUuNTUyMyA5IDE2IDguNTUyMjkgMTYgOEMxNiA3LjQ0NzcyIDE1LjU1MjMgNyAxNSA3SDlWMUM5IDAuNDQ3NzE1IDguNTUyMjggMCA4IDBDNy40NDc3MSAwIDcgMC40NDc3MTUgNyAxTDcgN0gxQzAuNDQ3NzE1IDcgMCA3LjQ0NzcxIDAgOEMwIDguNTUyMjggMC40NDc3MTUgOSAxIDlIN0w3IDE1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    }
  }
`;

export const SArrow = styled.div`
  height: 100%;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  padding: 0 8px;
  border-radius: 6px;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease all;

    &.active {
      transform: rotate(90deg);
      transform-origin: center;
      transition: 0.2s ease all;
    }
  }
`;
