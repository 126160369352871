import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Plus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3620_3021)">
        <g clipPath="url(#clip1_3620_3021)">
          <path
            d="M10.9999 19.0711C10.9999 19.6234 11.4476 20.0711 11.9999 20.0711C12.5522 20.0711 12.9999 19.6234 12.9999 19.0711L12.9999 13.0001L19.0711 13.0001C19.6234 13.0001 20.0711 12.5524 20.0711 12.0001C20.0711 11.4478 19.6234 11.0001 19.0711 11.0001H12.9999L12.9999 4.92893C12.9999 4.37665 12.5522 3.92893 11.9999 3.92893C11.4476 3.92893 10.9999 4.37665 10.9999 4.92893L10.9999 11.0001L4.92897 11.0001C4.37668 11.0001 3.92897 11.4478 3.92897 12.0001C3.92897 12.5524 4.37669 13.0001 4.92897 13.0001L10.9999 13.0001L10.9999 19.0711Z"
            fill="#1D2023"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3620_3021">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_3620_3021">
          <rect width="24" height="24" fill="white" transform="translate(-4.9707 12) rotate(-45)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Plus;
