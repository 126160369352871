import styled from "@emotion/styled";
import { device } from "theme";

export const SGridCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 32px;

  @media ${device.tabletNeg} {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;
