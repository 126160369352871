import { useNavigate } from "react-router-dom";
import Icon from "components/icons";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SWrap } from "./styles";

export const Back: React.FC<{ name?: string; link?: string }> = ({ name, link }) => {
  const navigate = useNavigate();
  return (
    <SWrap onClick={() => (link ? navigate(link) : navigate(-1))}>
      <Icon.ArrowBack sx={{ width: 21, height: 16 }} />
      <CustomTypography variant="p3_regular">{name ?? "Назад"}</CustomTypography>
    </SWrap>
  );
};
