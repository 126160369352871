import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Cloud = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.9727 9.5C18.9727 9.48257 18.9722 9.46525 18.9713 9.44804C18.6925 6.39321 16.1275 4 13 4C10.0235 4 7.55322 6.16739 7.08128 9.01004C7.03792 9.00895 6.99441 9.0084 6.95078 9.0084C4.21654 9.0084 2 11.1623 2 13.8193C2 15.5915 2.98606 17.1399 4.45411 17.9746C4.65054 18.1057 4.84721 18.2133 5.0494 18.3029C5.18481 18.3713 5.3379 18.4099 5.5 18.4099C6.05228 18.4099 6.5 17.9622 6.5 17.4099C6.5 16.9877 6.23833 16.6266 5.86832 16.4799C5.53604 16.3336 5.16161 16.0807 4.90269 15.843C4.33657 15.3233 4 14.6005 4 13.8193C4 12.3205 5.2667 11.0084 6.95078 11.0084C6.97761 11.0084 7.00436 11.0087 7.03101 11.0094L8.76947 11.0531L9.05428 9.33759C9.36864 7.44404 11.0176 6 13 6C15.0519 6 16.7429 7.54493 16.9733 9.53501C17.0356 10.0733 17.4321 10.5 17.9727 10.5C18.5249 10.5 18.9727 10.0523 18.9727 9.5Z"
        fill="white"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12C9.34315 12 8 13.3431 8 15V17C8 18.6569 9.34315 20 11 20H19C20.6569 20 22 18.6569 22 17V15C22 13.3431 20.6569 12 19 12H11ZM19 14H11C10.4477 14 10 14.4477 10 15V17C10 17.5523 10.4477 18 11 18H19C19.5523 18 20 17.5523 20 17V15C20 14.4477 19.5523 14 19 14Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Cloud;
