export const seo = {
  title: "Условия акции",
  description: "Условия проведения акции «АСКУЭ  в облаке со скидкой 100 % на 12 месяцев»",
};

export const blockOne = {
  title: "1. Термины и определения",
  text: [
    "1.1. <b>Акция</b> - мероприятие для продвижения и привлечения внимания Участников Акции к ПО, проводимое на условиях, описанных в настоящих Условиях. Условия Акции размещены на сайте Организатора: <a href='https://energytool.mts.ru/' target='_blank' rel='noreferrer'>https://energytool.mts.ru/</a>.",
    "1.2. <b>Программный комплекс «Энергосфера»</b> (далее – ПО) – программа для ЭВМ, представляющее собой информационно-измерительная систему, предназначенную для автоматизации учета электроэнергии, тепловой энергии и расхода энергоносителей на предприятиях энергетики, промышленности, жилищно-коммунального хозяйства и других объектах. Программное обеспечение с интегрированной средой разработки, гибко обеспечивающий необходимую функциональность верхнего уровня систем учета различного вида.",
    "1.3. <b>Участник Акции</b> – юридическое лицо (или индивидуальный предприниматель), совершившее Акцепт настоящих Условий, соответствующее критериям, указанным в разделе 4 настоящих Условий.",
    "1.4. <b>Организатор</b> – компания, организовавшая Акцию, - Публичное акционерное общество «Мобильные Телесистемы» Юридический адрес: 109147, г. Москва, Марксистская ул., д. 4, ИНН 997750001.",
    "1.5. <b>ПУ</b> – прибор учёта электроэнергии, модельный ряд которого представлен в таблице 1 настоящих условий.",
    "1.6. <b>АСКУЭ в облаке</b> - Это- программный комплекс «Энергосфера», который является платформой коммерческого учёта энергии, установленный на облачных серверах ПАО МТС. Программный комплекс предназначен для создания автоматизированных систем коммерческого и технического учета различных видов энергоресурсов: электроэнергии, тепловой энергии, расхода воды, пара, газа и др. Решение обеспечивает реализацию всех основных задач учета энергоресурсов: осуществляет автоматизированный сбор данных измерений с приборов учета, долговременное хранение учетных данных в специализированной базе данных, обработку первичных данных и предоставление необходимой информации пользователям системы в различных видах (графиках, таблицах, журналах, отчетах).",
    "",
  ],
};

export const blockTwo = {
  title: "2. Описание Акции.",
  text: [
    "2.1. В соответствии с условиями Акции Организатор обязуется предоставить Участникам акции скидку в размере 100% на лицензионное вознаграждение за использование ПО на весь период, указанный в п. 3.2 настоящих Условий. Скидка предоставляется Участникам Акции, отвечающим критериям, указанным в разделе 4 настоящих Условий.",
    "2.2. Скидка предоставляется исключительно для ознакомления, тестирования Участником Акции ПО для целей принятия решения о целесообразности дальнейшего использования ПО на коммерческих условиях.",
  ],
};

export const blockThree = {
  title: "3. Период проведения Акции",
  text: [
    "3.1. Период проведения Акции (возможности выполнения Участником Акции требований к участию в Акции, указанных в разделе 4 настоящих Условий): с 14 ноября 2023 года по 14 мая 2024 года.",
    "3.2. Скидка предоставляется на срок 12 месяцев с момента выполнения Участником Акции требований к участию в Акции, указанных в разделе 4 настоящих Условий.",
    "3.3. По истечении срока предоставления Скидки тарификация за дистанционный доступ и эксплуатацию ПО осуществляется в порядке, установленном Пользовательским соглашением на программное обеспечение «АСКУЭ» (размещено на <a href='https://energytool.mts.ru/offer-license' target='_blank' rel='noreferrer'>https://energytool.mts.ru/offer-license</a> ), по тарифам согласно следующим условиям:",
    "Размер лицензионного вознаграждения за один прибор заведённый на платформу составляет 60 рублей за единицу (НДС не начисляется, так как Приказом Минкомсвязи России от 06.09.2016 № 426 ПО включено в Единый реестр российских программ для электронных вычислительных машин и баз данных под номером 1691).",
    "Общий размер лицензионного вознаграждения за АСКУЭ в облаке, составляет произведение общего количества ПУ на размер лицензионного вознаграждения за один Прибор (Общая стоимость= общее количество ПУ * 60 рублей)",
  ],
};

export const blockFourPrtOne = {
  title: "4. Участие в Акции",
  text: [
    "4.1. Участником Акции может быть лицо, которое на момент начала Акции не использует ПО.",
    "4.2. Для участия в Акции Участнику Акции необходимо:",
    "- приобрести у Организатора прибор учёта по цене, указанной в таблице 1:",
    "Таблица 1:",
  ],
};

export const blockFourPrtTwo = [
  "- ознакомиться с текстом настоящих Условий и принять их путем подписания заявления на присоединение к Условиям по форме, установленной Организатором;",
  "- ознакомиться с текстом и присоединиться к Оферте на лицензии EnergyTool (размещено на <a href='https://energytool.mts.ru/offer-license' target='_blank' rel='noreferrer'>https://energytool.mts.ru/offer-license</a>) в установленном ею порядке;",
  "- приобрести право использования на ПО;",
  "- заключить с Организатором договор оказания услуг связи на предоставление канала передачи данных от ПУ к ПО;",
  "- ознакомиться с текстом и присоединиться к Оферте на лицензии EnergyTool (размещено на <a href='https://energytool.mts.ru/offer-license' target='_blank' rel='noreferrer' >https://energytool.mts.ru/offer-license</a> ) в установленном ею порядке; - приобрести право использования на ПО;» объединить – «приобрести право использования на ПО, ознакомившись с текстом и присоединившись к Оферте на лицензии EnergyTool (размещено на <a href='https://energytool.mts.ru/offer-license' target='_blank' rel='noreferrer' >https://energytool.mts.ru/offer-license</a>) в установленном ею порядке;",
  "4.3. Количество подключаемых Участником Акции ПУ - не менее 1 (одного).",
];

export const blockFive = {
  title: "5. Прочие положения",
  text: [
    "5.1. Настоящие Условия составляют юридически обязывающее соглашение между Участником Акции, Организатором в отношении участия в Акции и действуют в течение периода проведения Акции, указанного в разделе 3 настоящих Условий.",
    "5.2. Организатор обязуется провести Акцию в порядке, определенном настоящими Условиями.",
    "5.3. Организатор вправе:",
    "- Приостановить или отказать в участии в Акции в случае нарушения, по мнению Организатора, Участником Акции положений настоящих Условий;",
    "- Изменять условия Акции в любое время, доводя обновленные условия Акции до Участников посредством сайта в сети Интернет: <a href='https://energytool.mts.ru' target='_blank' rel='noreferrer'> https://energytool.mts.ru</a>;",
    "- Отменить Акцию в любое время, доведя информацию об отмене Акции до Участников посредством сайта в сети Интернет: <a href='https://energytool.mts.ru' target='_blank' rel='noreferrer'> https://energytool.mts.ru </a>.",
    "5.4. В случае отказа Участника от участия в Акции или прекращения Акции по любым основаниям тарификация за Аналитику с дня, следующего, соответственно, за днем отказа от участия в Акции или прекращения Акции, осуществляется в порядке, установленном Офертой на лицензии EnergyTool (размещено на <a href='https://energytool.mts.ru' target='_blank' rel='noreferrer'>https://energytool.mts.ru</a>), по тарифу условия которого описаны в пункте 3.3 настоящих Условий.",
    "5.5. Организатор не несет ответственность за неисполнение (несвоевременное исполнение) Участником Акции обязанностей (требований), предусмотренных настоящими Условиями.",
    "5.6. Принимая участие в Акции, Участник Акции подтверждает, что полностью ознакомлен и согласен с настоящими Условиями.",
    "5.7. Во всем, что не предусмотрено настоящими Условиями, Организатор и Участник Акции руководствуются действующим законодательством Российской Федерации.",
    "5.8. Термины, употребляемые в настоящих Условиях, относятся исключительно к настоящей Акции.",
    "5.9. Организатор Акции, а также уполномоченные им лица не несут перед Участниками Акции ответственность за какие-либо прямые или косвенные потери Участника, связанные с участием в Акции. В любом случае ответственность МТС по Соглашению в соответствии со статьей 15 ГК РФ ограничивается суммой 10 000 (десять тысяч) рублей. Ответственность может быть возложена на МТС только при наличии в его действиях вины.",
    "5.10. Соглашаясь с Условиями, Участник Акции выражает свое согласие на получение рекламной и информационной рассылки от Организатора.",
    "5.11. Решения Организатора по всем вопросам, связанным с проведением Акции, являются окончательными и не подлежащими пересмотру.",
    "5.12. Все Участники Акции самостоятельно оплачивают все расходы, понесенные ими в связи с участием в Акции.",
    "5.13. Стороны согласились, что все споры, возникающие из отношений Сторон, регулируемых настоящим Соглашением, должны разрешаться в компетентном суде в соответствии с законодательством Российской Федерации.",
  ],
};

export const blockSix = {
  title: "6. Антикоррупционная оговорка",
  text: [
    "6.1. Участник Акции обязуется в рамках исполнения настоящих Условий соблюдать требования применимого антикоррупционного законодательства и не предпринимать никаких действий, которые могут нарушить нормы антикоррупционного законодательства или стать причиной такого нарушения Участником Акции, в том числе не требовать, не получать, не предлагать, не санкционировать, не обещать и не совершать незаконные платежи напрямую, через третьих лиц или в качестве посредника, включая (но не ограничиваясь) взятки в денежной или любой иной форме, каким-либо физическим или юридическим лицам, включая (но не ограничиваясь) коммерческим организациям, органам власти и самоуправления, государственным служащим, частным компаниям и их представителям.",
    "6.2. В случае нарушения Участником Акции изложенных выше антикоррупционных обязательств, Организатор вправе в одностороннем порядке приостановить исполнение своих обязательств по настоящим Условиям до устранения причин такого нарушения или отказаться от исполнения настоящих Условий, направив об этом письменное уведомление.",
  ],
};
