import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Support = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49864 1.49864C0.33164 2.66564 0.245109 3.8283 0.0720472 6.15362C0.0271852 6.7564 0 7.3782 0 8C0 8.6218 0.0271852 9.2436 0.0720473 9.84638C0.245109 12.1717 0.33164 13.3344 1.49864 14.5014C2.66564 15.6684 3.8283 15.7549 6.15362 15.928C6.7564 15.9728 7.3782 16 8 16C8.6218 16 9.2436 15.9728 9.84638 15.928C12.1717 15.7549 13.3344 15.6684 14.5014 14.5014C15.6684 13.3344 15.7549 12.1717 15.928 9.84638C15.9728 9.2436 16 8.6218 16 8C16 7.3782 15.9728 6.7564 15.928 6.15362C15.7549 3.8283 15.6684 2.66564 14.5014 1.49864C13.3344 0.33164 12.1717 0.245109 9.84638 0.0720472C9.2436 0.0271852 8.6218 0 8 0C7.3782 0 6.7564 0.0271852 6.15362 0.0720473C3.8283 0.245109 2.66564 0.33164 1.49864 1.49864ZM8.88894 4.44344C8.88894 5.05653 8.39193 5.55354 7.77884 5.55354C7.16574 5.55354 6.66874 5.05653 6.66874 4.44344C6.66874 3.83034 7.16574 3.33333 7.77884 3.33333C8.39193 3.33333 8.88894 3.83034 8.88894 4.44344ZM8.62854 7.37146C8.79524 7.53816 8.88889 7.76425 8.88889 8L8.88888 11.5556C8.88888 12.0465 8.49091 12.4444 7.99999 12.4444C7.50907 12.4444 7.1111 12.0465 7.1111 11.5556L7.11111 8.88889H6.66667C6.17575 8.88889 5.77778 8.49092 5.77778 8C5.77778 7.50908 6.17575 7.11111 6.66667 7.11111H8C8.23575 7.11111 8.46184 7.20476 8.62854 7.37146Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default Support;
