import React, { useEffect, useState, createContext, useContext } from "react";
import _ from "lodash";
import { breakpoints } from "theme";

interface IUseBreakpoint {
  isMobile: () => boolean;
  isDesktop: () => boolean;
  isTablet: () => boolean;
}

const getDeviceWidth = (val: number): string => {
  switch (true) {
    case val < breakpoints.tablet:
      return "tablet";
    case val < breakpoints.miniTablet:
      return "mobile";
    case val >= breakpoints.desktop:
      return "desktop";
    default:
      return "extra small";
  }
};

const defaultValue: IUseBreakpoint = {
  isMobile: () => false,
  isDesktop: () => false,
  isTablet: () => false,
};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider: React.FC = ({ children }) => {
  const [device, setDevice] = useState(() => getDeviceWidth(window.innerWidth));

  useEffect(() => {
    const calcWindowWidth = _.throttle(() => {
      setDevice(getDeviceWidth(window.innerWidth));
    }, 250);

    window.addEventListener("resize", calcWindowWidth);

    return (): void => {
      window.removeEventListener("resize", calcWindowWidth);
    };
  }, []);

  return (
    <BreakpointContext.Provider
      value={{
        isMobile: (): boolean => device === "mobile",
        isDesktop: (): boolean => device === "desktop",
        isTablet: (): boolean => device === "tablet",
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint(): IUseBreakpoint {
  return useContext(BreakpointContext);
}

export { BreakpointProvider, useBreakpoint };
