import styled from "@emotion/styled";
import { tool_base } from "components/pageFilter/styles";

export const SFltBtn = styled.div`
  ${(props) => tool_base(props.theme)};

  & svg {
    width: 20px;
    height: 20px;
  }

  & > .MuiSvgIcon-root {
    fill: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }
`;
