import {
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_ASKUE_PAGE,
  ROUTE_MINING_PAGE,
} from "stream-constants/route-constants";
import { IAccordionCustom } from "interface/common";
import Icon from "components/icons";

import productImg1 from "./images/product-1.png";
import productImg2 from "./images/product-2.png";
import productImg3 from "./images/product-3.png";
import advantageImg1 from "./images/advantage-1.svg";
import advantageImg2 from "./images/advantage-2.svg";
import advantageImg3 from "./images/advantage-3.svg";
import advantageImg4 from "./images/advantage-4.svg";
import advantageImg5 from "./images/advantage-5.svg";
import advantageImg6 from "./images/advantage-6.svg";
import instructionPdf from "../../files/instruction_lk.pdf";

export const advantageData: Array<{ icon: any; title: string; description: string }> = [
  {
    icon: advantageImg1,
    title: "Надежность и безопасность",
    description:
      "Дата-центры уровня Tier\u00A0III, наличие необходимых сертификатов, соответствие законодательным требованиям",
  },
  {
    icon: advantageImg2,
    title: "Гибкость и прозрачность",
    description:
      "Гибкая модель тарификации, прозрачная стоимость услуг, готовая и\u00A0легко масштабируемая инфраструктура",
  },
  {
    icon: advantageImg3,
    title: "Индивидуальный подход",
    description:
      "Каждому клиенту предлагаем кастомизацию решений, которые наилучшим образом подходят именно его\u00A0инфраструктуре",
  },
  {
    icon: advantageImg4,
    title: "Высокие оценки и\u00A0награды",
    description: "«Лучшее IT-решение для энергетики» лауреата премии «Цифровых\u00A0Вершин»",
  },
  {
    icon: advantageImg5,
    title: "Современные технологии",
    description:
      "Экспертиза в сфере ИТ и\u00A0опыт в\u00A0анализе больших данных, накопленные в\u00A0телекоммуникационном бизнесе",
  },
  {
    icon: advantageImg6,
    title: "Уникальное решение",
    description:
      "Код написан силами МТС. Используется ПО с\u00A0открытым кодом, одобренное в\u00A0РФ",
  },
];

export const productsCard: Array<{
  title: string;
  description?: string;
  nameBtn: string;
  isCallbackBlock?: boolean;
  img?: string;
  link?: string;
}> = [
  {
    title: "Поиск коммерческих потерь",
    description:
      "Программная платформа позволит быстро и\u00A0своевременно обнаружить точки коммерческих потерь и\u00A0выявить конкретные участки сети, на\u00A0которых происходит хищение",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg1,
    link: ROUTE_COMMERCIAL_LOSSES_PAGE,
  },
  {
    title: "Облачная платформа учета",
    description:
      "Облачное решение, которое упрощает сбор показаний и\u00A0значительно уменьшает затраты энергосбытовой компаний при\u00A0учете потребления электроэнергии",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg2,
    link: ROUTE_ASKUE_PAGE,
  },
  {
    title: "Выявление майнинга",
    description:
      "Наше решение поможет своевременно обнаружить подозрительную активность потенциальных майнинговых\u00A0ферм с\u00A0указанием их\u00A0расположения",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg3,
    link: ROUTE_MINING_PAGE,
  },
  {
    title: "Остались вопросы?",
    description: "Свяжитесь с нами и мы ответим на возникшие вопросы",
    nameBtn: "Связаться с нами",
    isCallbackBlock: true,
    link: "",
  },
];

export const accordionInfo: IAccordionCustom[] = [
  {
    title: "Работаете ли вы с персональными данными?",
    text: "Мы не работаем с персональными данными, для программных решений используются исключительно данные заказчика.",
  },
  {
    title: "Доступен ли пробный период?",
    text: "Доступен. Условия пробных периодов предоставляются индивидуально.",
  },
  {
    title: "Смогу ли я в дальнейшем изменить условия контракта?",
    text: "Да! Все программные решения ENERGYTOOL являются масштабируемыми и легко кастомизируются.",
  },
  {
    title: "Где мне взять установочный файл с программой?",
    text: "У нас SaaS-решение, ПО распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.",
  },
  {
    title: "Сколько стоит ваше решение?",
    text: "Стоимость рассчитывается индивидуально. Заявки направляйте по форме выше.",
  },

  {
    title: "Инструкция пользователя",
    pdf: {
      icon: <Icon.PDF />,
      text: "Инструкция входа в личный кабинет",
      link: instructionPdf,
      size: "12.9 Мб",
    },
  },
];

export const seo = {
  title: "ENERGYTOOL – умные инструменты, направленные для решения задач сфер ЖКХ и Энергетики",
  description:
    "Составные части продуктовой витрины EnergyTool можно выделить на следующие компоненты: 1) Оборудование для энергетических компаний, 2) АСКУЭ в облаке МТС, 3) Канал передачи данных от прибора учёта к АСКУЭ, 4) Поиск коммерческих потерь, 5) Поиск майнинговых ферм.",
};
