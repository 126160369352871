import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { declWords } from "utils/declensionWords";
import { ChxRenderer } from "./chxRenderer/checkbox-renderer";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SBtns, SFltBtn, SResult, SWrap } from "./styles";
import { useBreakpoint } from "hooks/useBreakpoint";
import Icon from "components/icons";
import { SlideIn } from "./slideIn/slideIn.component";

export interface IColumn {
  column: string;
  value: any;
}

export type IKek = { [key: string]: string[] };
export type IItem = {
  [key: string]:
    | {
        id: number;
        name: string | null;
        friendlyName?: string;
      }[]
    | undefined;
};

interface IProp {
  dataLength: number;
  onApplyFilter: ({ filtersArr }) => void;
  defaultFilter: IKek;
  onResetFilter?: () => void;
  scheme: IItem;
  titleByKey: { [key: string]: string };
  slideIndex?: number;
  isShowMobView?: boolean;
}

export const PageFilter: React.FC<IProp> = ({
  dataLength,
  onApplyFilter,
  defaultFilter,
  onResetFilter,
  scheme,
  titleByKey,
  slideIndex = 410,
  isShowMobView = false,
}) => {
  const { isTablet } = useBreakpoint();
  const [isOpen, setOpen] = useState(false);
  const [dataRes, setDataRes] = useState<IKek>({
    ...defaultFilter,
  });

  const checkAll = (column: string, list: any[], makeReset?: boolean) => {
    if (makeReset) {
      setDataRes((s) => {
        return {
          ...s,
          [column]: [],
        };
      });
    } else {
      setDataRes((s) => {
        const vals = list.map((a) => a.name);
        return {
          ...s,
          [column]: vals,
        };
      });
    }
  };

  const toggleChbx = (column: string, name: string) => {
    setDataRes((s) => {
      const target = s[column] ?? [];
      const arr = target?.includes(name) ? target?.filter((a) => a !== name) : [...target, name];

      return {
        ...s,
        [column]: arr,
      };
    });
  };

  const handleApplyFilter = (filters) => {
    onApplyFilter({
      filtersArr: filters,
    });
  };

  const handleResetFilter = () => {
    setDataRes((s) => {
      const keys = Object.keys(s);
      return keys.reduce((sum, cur) => ({ ...sum, [cur]: [] }), {}) as any;
    });
    handleApplyFilter([]);
    onResetFilter?.();
  };

  useEffect(() => {
    setDataRes({
      ...defaultFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultFilter)]);

  useEffect(() => {
    let filtersArray: IColumn[] = Object.entries(dataRes).map(([key, val]) => {
      return { column: key, value: val };
    });
    handleApplyFilter(filtersArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRes]);

  useEffect(() => {
    const trg = document.getElementById("root");
    if (isOpen) {
      trg?.classList.add("forbid-overflow");
    } else {
      trg?.classList.remove("forbid-overflow");
    }

    return () => {
      trg?.classList.remove("forbid-overflow");
    };
  }, [isOpen]);

  return isTablet() && isShowMobView ? (
    <>
      <SFltBtn
        withValue={Object.values(dataRes)?.some((a) => !isEmpty(a))}
        onClick={() => setOpen(true)}
      >
        <Icon.Filter />
      </SFltBtn>
      <SlideIn
        isOpen={isOpen}
        close={() => setOpen(false)}
        zIndex={slideIndex}
        headerChildren={
          <CustomTypography variant="h4_medium" font="comp">
            Фильтр
          </CustomTypography>
        }
      >
        <SWrap>
          {Object.keys(scheme)?.map((b) => {
            return (
              <ChxRenderer
                key={`${b}_${scheme[b]}`}
                targetArray={scheme[b]}
                filterKey={b}
                isAllChecked={(scheme[b] ?? []).length === dataRes[b]?.length}
                toggleAll={checkAll}
                toggleSingle={toggleChbx}
                currFilters={dataRes}
                titleByKey={titleByKey}
                isShowMobView={isShowMobView}
              />
            );
          })}
        </SWrap>
        <SBtns>
          <MTSButton size="M" variant="dark_blue" onClick={handleResetFilter}>
            Сбросить все
          </MTSButton>
        </SBtns>
        <Spacer value="24px" />
        <SResult>
          <CustomTypography variant="c1_regular">
            Найдено {declWords(dataLength, ["результат", "результата", "результатов"])}
          </CustomTypography>
        </SResult>
      </SlideIn>
    </>
  ) : (
    <>
      <SWrap>
        {Object.keys(scheme).map((a) => {
          return (
            <ChxRenderer
              key={`${a}_${scheme[a]}`}
              targetArray={scheme[a]}
              filterKey={a}
              isAllChecked={(scheme[a] ?? []).length === dataRes[a]?.length}
              toggleAll={checkAll}
              toggleSingle={toggleChbx}
              currFilters={dataRes}
              titleByKey={titleByKey}
            />
          );
        })}
      </SWrap>
      <SBtns>
        <MTSButton size="M" variant="secondary" onClick={handleResetFilter}>
          Сбросить все
        </MTSButton>
      </SBtns>
      <Spacer value="24px" />
      <SResult>
        <CustomTypography variant="c1_regular" font="comp">
          Найдено {declWords(dataLength, ["результат", "результата", "результатов"])}
        </CustomTypography>
      </SResult>
    </>
  );
};
