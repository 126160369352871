import { useMemo, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { IShopProduct } from "interface/common";
import { Loader } from "components/spinner";
import { CardDevice } from "../shopCard/shopCards.component";
import { useBreakpoint } from "hooks/useBreakpoint";
import Icon from "components/icons";
import { SSliderWrap } from "./styles";

const makeCards = (items: IShopProduct[] | undefined, isTablet: () => boolean) =>
  items?.map((a) => (
    <div style={{ width: isTablet() ? 180 : 296, padding: `0 ${isTablet() ? "6px" : "24px"} 0 0` }}>
      <CardDevice
        product={a}
        key={`${a.category}_${a.id}`}
        style={{ height: isTablet() ? 340 : 480 }}
      />
    </div>
  ));

export const ItemsSlider = ({ items, isLoading }) => {
  const [mainIndex, setMainIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const { isTablet } = useBreakpoint();

  const cards = useMemo(() => makeCards(items, isTablet), [isTablet, items]);

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === "action") {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  return isLoading ? (
    <>
      <Loader />
    </>
  ) : (
    <SSliderWrap disableBtns={isTablet()}>
      <AliceCarousel
        autoWidth
        activeIndex={mainIndex}
        animationType="slide"
        animationDuration={400}
        disableDotsControls={false}
        disableButtonsControls={isTablet()}
        items={cards as any}
        onSlideChange={syncMainBeforeChange}
        onSlideChanged={syncMainAfterChange}
        touchTracking={!thumbAnimation}
        mouseTracking={!thumbAnimation}
        infinite
        renderPrevButton={() => {
          return <Icon.ArrowLeft sx={{ width: 24 }} />;
        }}
        renderNextButton={() => {
          return <Icon.ArrowRight sx={{ width: 24 }} />;
        }}
      />
    </SSliderWrap>
  );
};
