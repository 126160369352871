import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: false,
      // notifyOnChangeProps: "tracked",
      staleTime: 10 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const ReactQueryWrapper: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default ReactQueryWrapper;
ReactQueryWrapper.displayName = 'ReactQueryWrapper';
