import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ROUTE_SHOP } from "stream-constants/route-constants";
import { useBreakpoint } from "hooks/useBreakpoint";
import { PhotosSlider } from "components/shop/shopSlider/photosSlider";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { InfoRight, InfoTabs } from "components/shop/shopItem/shopItem";
import { useCatalogItem } from "hooks/hooks";
import { Loader } from "components/spinner";
import { CustomTypography } from "components/customTypography/custom-typography";
import { calcSeo } from "./const";
import { SBox, SGrid } from "./styles";

export const ShopItem = () => {
  let { id } = useParams();
  const { isTablet } = useBreakpoint();
  const { data, isLoading } = useCatalogItem(id!);
  const { title, desc } = calcSeo(id!);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <SBox>
        <div>
          <Back link={`/${ROUTE_SHOP}`} />
          <Spacer value={isTablet() ? "24px" : "48px"} />
          {isLoading ? (
            <>
              <Spacer value="150px" />
              <Loader />
              <Spacer value="150px" />
            </>
          ) : data != null ? (
            <>
              <SGrid>
                <div>
                  <PhotosSlider photos={data.photos} />
                </div>
                <div>
                  <InfoRight product={data} />
                </div>
              </SGrid>
              <Spacer value={isTablet() ? "20px" : "48px"} />
              <InfoTabs product={data} />
            </>
          ) : (
            <CustomTypography variant={isTablet() ? "c1_bold" : "h2_bold"}>
              Ошибка запроса данных
            </CustomTypography>
          )}
        </div>
        <Spacer value="100px" />
      </SBox>
    </>
  );
};
