import Icon from "components/icons";
import { SArrow, SPlus } from "./styles";

interface AccordionIconProps {
  isActive?: boolean;
  onToggle?: (val: number) => void;
  index?: number;
  isArrowIcon?: boolean;
}

export const AccordionIcon = (props: AccordionIconProps) => {
  const { isActive, onToggle, index = 0, isArrowIcon = false } = props;

  const handleOnIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (onToggle) onToggle(index);
    e.stopPropagation();
  };

  return isArrowIcon ? (
    <SArrow onClick={(e) => (isArrowIcon ? handleOnIcon(e) : () => {})}>
      <span className={`${isActive ? "active" : ""}`}>
        {isArrowIcon ? <Icon.ArrowHeadRight sx={{ width: 24, height: 24 }} /> : null}
      </span>
    </SArrow>
  ) : (
    <SPlus onClick={(e) => (isArrowIcon ? handleOnIcon(e) : () => {})}>
      <span className={`icon ${isActive ?? "active"}`}></span>
    </SPlus>
  );
};

export default AccordionIcon;
