import { toast } from "react-toastify";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useDeleteInfo } from "hooks/hooks";
import { EUserInfoCard } from "interface/common";
import { Toast } from "components/toast/toast";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SControls, SWrap } from "./styles";

interface IDeleteUserInfo {
  onClose: () => void;
  typeInfo: keyof typeof EUserInfoCard;
}

export const DeleteUserInfo: React.FC<IDeleteUserInfo> = ({ onClose, typeInfo }) => {
  const { isTablet } = useBreakpoint();
  const mutation = useDeleteInfo();

  const handleCloseForm = () => {
    onClose();
  };

  const onDeleteInfo = async () => {
    mutation.mutate(
      {
        user_info_param: typeInfo,
      },
      {
        onSuccess: () => {
          handleCloseForm();
          toast(<Toast title="Информация обновлена" />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
        onError: () => {
          handleCloseForm();
          toast(<Toast title="Информация не обновлена" isError />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
      },
    );
  };

  return (
    <SWrap>
      <CustomTypography
        variant={isTablet() ? "h4_medium" : "h3_medium"}
        styles={{ display: "block", textAlign: "center" }}
        font="comp"
      >
        Вы действительно хотите удалить информацию?
      </CustomTypography>
      <Spacer value="20px" />
      <SControls>
        <MTSButton size="M" variant="primary" type="submit" onClick={onDeleteInfo}>
          Удалить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={handleCloseForm}>
          Отмена
        </MTSButton>
      </SControls>
    </SWrap>
  );
};
