import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.7489 11.5774C11.0837 11.9028 11.0837 12.4305 10.7489 12.7559C10.4142 13.0814 9.8715 13.0814 9.53677 12.7559L5.25105 8.58926C4.91632 8.26382 4.91632 7.73618 5.25105 7.41074L9.53677 3.24408C9.8715 2.91864 10.4142 2.91864 10.7489 3.24408C11.0837 3.56951 11.0837 4.09715 10.7489 4.42259L7.06933 8L10.7489 11.5774Z" />
    </SvgIcon>
  );
};

export default ArrowLeft;
