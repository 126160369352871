import { IHttpMethods } from "stream-constants/server-constants";
import { FORM_REGISTRATION } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

export const registrationUser = async (data: any) => {
  let result = fetchData({
    url: FORM_REGISTRATION,
    method: IHttpMethods.POST,
    name: "formRegistration",
    body: JSON.stringify(data),
    emptyResponse: false,
  });

  return result;
};
