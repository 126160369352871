import {
  ROUTE_ASKUE_PAGE,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_CONDITION_PROMOTION,
  ROUTE_MINING_PAGE,
  ROUTE_OFFER_EQUIPMENT,
  ROUTE_OFFER_LICENSE,
  ROUTE_USER_AGREEMENT,
} from "stream-constants/route-constants";

export const footerLinks: Array<{ link: string; name: string }> = [
  {
    name: "Облачная платформа учета",
    link: ROUTE_ASKUE_PAGE,
  },
  {
    name: "Поиск коммерческих потерь",
    link: ROUTE_COMMERCIAL_LOSSES_PAGE,
  },
  {
    name: "Выявление майнинговых ферм",
    link: ROUTE_MINING_PAGE,
  },
  {
    name: "Обработка персональных данных",
    link: ROUTE_USER_AGREEMENT,
  },
  {
    name: "Оферта на поставку оборудования",
    link: ROUTE_OFFER_EQUIPMENT,
  },
  {
    name: "Оферта на лицензии EnergyTool",
    link: ROUTE_OFFER_LICENSE,
  },
  {
    name: "Условия акции",
    link: ROUTE_CONDITION_PROMOTION,
  },
];
