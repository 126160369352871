import styled from "@emotion/styled";
import { device } from "theme";
import { STable, STableTitle } from "components/table/styles";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 1304px;
  width: 100%;
  padding: 80px 20px 200px 20px;
  background: white;

  @media ${device.tabletPlus} {
    grid-template-columns: 292px 1fr;
    gap: 32px;
  }
`;

export const SWrap = styled.div`
  overflow: hidden;
  & > section {
    padding-top: 48px;
  }
  & .empty {
    text-align: left;
  }

  & ${STable} ${STableTitle} {
    padding-top: 48px;
  }

  @media ${device.tabletPlus} {
    overflow: initial;
  }
`;

export const SFilters = styled.div`
  display: flex;
  gap: 4px;

  & > div:first-of-type {
    flex: 1;
  }
`;
