import styled from "@emotion/styled";
import { MTSButton } from "components/mts-button/mts-button";
import { device } from "theme";

export const STags = styled.div`
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  & span {
    display: inline-block;
    padding: 2px 8px;
    white-space: nowrap;
    background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    border-radius: 4px;
  }
`;

const barHeight = 4;
const padTopDesk = 24;
const padTopMob = 8;
const tabHeightMob = 36;
const tabHeightDesk = 68;

export const STabHead = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: ${tabHeightMob}px;
  margin: 0 auto;
  max-width: 1048px;

  @media ${device.tabletPlus} {
    justify-content: flex-start;
    height: ${tabHeightDesk}px;
  }
`;

export const STabItem = styled.div<{ isActive: boolean }>`
  height: 100%;
  padding: ${padTopMob}px 0;
  cursor: pointer;

  & > span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    user-select: none;

    &:after {
      content: "";
      position: absolute;
      top: calc(${tabHeightMob}px - ${padTopMob}px);
      left: 0;
      width: 100%;
      height: ${barHeight}px;
      background-color: ${(props) => (props.isActive ? "red" : "transparent")};
    }
  }

  @media ${device.tabletPlus} {
    padding: 22px ${padTopDesk}px;

    & > span {
      font-size: 16px;

      &:after {
        top: calc(${tabHeightDesk}px - ${padTopDesk}px - ${barHeight / 2}px);
      }
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 12px;

  & > div {
    font-size: 10px;
    &:first-of-type {
      flex: 1 1 auto;
      margin-right: 16px;
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }

    &:last-of-type {
      margin-left: auto;
      font-weight: 500;
      white-space: nowrap;
    }

    & > a {
      font-weight: 400;
      text-decoration: none;
      color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    }
  }

  @media ${device.tabletPlus} {
    padding: 14px 24px;

    & > div {
      font-size: unset;
      &:first-of-type {
        color: unset;
      }
    }
  }
`;

export const STabBody = styled.div`
  max-width: 100%;

  & > ${SRow} {
    background: ${(props) => props.theme.mtsColor.background.primary.lightMode};
    &:not(:last-of-type) {
      margin-bottom: 2px;
    }

    @media ${device.tabletPlus} {
      margin: 0;
      background: transparent;
      &:nth-child(2n) {
        background: ${(props) => props.theme.mtsColor.background.primary.lightMode};
      }
    }
  }
`;

export const SSticky = styled.div`
  position: sticky;
  bottom: 0;
  padding: 24px 0;
  background: white;
`;

export const SMobBtns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  & ${MTSButton} {
    width: auto;

    & button {
      width: auto !important;
    }
  }
`;
