import React, { useLayoutEffect, useState } from "react";
import { CustomTypography } from "components/customTypography/custom-typography";
import AccordionIcon from "components/accordion-icon";
import { SAccBody, SHeadWrap, SLink, SRow, SSubItem } from "./styles";

interface MobMenuAccordProps {
  data: any;
  close: () => void;
  isArrowIcon?: boolean;
}

export const MobMenuAccordion = (props: MobMenuAccordProps) => {
  const { data, close, isArrowIcon } = props;
  const contentEl = React.useRef<any>([]);
  const [expanded, setExpanded] = useState<number>(-1);
  const [heightDetail, setHeightDetail] = useState<number>(72);

  const expandedAccordionSummary = (obj: any, index: number) =>
    obj.subitem ? (
      <AccordionIcon
        isActive={expanded === index}
        onToggle={() => handleToggle(index)}
        index={index}
        isArrowIcon={isArrowIcon}
      />
    ) : null;

  const handleToggle = (index: number) => setExpanded(expanded === index ? -1 : index);

  useLayoutEffect(() => {
    if (contentEl.current) {
      const newVal = contentEl?.current[expanded]?.clientHeight;
      setHeightDetail(newVal);
    }
  }, [expanded]);

  return (
    <div>
      {data.map((item: any, index: number) => (
        <div key={index}>
          <SHeadWrap
            to={item.link}
            onClick={(e) => {
              setExpanded(index);
            }}
          >
            <SLink to={item.link} onClick={close}>
              <SRow>
                <CustomTypography variant={item.isActive ? "p3_medium" : "p3_regular"}>
                  {item.title}
                </CustomTypography>
              </SRow>
              {expandedAccordionSummary(item, index)}
            </SLink>
          </SHeadWrap>
          <SAccBody style={{ height: expanded === index ? heightDetail : 0 }}>
            <ul ref={(el) => (contentEl.current[index] = el)}>
              {item.subitem?.map((element: any) => (
                <li key={element.title}>
                  <SSubItem to={element.link} onClick={close}>
                    <CustomTypography variant="p4_regular">{element.title}</CustomTypography>
                  </SSubItem>
                </li>
              ))}
            </ul>
          </SAccBody>
        </div>
      ))}
    </div>
  );
};
