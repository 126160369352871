import { Typography, TypographyTypeMap } from "@mui/material";
import { ElementType } from "react";
import { MTS_COMPACT, MTS_TEXT, MTS_WIDE } from "theme";

interface IProps {
  children: React.ReactNode | string;
  variant?: TypographyTypeMap["props"]["variant"];
  font?: "sans" | "wide" | "comp";
  styles?: React.CSSProperties;
  component?: ElementType<any>;
  className?: string;
  onClick?: () => void;
}

export const CustomTypography = ({
  children,
  variant = "p2_regular",
  font = "sans",
  styles,
  className = "",
  onClick,
}: IProps) => {
  return (
    <Typography
      variant={variant}
      className={className}
      sx={{
        fontFamily: font === "wide" ? MTS_WIDE : font === "comp" ? MTS_COMPACT : MTS_TEXT,
        ...styles,
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};
