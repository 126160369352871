import styled from "@emotion/styled";
import theme from "theme";
import { Link } from "react-router-dom";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SWrap = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
`;

export const SLogo = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  background-color: #ff0032;
`;

export const SName = styled(CustomTypography)<{ isLight?: boolean }>`
  padding: 3px 0 0;
  line-height: 17px;
  white-space: pre-wrap;
  text-align: left;
  ${(props) =>
    props.isLight
      ? `color: ${theme.mtsColor.text.headline.darkMode}`
      : `color: ${theme.mtsColor.text.headline.lightMode}`}
`;
