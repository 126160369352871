import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import IntroSection from "components/intro-section/intro-section";
import { AccordionCustom } from "components/accordion-custom/accordion-custom";
import { SectionTitle } from "components/section-title/section-title";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { CustomTypography } from "components/customTypography/custom-typography";
import { AdditInfoList } from "components/additional-info-list/additional-info";
import { PromoSection } from "components/section-promo/section-promo";
import Icon from "components/icons";
import { AskuePromo } from "components/section-promo/subComponent/akue-promo";
import {
  APNContent,
  accordionInfo,
  additionalInfo,
  productsAskue,
  seo,
  tariffsAskue,
} from "./const";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { promoQuery } from "pages/shop/const";
import {
  SContainer,
  SMain,
  SInfoProd,
  STop,
  SInfo,
  STariffs,
  STariffCard,
  SPrice,
  SAnnotation,
  SProducts,
  SProdCard,
  SSectionProd,
} from "./styles";

import bgImg from "./images/hero_bg.png";
import promoSectionBgImg from "../landingAskue/images/promo-section-bg.png";

const onClickAnchorLink = (e: any, anchor: string) => {
  e.preventDefault();
  document.querySelector(anchor)?.scrollIntoView({ behavior: "smooth" });
};

export const CostIntegration = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <IntroSection
          title={"Стоимость\nвнедрения АСКУЭ"}
          subtext="Предлагаем комплексные решения на базе ПК «Энергосфера»"
          bgImg={bgImg}
        />
        <SSectionProd>
          <SContainer>
            <SProducts>
              {productsAskue.map((item) => (
                <SProdCard key={item.id}>
                  <h3>
                    <CustomTypography variant="h3_medium" font="wide">
                      {item.title}
                    </CustomTypography>
                  </h3>
                  <div>
                    <CustomTypography variant="h3_medium" font="wide">
                      {item.price}
                    </CustomTypography>
                    {item.priceText ? (
                      <CustomTypography variant="p4_regular" font="comp">
                        {item.priceText}
                      </CustomTypography>
                    ) : null}
                  </div>
                  {item.anchor ? (
                    <a href={item.link[1]} onClick={(e) => onClickAnchorLink(e, item.link[1])}>
                      <CustomTypography variant="c1_regular" font="comp">
                        {item.link[0]}
                      </CustomTypography>
                      <Icon.ArrowRight />
                    </a>
                  ) : (
                    <Link rel="noreferrer" to={`/${item.link[1]}`}>
                      <CustomTypography variant="c1_regular" font="comp">
                        {item.link[0]}
                      </CustomTypography>
                      <Icon.ArrowRight />
                    </Link>
                  )}
                </SProdCard>
              ))}
            </SProducts>
          </SContainer>
        </SSectionProd>
        <section>
          <SContainer>
            <SectionTitle title="Тарифы" />
            <STariffs>
              {tariffsAskue.map((item) => (
                <STariffCard key={item.id}>
                  <div>
                    <h3>
                      <CustomTypography variant="h3_medium" font="wide">
                        {item.title}
                      </CustomTypography>
                      <CustomTypography variant="h4_medium" font="wide">
                        {item.subtitle}
                      </CustomTypography>
                    </h3>
                    {item.text.map((e, index) => (
                      <CustomTypography variant="c1_regular" font="comp" key={`text-${index}`}>
                        {e}
                      </CustomTypography>
                    ))}
                  </div>
                  <div>
                    <SPrice>
                      <CustomTypography variant="h3_bold" font="wide">
                        от {item.price} ₽/месяц*
                      </CustomTypography>
                    </SPrice>
                    <MTSButton size="S" onClick={() => undefined}>
                      Подключить
                    </MTSButton>
                  </div>
                </STariffCard>
              ))}
            </STariffs>
            <SAnnotation>
              <CustomTypography variant="p4_regular" font="comp">
                *Цены указаны для опроса по GPRS/NB-IoT без НДС. Для других видов связи цена по
                запросу.
              </CustomTypography>
              <CustomTypography variant="p4_regular" font="comp">
                Расширение функционала базовых моделей (Интеграция с внешними системами -
                биллинговые системы, классификаторы адресов, ГИС-системы, XML80020, и др.) - цена по
                запросу.
              </CustomTypography>
            </SAnnotation>
            <section>
              <Spacer value="48px" />
              <AdditInfoList data={additionalInfo} />
            </section>
          </SContainer>
        </section>
        <SInfoProd id="apn">
          <SContainer>
            <STop>
              <SectionTitle title="Access Point Name (APN)" styles={{ marginBottom: "16px" }} />
              <CustomTypography variant="p2_regular" font="comp">
                Позволяет абоненту мобильной сети осуществлять защищенную передачу данных между
                прибором учета (ПУ) и АСКУЭ.
              </CustomTypography>
            </STop>
            <Spacer value="48px" />
            <SInfo>
              {APNContent.map((item, i) => (
                <div key={i}>
                  <div>
                    <CustomTypography variant="p4_regular" font="comp">
                      {item.title}
                    </CustomTypography>
                  </div>
                  <div>
                    <CustomTypography variant="p4_medium" font="comp">
                      {item.text}
                    </CustomTypography>
                  </div>
                </div>
              ))}
            </SInfo>
          </SContainer>
        </SInfoProd>
        <PromoSection bgImg={promoSectionBgImg} maxWidth="75%">
          <AskuePromo handleClickBtn={() => navigate(`/${ROUTE_SHOP}${promoQuery}`)} />
        </PromoSection>
        <section>
          <SContainer>
            <AccordionCustom data={accordionInfo} />
          </SContainer>
        </section>
      </SMain>
    </>
  );
};
