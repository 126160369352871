import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowHeadRight = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_301_7039)">
      <path d="M8 18.9875C8 19.8877 9.07659 20.3386 9.70623 19.702C9.70623 19.702 9.70623 19.702 9.70623 19.702L12.8016 16.5727C14.9339 14.4171 16 13.3393 16 12C16 10.6607 14.9339 9.58288 12.8016 7.42729L9.70623 4.29797C9.07659 3.66144 8 4.11226 8 5.01246C8 5.28044 8.1053 5.53745 8.29274 5.72695L11.3882 8.85626C12.4942 9.97445 13.1786 10.6724 13.6112 11.2456C14.0016 11.7629 14.0012 11.9435 14.001 11.9972L14.001 12L14.001 12.0027C14.0012 12.0565 14.0016 12.2371 13.6112 12.7544C13.1786 13.3276 12.4942 14.0255 11.3882 15.1437L8.29274 18.2731C8.29274 18.2731 8.29274 18.2731 8.29274 18.2731C8.1053 18.4625 8 18.7196 8 18.9875Z" fill="#1D2023"/>
      </g>
      <defs>
      <clipPath id="clip0_301_7039">
      <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)"/>
      </clipPath>
      </defs>  
    </SvgIcon>
  );
};

export default ArrowHeadRight;
