import { createContext } from "react";
import { LIST_ROLES } from "utils/roles";

export interface ICart {
  [key: string]: {
    qty: number;
  };
}

export type ISetCart = {
  id: number;
  quant?: number;
  action?: "inc" | "decr";
};

interface Context {
  isOpenFormConsultation: boolean;
  changeStateFormConsultation: (val: boolean) => void;
  roles: LIST_ROLES[];
  setRoles: (array: LIST_ROLES[]) => void;
  isAuthorized: boolean;
  setIsAuthorized: (status: boolean) => void;
  cart: ICart;
  removeFromCart: (val: number, withFlag?: boolean) => void;
  setCartAmount: ({ id, quant, action }: ISetCart) => void;
  clearCart: () => void;
}

export const ContextSettings = createContext<Context>({
  isOpenFormConsultation: false,
  changeStateFormConsultation: (val: boolean) => {},
  isAuthorized: false,
  setIsAuthorized: (status: boolean) => {},
  roles: [],
  setRoles: (array: string[]) => {},
  cart: {},
  removeFromCart: (val: number) => {},
  setCartAmount: ({ id, quant, action }: ISetCart) => {},
  clearCart: () => {},
});
