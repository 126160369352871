import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Minus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3620_3016)">
        <rect x="4" y="11" width="16" height="2" rx="1" fill="#1D2023" />
      </g>
      <defs>
        <clipPath id="clip0_3620_3016">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Minus;
