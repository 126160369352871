import styled from "@emotion/styled";
import { device } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SSection = styled.section`
  padding: 80px 0;
  width: 100%;

  @media ${device.miniTabletNeg} {
    padding: 64px 0;
  }
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const SAdvantage = styled.section`
  padding: 80px 0;
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 32px;
  row-gap: 32px;

  @media ${device.tabletNeg} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    rowgap: 16px;
  }

  @media ${device.miniTabletNeg} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
