import styled from "@emotion/styled";

export const SBtn = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
`;
