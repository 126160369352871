import Icon from "components/icons";
import { SClose, SContent, SModBox, SOuter } from "./styles";

export const MTSModal = ({
  open,
  close,
  children,
  maxWidth,
}: {
  open: boolean;
  close: any;
  children: any;
  maxWidth?: number;
}) => {
  return (
    <SOuter
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropProps={{ timeout: 0 }}
      onClose={close}
    >
      <SModBox maxWidth={maxWidth}>
        <SContent>{children}</SContent>
        <SClose onClick={close}>
          <Icon.Close sx={{ width: 24, height: 24 }} />
        </SClose>
      </SModBox>
    </SOuter>
  );
};
