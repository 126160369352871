import {
  ROUTE_SHOP,
  ROUTE_SHOP_ITEM,
  ROUTE_ASKUE_DEMO,
  ROUTE_SHOP_CART,
  ROUTE_SHOP_CHECKOUT,
} from "stream-constants/route-constants";

const toCheckShop = [ROUTE_SHOP, ROUTE_SHOP_ITEM, ROUTE_SHOP_CART, ROUTE_SHOP_CHECKOUT];

const toCheckPage = [ROUTE_ASKUE_DEMO];

export const defineHeaderView = (href: string, flag: boolean) => {
  return toCheckShop.includes(href.substring(1)) && flag ? "white" : null;
};

export const isOnlyContentPage = (href: string) => {
  return !toCheckPage.includes(href.substring(1));
};
