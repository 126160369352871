import {
  LOG_IN,
  LOG_IN_VALIDATE,
  GET_ABOUT,
  GOTO_LK,
  returnToSubpath,
  LOG_OUT,
  WELCOME_PAGE_URL,
  LOGOUT_MTS_BY_ID,
} from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";

const fetch_default: any = {
  mode: "cors",
  cache: "no-store",
  credentials: "include",
  redirect: "follow",
};

export const login = (e?, sub?: string): void =>
  sub ? window.location.replace(returnToSubpath(sub)) : window.location.replace(LOG_IN);

export const redirectToLk = (): void => window.location.replace(GOTO_LK);

export const validate = async (): Promise<Response> => {
  return await fetch(LOG_IN_VALIDATE, {
    ...fetch_default,
    method: IHttpMethods.GET,
  });
};

export const fetchAbout = async (): Promise<Response> => {
  return await fetch(GET_ABOUT, {
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    redirect: "follow",
    method: IHttpMethods.GET,
  });
};

export const logout = async (): Promise<Response> => {
  return await fetch(LOG_OUT, {
    ...fetch_default,
    method: IHttpMethods.POST,
  });
};

export const handleLogout = async (callback) => {
  try {
    const response = await logout();
    if (response.ok) {
      callback(false);
      const params = `${WELCOME_PAGE_URL}`;
      window.location.href = LOGOUT_MTS_BY_ID(params);
    }
  } catch (error) {
    new Error(error);
  }
};
