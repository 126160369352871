export const text = {
  par_1: [
    "Нажимая кнопку «Отправить», Вы, действуя свободно, своей волей и в своем интересе, выражаете конкретное, сознательное и информированное согласие Публичному акционерному обществу «Мобильные ТелеСистемы» (109147, г. Москва, ул. Марксистская, дом. 4) (далее по тексту – МТС) на обработку Ваших персональных данных с использованием средств автоматизации и/или без использования таковых средств путем совершения следующих действий (операций) или совокупности следующих действий (операций): сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, для реализации следующих целей:",
  ],
  par_2: [
    "Настоящее согласие на обработку персональных данных действует с момента его акцепта и до момента достижения заявленных целей обработки персональных данных, с учетом сроков, предусмотренных действующим законодательством Российской Федерации.",
  ],
  par_3: [
    "Настоящее согласие на обработку персональных данных, в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» может быть отозвано Вами путем направления письменного заявления МТС по адресу: 109147, г. Москва, ул. Марксистская, дом. 4.",
  ],
  list: [
    "Формирование и рассмотрение заявок на приобретение лицензии на программное обеспечение «EnegryTool»;",
    "Использование программного обеспечения «EnegryTool»;",
    "Oсуществление МТС информационных, новостных и рекламных рассылок об услугах и сервисах МТС и Партнеров МТС; о проводимых МТС и Партнерами МТС акциях, специальных предложениях и программах лояльности;",
    "Проведение МТС маркетинговых исследований;",
    [
      "МТС может осуществлять обработку следующих категорий персональных данных:",
      "фамилия, имя, отчество; ИНН организации представителем которой Вы являетесь; контактная информация (номер телефона, адрес электронной почты).",
    ],
  ],
};

export const seo = {
  title: "Согласие на обработку персональных данных – EnergyTool",
  description:
    "Согласие на обработку персональных данных на сайте EnergyTool. Мы гарантируем конфиденциальность и безопасность ваших персональных данных.",
};
