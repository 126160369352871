import { useContext, useEffect, useState, ChangeEvent } from "react";
import { SectionTitle } from "components/section-title/section-title";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { ContextSettings } from "context/context-settings";
import { CustomTypography } from "components/customTypography/custom-typography";
import { calcContent } from "./const";
import {
  SWrap,
  SCalc,
  SResultWrap,
  SCalcInner,
  SFieldBlock,
  SMeasureUnit,
  SResultInner,
  SResNum,
  SResSum,
  SResExtent,
  SResRub,
  SResСurrency,
  SResOther,
  SResItem,
  SMainExtent,
  SMainRub,
  SMainSum,
  SMainTitle,
} from "./styles";

interface CalculatorProps {
  title?: string;
  isWhite?: boolean;
  className?: string;
  maxWidth?: string;
  sx?: any;
}

export const Calculator = (props: CalculatorProps) => {
  const context = useContext(ContextSettings);
  const [actualLosses, setActualLosses] = useState<number>(0.0);
  const [usefulVacation, setUsefulVacation] = useState<number>(0.0);
  const [cost, setCost] = useState<number>(0);
  const [resultLoss, setResultLoss] = useState<number>(0.0);
  const ratioCost = 0.3;
  const calculatedObj: { [key: string]: string[] } = {
    year: ["0.00", "тыс"],
    month: ["0.00", "тыс"],
    day: ["0.00", "тыс"],
    hour: ["0.00", "тыс"],
  };
  const [calculate, setСalculate] = useState(calculatedObj);
  const nameTime: string[] = ["year", "month", "day", "hour"];

  const calculationLoss = () => {
    const result = actualLosses * (usefulVacation / 100) * ratioCost * cost;
    setResultLoss(result);
  };

  function validInputCalculate(value: string | undefined, format: string) {
    if (value) {
      switch (format) {
        case "percent":
          if (Number(value) > 100) return "100";
          if (value.length > 3) return value.slice(0, 3);
          if (Number(value) < 0) return String(Math.abs(Number(value)));
          setActualLosses(Math.abs(Number(value)));
          return value;

        case "vacation":
          if (Number(value) < 0) return String(Math.abs(Number(value)));
          if (value.length > 11) return value.slice(0, 11);
          setUsefulVacation(Math.abs(Number(value)));
          return value;
      }
    }
  }

  function shortenNum(num: number, periods: string[]) {
    const digit: string[] = ["", "тыс", "млн", "млрд", "трлн", "квадрлн"];
    let thousands = 0;
    let coef = 0;

    function calcCost(param: number, key: string) {
      thousands = Math.floor((("" + Math.floor(param)).length - 1) / 3);
      coef = 1000 ** thousands;

      return (calculatedObj[key] = [(param / coef).toFixed(2), digit[thousands]]);
    }

    periods.forEach((item: string, index: number) => {
      switch (item) {
        case "year":
          calcCost(num, item);
          break;
        case "month":
          calcCost(num / 12, item);
          break;
        case "day":
          calcCost(num / 365, item);
          break;
        case "hour":
          calcCost(num / 8760, item);
          break;
      }
    });
  }

  useEffect(() => {
    calculationLoss();
    shortenNum(resultLoss, nameTime);
    setСalculate(calculatedObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualLosses, usefulVacation, cost, resultLoss]);

  return (
    <SWrap>
      <SCalc>
        <SCalcInner>
          <SectionTitle title="Сколько вы теряете?" styles={{ marginBottom: "16px" }} />
          <p>
            <CustomTypography variant="p2_regular" font="comp">
              Воспользуйтесь калькулятором для расчета коммерческих потерь вашей организации*
            </CustomTypography>
          </p>
          <div>
            <SFieldBlock>
              <MTSInput
                type="number"
                name="losses"
                placeholder="0"
                label="Укажите фактические потери за год"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const ev = event;
                  return (ev.target.value = String(
                    validInputCalculate(event.target.value, "percent"),
                  ));
                }}
              />
              <SMeasureUnit>%</SMeasureUnit>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                type="number"
                name="releasing"
                placeholder="0"
                label="Укажите полезный отпуск за год"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const ev = event;
                  return (ev.target.value = String(
                    validInputCalculate(event.target.value, "vacation"),
                  ));
                }}
              />
              <SMeasureUnit>кВт*ч</SMeasureUnit>
            </SFieldBlock>
            <SFieldBlock>
              <MTSInput
                type="number"
                name="cost"
                placeholder="0"
                label="Укажите стоимость за 1 кВт"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  const ev = event;
                  if (+value < 0) ev.target.value = String(Math.abs(+ev.target.value));
                  if (value.length > 4) ev.target.value = value.slice(0, 4);
                  setCost(Number(+value));
                }}
              />
              <SMeasureUnit>₽</SMeasureUnit>
            </SFieldBlock>
          </div>
          <p>
            <CustomTypography variant="p4_regular" font="comp">
              *Все расчеты являются предварительными и могут отличаться от фактически полученных
              расчетов коммерческих потерь
            </CustomTypography>
          </p>
        </SCalcInner>
      </SCalc>
      <SResultWrap>
        <SResultInner>
          <div>
            <SMainTitle>
              <CustomTypography variant="h3_medium">Ваши потери в год</CustomTypography>
            </SMainTitle>
            <SResNum>
              <SMainSum>{calculate.year[0]}</SMainSum>
              <SResСurrency>
                <SMainRub>
                  <CustomTypography variant="h2_medium">₽</CustomTypography>
                </SMainRub>
                <SMainExtent>
                  <CustomTypography variant="h2_bold">{calculate.year[1]}</CustomTypography>
                </SMainExtent>
              </SResСurrency>
            </SResNum>
          </div>
          <Spacer value="32px" />
          <SResOther>
            {calcContent.map((item, i) => (
              <SResItem key={i}>
                <div>
                  <div>
                    <CustomTypography
                      variant="p4_regular"
                      styles={{ display: "block", textAlign: "left" }}
                    >
                      {item.title}
                    </CustomTypography>
                  </div>
                  <SResNum>
                    <SResSum>{calculate[item.name][0]}</SResSum>
                    <SResСurrency>
                      <SResRub>₽</SResRub>
                      <SResExtent>
                        <CustomTypography variant="c1_regular">
                          {calculate[item.name][1]}
                        </CustomTypography>
                      </SResExtent>
                    </SResСurrency>
                  </SResNum>
                </div>
              </SResItem>
            ))}
          </SResOther>
          <Spacer value="48px" />
          <div>
            <MTSButton
              size="L"
              variant="dark_blue"
              onClick={() => context.changeStateFormConsultation(true)}
            >
              Сократить потери
            </MTSButton>
          </div>
        </SResultInner>
      </SResultWrap>
    </SWrap>
  );
};
