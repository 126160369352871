import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import theme from "theme";

import { ContextSettings } from "context/context-settings";
import { usePlaceOrder } from "hooks/hooks";
import {
  ROUTE_CHECKOUT_SUCCESS,
  ROUTE_OFFER_EQUIPMENT,
  ROUTE_USER_AGREEMENT,
} from "stream-constants/route-constants";
import { MTSButton } from "components/mts-button/mts-button";
import { Checkbox } from "components/checkbox/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { schemaOrder } from "./validation-schema";
import { ILocationState } from "pages/shopCheckout/shopCheckoutPage";
import { Toast } from "components/toast/toast";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SFieldBlock } from "./styles";

interface IForm {
  isUserDataFull: boolean;
  promocode: string;
  cartProducts: ILocationState[];
  setOrderRes: (a: boolean) => void;
  userId: number | null;
}

export const FormPlaceOrder: React.FC<IForm> = ({
  isUserDataFull,
  promocode,
  cartProducts,
  setOrderRes,
  userId,
}) => {
  const { clearCart } = useContext(ContextSettings);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<any>({
    resolver: yupResolver(schemaOrder),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const mutation = usePlaceOrder();
  const navigate = useNavigate();

  const onSubmit = async () => {
    mutation.mutate(
      {
        order: cartProducts.map((item) => {
          return {
            id: item.id,
            quantity: item.qty,
          };
        }),
        user_id: userId ?? null,
        promocode: promocode,
      },
      {
        onSuccess: (res) => {
          setOrderRes(true);
          navigate(`/${ROUTE_CHECKOUT_SUCCESS}`, { state: { shopOrderId: res } });
          clearCart();
        },
        onError: () => {
          setOrderRes(false);
          toast(<Toast title="Произошла ошибка. Повторите еще раз" isError />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SFieldBlock>
        <Controller
          name="agree"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                value="true"
                checked={value === "true"}
                onChange={(a) => onChange(a === value ? undefined : a)}
                label={
                  <CustomTypography
                    variant="c1_regular"
                    font="comp"
                    styles={{ color: theme.mtsColor.text.secondary.lightMode }}
                  >
                    Вы соглаcны с{" "}
                    <Link target="_blank" rel="noreferrer" to={`/${ROUTE_USER_AGREEMENT}`}>
                      условиями обработки персональных данных
                    </Link>
                  </CustomTypography>
                }
                color="red"
                size="S"
              />
            );
          }}
        />
        <Controller
          name="oferta"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                value="true"
                checked={value === "true"}
                onChange={(a) => onChange(a === value ? undefined : a)}
                label={
                  <CustomTypography
                    variant="c1_regular"
                    font="comp"
                    styles={{ color: theme.mtsColor.text.secondary.lightMode }}
                  >
                    Вы соглаcны на{" "}
                    <Link target="_blank" rel="noreferrer" to={`/${ROUTE_OFFER_EQUIPMENT}`}>
                      условиями оферты на поставку оборудования
                    </Link>
                  </CustomTypography>
                }
                color="red"
                size="S"
              />
            );
          }}
        />
      </SFieldBlock>
      <MTSButton
        size="M"
        variant="primary"
        type="submit"
        onClick={() => undefined}
        disabled={!(isValid && isUserDataFull)}
      >
        Отправить заявку
      </MTSButton>
    </form>
  );
};
