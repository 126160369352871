export const USER_IP = "Индивидуальный предприниматель";
export const USER_UL = "Юридическое лицо";

export const infoKeysUl = [
  "file",
  "tenant_name",
  "inn",
  "kpp",
  "ogrnip",
  "ogrn",
  "ur_adress",
  "owner_name",
  "phone",
  "email",
  "post_adress",
  "phone_add",
  "type_user",
];

export const infoKeysFl = [
  "owner_name",
  "type_user",
  "email",
  "tenant_name",
  "ur_adress",
  "phone",
  "inn",
  "ogrnip",
];
