import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  text-align: left;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 12px;

  & section {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    & > div {
      flex-basis: auto;
    }
  }

  @media ${device.tabletPlus} {
    padding-bottom: 24px;

    & section {
      display: flex;
      flex-flow: row nowrap;
      gap: 24px;

      & > div {
        flex-basis: 304px;
      }
    }
  }
`;

export const SControls = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 16px;

  & button {
    width: 100%;
  }
`;

export const SSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;

export const SFieldAdress = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;

  & > div {
    flex-basis: auto;
  }

  @media ${device.tabletPlus} {
    flex-flow: row nowrap;
    gap: 24px;

    & > div {
      &:not(:last-child) {
        flex-basis: 82px;
      }

      &:last-child {
        flex-basis: 160px;
      }
    }
  }
`;

export const SFieldBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  margin-bottom: 12px;

  & a {
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }

  @media ${device.tabletPlus} {
    margin-bottom: 24px;
  }
`;
