import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isEmpty } from "lodash";

import { IShopProduct } from "interface/common";
import { ContextSettings } from "context/context-settings";
import { useBreakpoint } from "hooks/useBreakpoint";
import { formatPrice } from "./utils";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { ROUTE_SHOP, ROUTE_SHOP_CART } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { emptyPrice } from "../const";
import { SBtns, SCard, SCategory, SDescription, SPrice, SPromoTags, STop } from "./styles";

const tooltipText = `Цены указаны для опроса по GPRS/NB-IoT без НДС.
Для других видов связи цена по запросу.
Расширение функционала базовых моделей (Интеграция с внешними системами - биллинговые системы, классификаторы адресов, ГИС-системы, XML80020, и др.) - цена по запросу`;

export const CardDevice: React.FC<{ product: IShopProduct; style?: React.CSSProperties }> = ({
  product,
  style,
}) => {
  const { isTablet } = useBreakpoint();

  return (
    <SCard style={style}>
      <summary>
        <img src={product.image_url} alt={product.name} />
        <SCategory>
          <CustomTypography variant={isTablet() ? "c2_regular" : "c1_regular"} font="comp">
            {product.category}
          </CustomTypography>
        </SCategory>
        <div>
          <CustomTypography variant={isTablet() ? "p4_medium" : "h4_medium"} font="comp">
            {product.name}
          </CustomTypography>
        </div>
      </summary>
      {!isEmpty(product.promotions) ? (
        <>
          <SPromoTags>
            {product.promotions.map((promo) => (
              <div key={promo.promotion_id}>
                <CustomTypography font="wide">{promo.promotion_bubble}</CustomTypography>
              </div>
            ))}
          </SPromoTags>
          <Spacer value="16px" />
        </>
      ) : null}
      <SBtns>
        <SPrice>
          <CustomTypography variant={isTablet() ? "p4_bold" : "h3_bold"} font="wide">
            {formatPrice(product.price)}
          </CustomTypography>
        </SPrice>
        <ShopButton id={product.id} />
        <Link to={`/${ROUTE_SHOP}/${product.id}`}>
          <MTSButton size={isTablet() ? "XS" : "S"} variant="secondary" onClick={() => undefined}>
            Подробнее
          </MTSButton>
        </Link>
      </SBtns>
    </SCard>
  );
};

export const CardService: React.FC<{ product: IShopProduct }> = ({ product }) => {
  const { isTablet } = useBreakpoint();

  return (
    <SCard>
      <STop>
        <div>
          <CustomTypography variant={isTablet() ? "p4_medium" : "h4_medium"} font="comp">
            {product.name}
          </CustomTypography>
        </div>
        <div>
          <CustomTypography variant={isTablet() ? "c2_medium" : "p3_medium"} font="comp">
            {product.type}
          </CustomTypography>
        </div>
      </STop>
      <Spacer value="16px" />
      <SDescription>
        <CustomTypography variant={isTablet() ? "c2_regular" : "c1_regular"} font="comp">
          {product.desc}
        </CustomTypography>
      </SDescription>
      <SBtns>
        <SPrice>
          <CustomTypography
            variant={isTablet() ? "p4_bold" : "h3_bold"}
            font="wide"
            styles={isTablet() ? { whiteSpace: "nowrap" } : {}}
          >
            {!product.price || product.price === -1
              ? emptyPrice
              : `от ${formatPrice(product.price)}/месяц`}
          </CustomTypography>
          {!isTablet() && product.tooltip ? (
            <CustomWidthTooltip
              title={<div style={{ fontWeight: 400, fontSize: "12px" }}>{tooltipText}</div>}
              arrow
              placement="right"
            >
              <span>
                <Icon.Info sx={{ width: 16, height: 16, margin: "0 0 0 16px" }} />
              </span>
            </CustomWidthTooltip>
          ) : null}
        </SPrice>
        <ShopButton id={product.id} isService />
        <Link to={`/${product.redirect!}`}>
          <MTSButton size={isTablet() ? "XS" : "S"} variant="secondary" onClick={() => undefined}>
            Подробнее
          </MTSButton>
        </Link>
      </SBtns>
    </SCard>
  );
};

export const ShopButton = ({ id, style = {}, isService = false }) => {
  const navigate = useNavigate();
  const { isTablet } = useBreakpoint();
  const { setCartAmount, cart } = useContext(ContextSettings);
  const isInCart = Boolean(cart[id]);

  return (
    <MTSButton
      size={isTablet() ? "XS" : "S"}
      variant={isInCart ? "dark_blue" : "primary"}
      onClick={() => (isInCart ? navigate(`/${ROUTE_SHOP_CART}`) : setCartAmount({ id, quant: 1 }))}
      icon={
        isInCart ? (
          <Icon.CheckShaped sx={{ width: 16, height: 16, path: { fill: "white" } }} />
        ) : (
          <Icon.Cart sx={{ width: 16, height: 16 }} />
        )
      }
      style={style}
    >
      {isInCart ? "В корзине" : isService ? "Подключить" : " Добавить в корзину"}
    </MTSButton>
  );
};
