import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";
import { device } from "theme";

export const SBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: white;
  text-align: left;

  & > section {
    margin: 0 auto;
    padding: 48px 20px 0 20px;
    max-width: 1304px;
    width: 100%;
    height: auto;
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  & > div {
    &:first-of-type {
      & > * {
        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }
  }

  @media ${device.desktopPlus} {
    grid-template-columns: 1fr 400px;
    gap: 32px;

    & > div {
      &:first-of-type {
        & > * {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
  }
`;

export const SInfo = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SDisclaim = styled.div`
  & > .MuiTypography-root {
    display: block;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;
