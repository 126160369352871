import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowBack = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0313 8.05541C21.0313 7.42202 20.5084 6.90855 19.8633 6.90855H3.99486L9.0084 1.98517C9.42947 1.57174 9.46186 0.921203 9.10557 0.471297L9.0084 0.363253C8.58732 -0.0501739 7.92475 -0.081976 7.46652 0.267847L7.35648 0.363253L0.347983 7.24446L0.299627 7.29487L0.235194 7.37268L0.151486 7.50026L0.0890566 7.62954L0.0477295 7.75036L0.0138423 7.92069L0.00585938 8.05541L0.00911583 8.1417L0.0295014 8.28562L0.0639576 8.4134L0.115278 8.54079L0.176394 8.6526L0.254411 8.76274C0.283476 8.79912 0.314732 8.83372 0.347983 8.86637L7.35648 15.7476C7.81264 16.1955 8.55223 16.1955 9.0084 15.7476C9.42947 15.3341 9.46186 14.6836 9.10557 14.2337L9.0084 14.1257L3.99486 9.20228H19.8633C20.5084 9.20228 21.0313 8.68881 21.0313 8.05541Z" />
    </SvgIcon>
  );
};

export default ArrowBack;
