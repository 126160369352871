import { useLocation } from "react-router-dom";
import Icon from "components/icons";
import { ROUTE_MAIN_PAGE, ROUTE_SHOP } from "stream-constants/route-constants";
import { SWrap, SName } from "./styles";

interface IProp {
  isLight?: boolean;
}

export const Logo: React.FC<IProp> = ({ isLight }) => {
  const { pathname } = useLocation();

  return (
    <SWrap to={ROUTE_MAIN_PAGE}>
      <Icon.CompanyLogo sx={{ width: 32, height: 32 }} />
      <SName variant="p3_medium" font="wide" isLight={isLight}>
        {pathname.includes(ROUTE_SHOP) ? "ENERGYTOOL\nSHOP" : "ENERGYTOOL"}
      </SName>
    </SWrap>
  );
};
