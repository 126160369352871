import { Helmet } from "react-helmet";

import { SectionTitle } from "components/section-title/section-title";
import { CustomTypography } from "components/customTypography/custom-typography";

import { pageContent, pageTitle, seo } from "./const";
import { SArticle, SContainer, SMain, SParag, STable } from "./styles";

export const OfferLicense = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <SectionTitle title={pageTitle} />
            <div>
              <SParag>
                {pageContent.map((item) => (
                  <>
                    {item.title ? (
                      <CustomTypography variant="p3_bold" font="comp">
                        {item.title}
                      </CustomTypography>
                    ) : null}
                    {item.text.map((text, index) =>
                      text === "isTable" && item.table ? (
                        <STable key={`table_${index}`}>
                          <table>
                            <tbody>
                              {item.table.map((tds, i) => (
                                <tr key={`tr_${i}`}>
                                  {tds.map((td, indx) => (
                                    <td>{td}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </STable>
                      ) : (
                        <CustomTypography variant="p3_regular" font="comp">
                          <span dangerouslySetInnerHTML={{ __html: text }} />
                        </CustomTypography>
                      ),
                    )}
                  </>
                ))}
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
