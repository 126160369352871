import styled from "@emotion/styled";

export const SWrap = styled.section`
  margin: 0 auto;
  max-width: 335px;
`;

export const SLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
