import styled from "@emotion/styled";
import { device, MTS_COMPACT, p1_styles } from "theme";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 320px;
  & > .MuiTypography-root {
    text-align: center;
    display: inline-block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const SIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const SModalTitle = styled(CustomTypography)`
  display: inline-block;
  width: 100%;
  font-family: ${MTS_COMPACT}, sans-serif;
  font-weight: 500;
  ${p1_styles as any};
`;

export const SBtns = styled.div`
  text-align: center;
  width: 100%;

  & button {
    width: 100%;

    @media ${device.mobilePlus} {
      max-width: 400px;
    }
  }
`;
