import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";
import { Link } from "react-router-dom";

export const SField = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const SAddNew = styled.div`
  display: flex;
  justify-content: left;
`;

export const SBtns = styled.div`
  display: grid;
  flex-flow: column nowrap;

  & .MuiButton-root {
    width: 100%;
  }
`;

export const SLink = styled(Link)`
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  text-decoration: none;
`;

export const STxtLink = styled(CustomTypography)`
  display: block;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  cursor: pointer;
`;

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const SDelIcon = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: flex-start;
  padding: 10px 4px;
  cursor: pointer;
  height: ${(props) => (props.isError ? "64px" : `44px`)};

  & > svg {
    color: ${(props) => props.theme.mtsColor.icons.secondary.lightMode};
    font-size: 24px;
  }
`;

export const SInp = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const SPers = styled(CustomTypography)`
  display: block;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  cursor: pointer;
`;
