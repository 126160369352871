import { useContext, useState } from "react";
import { MTSButton } from "components/mts-button/mts-button";
import { ContextSettings } from "context/context-settings";
import { SBody, SLeft, SRight, SSection } from "./styles";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormReg } from "components/forms/form-registration/form-registration";

interface SectionInfoProps {
  img?: string;
  children?: React.ReactNode | string;
  withBtnDemo?: boolean;
}

export const InfoSection = (props: SectionInfoProps) => {
  const { img, children, withBtnDemo } = props;
  const context = useContext(ContextSettings);
  const [showFormReg, setShowFormReg] = useState(false);
  const toggleShowFormReg = () => {
    setShowFormReg(!showFormReg);
  };

  return (
    <SSection>
      <div>
        <SBody>
          <SLeft>
            <img src={img} alt="background" />
          </SLeft>
          <SRight>
            <div>{children}</div>
            <div>
              <MTSButton size="L" onClick={() => context.changeStateFormConsultation(true)}>
                Заказать консультацию
              </MTSButton>
              {withBtnDemo ? (
                <MTSButton size="L" variant="secondary" onClick={() => toggleShowFormReg()}>
                  Попробовать Демо
                </MTSButton>
              ) : null}
            </div>
          </SRight>
        </SBody>
      </div>
      {withBtnDemo ? (
        <MTSModal open={showFormReg} close={() => toggleShowFormReg()}>
          <FormReg handleCancelClick={() => toggleShowFormReg()} />
        </MTSModal>
      ) : null}
    </SSection>
  );
};
