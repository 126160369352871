import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import theme from "theme";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useFeedbackInfo } from "hooks/hooks";
import { IShopCheckout } from "interface/common";
import { Toast } from "components/toast/toast";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { defaultValFeedbackInfo, schemaFeedback } from "./validation-schema";
import { MTSInput } from "components/mts-input";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { Checkbox } from "components/checkbox/checkbox";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SControls, SWrap } from "./styles";

interface IForm {
  data?: IShopCheckout;
  onClose: () => void;
}

export const FormFeedbackInfo: React.FC<IForm> = ({ data, onClose }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      ...defaultValFeedbackInfo,
      name: data?.feedback_info?.name ?? "",
      user_phone: data?.feedback_info?.user_phone ?? "",
      user_email: data?.feedback_info?.user_email ?? "",
    },
    resolver: yupResolver(schemaFeedback),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const { isTablet } = useBreakpoint();

  const mutation = useFeedbackInfo();
  const contactsInfoOrg = {
    name: data?.information?.owner_name ?? "",
    user_phone: data?.information?.phone ?? "",
    user_email: data?.information?.email ?? "",
    use_contacts_org: "true",
  };

  const handleCloseForm = () => {
    onClose();
    reset();
  };

  const onSubmit = async (sbmtData) => {
    mutation.mutate(
      {
        user_phone: sbmtData.user_phone,
        name: sbmtData.name,
        user_email: sbmtData.user_email,
      },
      {
        onSuccess: () => {
          handleCloseForm();
          toast(<Toast title="Информация обновлена" />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
        onError: () => {
          reset();
          toast(<Toast title="Информация не обновлена" isError />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
      },
    );
  };

  return (
    <SWrap>
      <CustomTypography variant={isTablet() ? "h4_medium" : "h3_medium"} font="comp">
        Укажите контакты для обратной связи
      </CustomTypography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spacer value={isTablet() ? "20px" : "32px"} />
        <MTSInput
          label="Ваше имя"
          placeholder="Иванов Петр Сергеевич"
          control={control}
          {...register("name", {
            required: true,
          })}
          errorMessage={errors?.name?.message}
        />
        <Spacer value={isTablet() ? "12px" : "32px"} />
        <section>
          <InputMask mask={PHONE_MASK} {...register("user_phone")}>
            {() => (
              <MTSInput
                label="Телефон"
                placeholder="+7"
                {...register("user_phone", {
                  required: true,
                })}
                control={control}
                errorMessage={errors?.user_phone?.message}
              />
            )}
          </InputMask>
          <MTSInput
            label="Электронная почта"
            placeholder="Необязательно"
            {...register("user_email")}
            control={control}
          />
        </section>
        <Spacer value={isTablet() ? "12px" : "24px"} />
        {data?.information?.owner_name ? (
          <>
            <Controller
              name="use_contacts_org"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    value="true"
                    checked={value === "true"}
                    onChange={(a) => {
                      onChange(a === value ? undefined : a);
                      if (a === value) reset({ ...data?.feedback_info });
                      else reset(contactsInfoOrg);
                    }}
                    label={
                      <CustomTypography
                        variant="c1_regular"
                        font="comp"
                        styles={{ color: theme.mtsColor.text.secondary.lightMode }}
                      >
                        Использовать контакты указанные в информации о компании
                      </CustomTypography>
                    }
                    color="red"
                  />
                );
              }}
            />
            <Spacer value={isTablet() ? "20px" : "32px"} />
          </>
        ) : null}
        <SControls>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => {}}
            disabled={!isValid}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={handleCloseForm}>
            Отмена
          </MTSButton>
        </SControls>
      </form>
    </SWrap>
  );
};
