import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7117 6.68043C19.9039 6.48822 20 6.2363 20 5.98438C20 5.73245 19.9039 5.48053 19.7117 5.28832C19.5195 5.09611 19.2675 5 19.0156 5C18.7637 5 18.5118 5.09611 18.3196 5.28832L12 11.6235L9.18043 8.78832C8.98822 8.59611 8.7363 8.5 8.48438 8.5C8.23245 8.5 7.98053 8.59611 7.78832 8.78832C7.59611 8.98053 7.5 9.23245 7.5 9.48438C7.5 9.7363 7.59611 9.98822 7.78832 10.1804L11.3039 13.7117C11.4962 13.9039 11.7481 14 12 14C12.2519 14 12.5038 13.9039 12.6961 13.7117L19.7117 6.68043Z"
        fill="white"
      />
      <path
        d="M15.7105 4.8993L15.729 4.88088L15.7364 4.87296C15.9121 4.68775 15.9948 4.45876 16 4.22983C16.0086 3.84974 15.8143 3.4708 15.4705 3.29213C15.2607 3.22296 15.1339 3.19385 14.8802 3.16541C13.9171 3.06672 13.0311 3 12 3C11.3005 3 10.601 3.03058 9.92282 3.08105C7.30684 3.27575 5.99884 3.3731 4.68597 4.68597C3.3731 5.99884 3.27575 7.30684 3.08105 9.92282C3.03058 10.601 3 11.3005 3 12C3 12.6995 3.03058 13.399 3.08105 14.0772C3.27575 16.6932 3.3731 18.0012 4.68597 19.314C5.99884 20.6269 7.30684 20.7243 9.92282 20.9189C10.601 20.9694 11.3005 21 12 21C12.6995 21 13.399 20.9694 14.0772 20.9189C16.6932 20.7243 18.0012 20.6269 19.314 19.314C20.6269 18.0012 20.7243 16.6932 20.9189 14.0772C20.9694 13.399 21 12.6995 21 12C21 11.6077 20.9904 11.5097 20.973 11.1212C20.9456 10.5079 20.5 10 19.8849 10C19.3149 10 18.9472 10.5111 18.9661 11.0225L18.9681 11.0633C18.9886 11.4723 19 11.5901 19 12C19 12.6422 18.9719 13.2916 18.9245 13.9287C18.7198 16.6791 18.606 17.1936 17.8998 17.8998C17.1936 18.606 16.6791 18.7198 13.9287 18.9245C13.2916 18.9719 12.6422 19 12 19C11.3578 19 10.7084 18.9719 10.0713 18.9245C7.3209 18.7198 6.80638 18.606 6.10018 17.8998C5.39398 17.1936 5.28023 16.6791 5.07554 13.9287C5.02812 13.2916 5 12.6422 5 12C5 11.3578 5.02812 10.7084 5.07554 10.0713C5.28023 7.3209 5.39398 6.80638 6.10018 6.10018C6.80638 5.39398 7.3209 5.28023 10.0713 5.07554C10.7084 5.02812 11.3578 5 12 5C13.0274 5 13.9162 5.07178 14.8802 5.17648L14.9133 5.18003C15.2122 5.20932 15.4959 5.10943 15.7105 4.8993Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default CheckSquare;
