import { useForm } from "react-hook-form";
import { useCheckPromocode } from "hooks/hooks";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { SPromoWrap } from "pages/shopCheckout/styles";
import { schemaPromo } from "./validation-schema";

interface IForm {
  saveDiscountVal: (a: number) => void;
  setPromocode: (a: string) => void;
}

export const FormPromocode: React.FC<IForm> = ({ saveDiscountVal, setPromocode }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<any>({
    resolver: yupResolver(schemaPromo),
    mode: "onBlur",
    shouldFocusError: false,
    reValidateMode: "onBlur",
  });

  const [isPromo, setIsPromo] = useState<boolean>(false);
  const [promo, setPromo] = useState<string>("");
  const promoData = useCheckPromocode(promo.toUpperCase(), isPromo);

  const errorMes = (): string | undefined =>
    promoData.isError ? promoData.error?.message : undefined;

  const onSubmit = () => {
    if (getValues("promocode") !== promo) {
      setIsPromo(true);
    } else {
      setIsPromo(false);
    }
    setPromo(getValues("promocode"));
  };

  useEffect(() => {
    getValues("promocode") !== ""
      ? saveDiscountVal(promoData.data?.promo_percent ?? 0)
      : saveDiscountVal(0);
  }, [getValues, promoData, saveDiscountVal]);

  return (
    <SPromoWrap>
      <MTSInput
        {...register("promocode", {
          onBlur: handleSubmit(onSubmit),
        })}
        control={control}
        placeholder="Введите промокод"
        touched={promoData.isSuccess && !errors?.promocode?.message}
        isLoading={promoData.isLoading}
        errorMessage={getValues("promocode") === "" ? undefined : errorMes()}
      />
    </SPromoWrap>
  );
};
