import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SPlace = styled.section<{ iconColor?: string }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 430px;
  margin: 0 auto;

  & svg {
    & path {
      ${(props) =>
        props.iconColor === "green"
          ? `fill: ${props.theme.mtsColor.text.positive.lightMode}`
          : `fill: ${props.theme.mtsColor.text.tertiary.lightMode}`}
    }
  }
`;

export const SInfo = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
