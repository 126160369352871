const PREFIX = "LAND_";

export enum EStorageKeys {
  SHOP_ITEMS = "SHOP_ITEMS",
}

function save(key: EStorageKeys, data: string): void {
  if (!data) {
    return;
  }

  localStorage.setItem(`${PREFIX}${key}`, data);
  const event = new Event("localStorageItemChanged");
  window.dispatchEvent(event);
}

function remove(key: EStorageKeys): void {
  localStorage.removeItem(`${PREFIX}${key}`);
}

function get(key: EStorageKeys): string {
  return localStorage.getItem(`${PREFIX}${key}`) || "";
}

export const StorageAPI = {
  save,
  get,
  remove,
};
