import { deviceElectroCategory } from "components/shop/const";

export const promoQuery = `?categorys=${deviceElectroCategory}&conn_types=NB-IoT,GSM/GPRS`;

export const title = "АСКУЭ для CHT";

export const text = {
  par_1: {
    id: "1",
    title: "Что такое АСКУЭ?",
    blocks: [
      {
        text: [
          "АСКУЭ для СНТ – это компьютерная программа, позволяющая контролировать потребление электричества на каждом счетчике электроэнергии, установленном в садовом товариществе.",
          "Расшифровка аббревиатуры АСКУЭ – автоматизированная система коммерческого учета электроэнергии.",
          "Решение может работать в любом регионе России. Наиболее востребованной установка системы АСКУЭ является в регионах с большой концентрацией садовых товариществ и большим количеством дачных участков на территории одного садового товарищества. Но зачастую, и для небольших товариществ, вне зависимости от территории расположения, внедрение системы интеллектуального учета будет выгодным вложением.",
        ],
      },
    ],
  },
  par_2: {
    id: "2",
    title: "Какие проблемы решает АСКУЭ в СНТ?",
    blocks: [
      {
        text: [
          "Итак, в СНТ успешно внедрили систему коммерческого учета, что это даст:",
          [
            "сокращение числа неплательщиков за электроэнергию, потому что у правления СНТ появляется возможность ограничивать потребление неплательщикам. Ввести ограничение можно удаленно, прямо с компьютера, без физической необходимости «резать провода».",
            "уменьшается финансовая нагрузка на тех, кто постоянно и в срок оплачивает электричество.",
            "собственник каждого участка получает доступ в личный кабинет, можно увидеть информацию о потреблении с точностью до получаса и контролировать качество электроэнергии.",
            "возможность видеть потребление «в обход» счетчика, бороться с хищениями электричества.",
            "показания передаются автоматически в правление садового некоммерческого товарищества, нет необходимости делать «обходы» и передавать показания счетчиков.",
          ],
        ],
      },
    ],
  },
  par_3: {
    id: "3",
    title: "Принцип работы АСКУЭ.",
    blocks: [
      {
        text: [
          "Для работы системы АСКУЭ необходимо установить «интеллектуальные» счетчики. Стоит отметить, что большинство установленных в СНТ приборов учета уже являются «интеллектуальными» и поддерживают возможность подключения к АСКУЭ.",
          "Информация с приборов учета по GSM/GPRS (сеть операторов сотовой связи) или NB-IoT сети может напрямую со счетчиков или через УСПД (устройство сбора и передачи данных) передаваться на сервер. Там же хранятся архивы данных с историей потребления.  Сам сервер находится в центрах обработки данных поставщика услуги, это позволяет правлению товарищества избежать затрат на покупку дорогостоящего оборудования, лицензии на программное обеспечение и затрат на технический персонал, обеспечивающий работоспособность оборудования, а также защиту и хранение данных.",
          "Доступ в личный кабинет пользователя осуществляется через мобильное приложение, которое можно установить на мобильный телефон, планшет или компьютер. Для входа в личный кабинет системы учета ресурсов необходимо только наличие интернета, логин и пароль. В личном кабинете каждый собственник участка сможет увидеть историю потребления на своем электросчетчике, текущие показания, журнал событий, параметры качества электроэнергии. Напомним, что показания потребления передаются автоматически в правление СНТ, отдельно передавать данные никуда не надо.",
        ],
      },
    ],
  },
  par_4: {
    id: "4",
    title: "Выгода от внедрения АСКУЭ в СНТ:",
    blocks: [
      {
        subtitle: "Для собственников участков:",
        text: [
          [
            "система АСКУЭ сама «собирает» информацию и передает в правление, больше не надо подавать показания счетчиков вручную.",
            "помогает бороться с хищениями электричества. Подключение в «обход» счетчика, может негативно повлиять на качество электроэнергии у других потребителей.",
            "возможность своевременно выявлять потери электроэнергии и неплательщиков, а общие платежи, например, за освещение распределять пропорционально потреблению.",
          ],
        ],
      },
      {
        subtitle: "Для правления СНТ:",
        text: [
          [
            "средство борьбы с должниками. Возможность удаленного ограничения мощности.",
            "автоматический сбор показаний и формирование отчета о потреблении. А значит не надо снимать показания, обходя каждый прибор. Программа сама соберет данные, выгрузку данных можно сделать в виде гибко настраиваемого отчета, удобного для выставления счетов на оплату.",
            "возможность выявлять очаги «коммерческих» потерь электроэнергии, незаконные подключения к электросети.",
          ],
        ],
      },
    ],
  },
  par_6: {
    id: "6",
    title: "Какое решение мы предлагаем по внедрению АСКУЭ в СНТ?",
    blocks: [
      {
        text: [
          "МТС предлагает систему АСКУЭ как сервис по подписке. Само программное обеспечение работает на сервере компании МТС, МТС берет на себя затраты по поддержанию работоспособности оборудования и функционированию программы, выделяет хранилища для данных архивов показаний с электросчетчиков, обеспечивает каналы связи с приборами учета. Решение удобное, современное и полностью соответствует требованиям постановления правительства №890.",
          "Любое садовое товарищество может воспользоваться сервисом и получить доступ к системе АСКУЭ и её функционалу.",
          "Для подключения зачастую нет необходимости закупать оборудование или программное обеспечение. Если же СНТ не оборудовано приборами учета или они устарели, МТС поможет подобрать подходящие, поставит, смонтирует и подключит к АСКУЭ.",
        ],
      },
    ],
  },
  par_7: {
    id: "7",
    title: "Цена АСКУЭ для СНТ.",
    blocks: [
      {
        text: [
          "Расчет за использование сервиса осуществляется ежемесячными платежами, исходя из количества устройств, подключенных к сервису система коммерческого учета.",
          "Цена за один прибор учета на платформе составляет 60 руб./месяц, без учета НДС.",
          "Общий размер ежемесячного платежа составит: 60 Х Общее количество приборов учета на платформе.",
          "Таким образом, если садовое некоммерческое товарищество подключает 100 участков к сервису АСКУЭ, то размер ежемесячного платежа составит 6 000 руб. (без НДС)",
          "При использовании МТС в качестве мобильного оператора для передачи данных с электросчетчиков в система АСКУЭ, стоимость трафика отдельно оплачивать не надо.",
          "Предоставляется бесплатный тестовый период на 1 месяц, в течение этого времени есть возможность оценить удобство сервиса, качество опроса счетчиков и целостность передаваемых данных.",
        ],
      },
    ],
    banner: true,
  },
  par_8: {
    id: "8",
    title: "Как подключить систему?",
    blocks: [
      {
        text: [
          "Подключение к АСКУЭ происходит достаточно просто:",
          [
            "Потребуется уточнить точное название моделей установленных приборов учета или УСПД, к которому подключены счетчики",
            "Специалисты МТС проверят поддерживается ли установленное в СНТ оборудование программой АСКУЭ и возможность передачи данных по сети GSM/GPRS или NB-IoT",
            "Если оборудование входит в список поддерживаемого, то дополнительно потребуется уточнить серийные номера устройств и пароли доступа, чтобы программа смогла к ним подключиться и опросить. Уточнить есть ли SIM-карты для установки в приборы",
            "На самом счетчике по инструкции выполнить настройку на сервер опроса компании МТС",
          ],
          "После этого можно начинать собирать информацию с измерительных устройств и предоставлять личные кабинеты абонентов всем членам садового товарищества.",
          "Для получения более подробной информации вы можете оставить заявку на консультацию. С вами свяжется специалист, более подробно объяснит и вышлет инструкцию по настройке",
        ],
      },
    ],
  },
};

export const textEnd = [
  "В заключении следует отметить возможность работы решения «АКСКУЭ в облаке МТС» на сети NB-IoT – это стандарт связи, разработанный специально для «умных» устройств (расходомеры, датчики и др.), позволяющий передавать небольшие объемы данных с определенными интервалами времени.",
  "Передача данных с использованием NB-IoT позволяет собирать данные с конечных устройств, контролировать их работу, в режиме реального времени получать информацию о наступлении аварийных событий.",
  "Сеть NB-IoT отличается наличием стабильного радиосигнала, возможностью проникновения в труднодоступные места, независимость от загруженности голосового/интернет трафика.",
];

export const shopText =
  "В нашем магазине вы можете приобрести оборудование поддерживающее АСКУЭ с возможностью передачи данных по сети GSM/GPRS или NB-IoT.";

export const seo = {
  title: "АСКУЭ для СНТ: что это такое, какая выгода и сколько стоит подключение системы?",
  description:
    "Хотите оптимизировать учет электроэнергии в вашем СНТ? Узнайте, что такое АСКУЭ, ее назначение, принцип работы, стоимость и как подключить систему. В статье рассказываем о преимуществах АСКУЭ и другие нюансы",
};
