import styled from "@emotion/styled";
import { device } from "theme";

export const SGridInfo = styled.div<{ isShort?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  @media ${device.tabletPlus} {
    & > div:not(:last-of-type) {
      border-right: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
    }
  }

  ${(props) =>
    props.isShort
      ? `
		@media ${device.tabletNeg} {
			grid-template-columns: repeat(2, 1fr);
			border-bottom: none;
			& div {
				padding: 49px 32px !important;
				&:not(:last-of-type) {
					border-bottom: 1px solid ${props.theme.mtsColor.grey.greyLight};
				}
				&:nth-child(2n + 1) {
					border-right: 1px solid ${props.theme.mtsColor.grey.greyLight};
				}
			}
		}`
      : `
		@media ${device.tabletPlus} {

			& > div:nth-child(-n + 3) {
			border-bottom: 1px solid ${props.theme.mtsColor.grey.greyLight};
			}

			& > div:nth-child(3n) {
			padding-right: 0;
			border-right: none;
			}
		}

		@media ${device.tabletNeg} {
			grid-template-columns: repeat(2, 1fr);
			row-gap: 0;
			& div {
			padding: 49px 32px !important;
			&:nth-child(2n + 1) {
				border-right: 1px solid ${props.theme.mtsColor.grey.greyLight};
			}
			&:not(:nth-last-child(-n + 2)) {
				border-bottom: 1px solid ${props.theme.mtsColor.grey.greyLight};
			}
			}
		}
	  `}

  @media ${device.miniTabletNeg} {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;
    & div {
      border: none !important;
    }
  }
`;
