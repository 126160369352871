import { Link } from "react-router-dom";
import { CustomTypography } from "components/customTypography/custom-typography";
import { ICart } from "context/context-settings";
import { useBreakpoint } from "hooks/useBreakpoint";

const domain = "https://energytool.mts.ru";

export const makePayload = (someobj: ICart) =>
  Object.entries(someobj).map(([key, val]) => ({
    quantity: val.qty,
    id: parseInt(key),
  })) as any;

export const RenderDisclaim = (string: string) => {
  const { isTablet } = useBreakpoint();
  const [firstPart, secondPart] = string.split("\\n");
  const promoIndex = secondPart.indexOf("https://");
  const promoText = secondPart.slice(0, promoIndex).trim();
  const link = secondPart.slice(promoIndex).trim();

  return (
    <>
      <CustomTypography variant={isTablet() ? "c2_regular" : "p4_regular"} font="comp">
        {firstPart}. {promoText}{" "}
        <Link target="_blank" rel="noreferrer" to={link.replace(domain, "")}>
          {link}
        </Link>
      </CustomTypography>
    </>
  );
};

export const seo = {
  title: "Корзина - EnergyTool",
  description:
    "Быстро и просто оформляйте заказ на EnergyTool. Проверьте товары, выберите количество и завершите покупку в пару кликов.",
};
