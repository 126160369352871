import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { device } from "theme";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MTSInput } from "components/mts-input";

export const SPrice = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  margin-right: auto;

  @media ${device.desktopPlus} {
    margin: 0;
  }
`;

export const SClose = styled.div``;

export const SPriceWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  & svg {
    cursor: pointer;
  }

  & ${MTSInput} {
    width: 40px;
    margin: 0 8px;

    & input {
      padding: 0;
    }
  }

  & > p {
    order: -1;
  }

  @media ${device.desktopPlus} {
    flex: 1;
    justify-content: center;
    margin-right: 28px;

    & ${SPrice} {
      padding-left: 24px;
    }

    & ${MTSInput} {
      width: 64px;

      & input {
        padding: 10px 6px;
      }
    }

    & > p {
      order: 0;
    }
  }
`;

export const SCont = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 28px 12px 24px 12px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};

  & > img {
    display: block;
    min-width: 0;
    flex: 0 0 72px;
    height: 100px;
    margin-right: 8px;
    object-fit: cover;
  }

  & > div {
    &:first-of-type {
      & > div:nth-of-type(3) {
        & > span {
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }
      }
    }
  }

  & ${SClose} {
    position: absolute;
    top: 12px;
    right: 12px;

    cursor: pointer;
  }

  @media ${device.desktopPlus} {
    padding: 16px 24px;
    & > img {
      display: block;
      min-width: 0;
      flex: 0 0 120px;
      height: 80px;
      margin-right: 16px;
      object-fit: contain;
    }

    & > div {
      &:first-of-type {
        min-width: 0;
        display: flex;
        flex-flow: column nowrap;
        flex: 0 1 260px;
        margin-right: 28px;
      }
    }

    & ${SClose} {
      position: relative;
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
`;

export const STotal = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};

  & ${SPrice} {
    margin: 0;
  }

  & > section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  & button {
    max-width: 100%;
    width: 100%;
  }

  @media ${device.desktopPlus} {
    padding: 0;
    border: 0;
    background: transparent;

    & > section {
      height: 80px;
      padding: 0 24px;
      margin-bottom: 32px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }
  }
`;

export const SInfo = styled(CustomTypography)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
