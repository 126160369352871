import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const SContainer = styled.div<{ disabled?: boolean; view: "standard" | "minimal" }>`
  display: inline-block;
  ${(props) =>
    props.view === "standard" &&
    `
    min-width: 200px;
  `};
  width: 100%;
  text-align: center;
  position: relative;

  ${(props) =>
    props.disabled &&
    `
	  pointer-events: none;
	  opacity: 0.6;
	`}
`;

export const SSelect = styled.div<{ size: string; hasError?: boolean | string }>`
  display: flex;
  position: relative;
  border: 2px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};

  ${(props) =>
    props.size === "S"
      ? `min-height: 32px;`
      : props.size === "M"
      ? `min-height: 44px;`
      : props.size === "L"
      ? `min-height: 52px;`
      : props.size === "XL"
      ? `min-height: 72px;`
      : ""}

  ${(props) =>
    props.hasError &&
    `
		border-color: ${props.theme.mtsColor.accent.negative.lightMode};
	`}
`;

export const SInlineTypo = styled(Typography)`
  border-bottom: 1px solid transparent;
`;

export const SInline = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    & ${SInlineTypo} {
      border-color: #1d2023;
    }
  }
`;

export const SPlaceholder = styled.div`
  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SResultBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 5px 4px 16px;
  flex: 1;
  overflow: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  & > div {
    width: auto;
  }

  & > span {
    white-space: nowrap;
    color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
  }

  & div > span {
    white-space: nowrap;
  }
`;

export const SIconBlock = styled.div<{ isOpen?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 28px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  & > svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    ${(props) => props.isOpen && `transform: translateX(50%) translateY(-50%) rotate(180deg);`}
    fill: ${(props) => props.theme.mtsColor.icons.secondary.lightMode};
    background: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: transform 0.3s linear;
  }
`;

export const SList = styled.div`
  position: absolute;
  width: max-content;
  max-height: 200px;
  min-width: 200px;
  overflow: auto;
  padding: 10px 0;
  border-radius: 8px;
  background: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  z-index: 1;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const SOption = styled.div`
  width: 100%;
  padding: 5px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.mtsColor.background.hover.lightMode};
  }
`;
