import { FormRegDemoAskue } from "components/forms/demo-askue/reg-demo-askue";
import { Logo } from "components/logo/logo.component";
import { Spacer } from "components/spacer/spacer.component";
import { SLogo, SWrap } from "./styles";

export const DemoAskuePage = () => {
  return (
    <SWrap>
      <SLogo>
        <Logo />
      </SLogo>
      <Spacer value="32px" />
      <FormRegDemoAskue />
    </SWrap>
  );
};
