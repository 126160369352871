interface ICalcContent {
  name: string;
  title: string;
}

export const calcContent: ICalcContent[] = [
  {
    name: "month",
    title: "В месяц",
  },
  {
    name: "day",
    title: "В день",
  },
  {
    name: "hour",
    title: "В час",
  },
];
