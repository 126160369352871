import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { device } from "theme";

export const SThumb = styled.div`
  height: 72px;
  width: 76px;
  flex: 0 0 76px;
  border-radius: 4px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:focus-visible {
    outline: none !important;
  }
`;

export const SImg = styled.div`
  width: 100%;
  height: 260px;

  @media ${device.tabletPlus} {
    height: 400px;
  }
`;

export const scrollbarWidth = 4;

export const customScrollHorisontal = (theme, height = scrollbarWidth) => css`
  &::-webkit-scrollbar {
    height: ${height}px;
    border-radius: 4px 4px 4px 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.mtsColor.background.stroke.lightMode};
    border-radius: 4px 4px 4px 4px;
  }
`;

export const SSliderWrap = styled.div<{ disableBtns: boolean }>`
  width: 100%;
  overflow: hidden;

  @media ${device.tabletPlus} {
    padding-top: ${(props) => (props.disableBtns ? "0px" : "60px")};
  }

  .alice-carousel__dots {
    margin: 10px 3px 5px !important;
  }

  & .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    height: 40px;
    width: 40px;
    background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    border-radius: 50%;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: rgba(226, 229, 236, 1);
      transition: all 0.3s ease;
    }
  }

  .alice-carousel__prev-btn {
    right: 60px;
  }

  & .alice-carousel__next-btn {
    right: 10px;
  }

  & .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  & .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    width: 100%;
    height: 100%;
  }
`;
