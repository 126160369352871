import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  display: flex;

  @media ${device.tabletNeg} {
    flex-direction: column;
  }
`;

export const SCalc = styled.div`
  flex: 1;
  margin-right: 20px;

  @media ${device.tabletNeg} {
    margin-right: 0;
    padding: 0 20px;
  }
`;

export const SResultWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SCalcInner = styled.div`
  max-width: 508px;

  & > div {
    max-width: 400px;

    @media ${device.tabletNeg} {
      max-width: inherit;
    }
  }

  & p {
    max-width: 415px;
    margin-bottom: 32px;
    text-align: left;

    @media ${device.tabletNeg} {
      max-width: inherit;
    }

    & .MuiTypography-root {
      color: ${(props) => props.theme.mtsColor.grey.greyDark};
    }
  }

  @media ${device.tabletNeg} {
    max-width: inherit;
  }
`;

export const SFieldBlock = styled.div`
  position: relative;
  margin-bottom: 24px;

  input {
    height: 52px;
    font-size: 21px;
    padding: 11px 16px;
  }
`;

export const SMeasureUnit = styled.span`
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  display: flex;
  position: relative;
  align-items: center;
  height: 48px;
  padding: 8px;
  position: absolute;
  bottom: 2px;
  right: 7px;
  font-size: 21px;
  color: ${(props) => props.theme.mtsColor.grey.greyMedium};
`;

export const SResultInner = styled.div`
  max-width: 548px;
  box-shadow: 1px 26px 22px ${(props) => props.theme.mtsColor.grey.greyDirty};
  border-radius: 8px;
  padding: 48px 64px;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  margin: 0 auto;
  width: 100%;

  @media ${device.tabletNeg} {
    padding: 48px 20px;
    max-width: inherit;
    box-shadow: none;
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }
`;

export const SMainTitle = styled.div`
  text-align: left;
  & > span {
    color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};
  }
  margin-bottom: 32px;
`;

export const SResNum = styled.div`
  display: flex;
`;

export const SMainSum = styled.div`
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.mtsColor.red};

  @media ${device.tabletNeg} {
    font-size: 80px;
  }
`;

export const SResСurrency = styled.div`
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SMainRub = styled.div`
  & span {
    color: ${(props) => props.theme.mtsColor.red};
  }
`;

export const SMainExtent = styled.div`
  & > span {
    color: ${(props) => props.theme.mtsColor.red};
    line-height: 1.8;

    @media ${device.mobileNeg} {
      line-height: 1.38;
    }
  }
`;

export const SResOther = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobileNeg} {
    flex-direction: column;
  }
`;

export const SResSum = styled.div`
  font-size: 34px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};
`;

export const SResRub = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};
`;

export const SResExtent = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};
`;

export const SResItem = styled.div`
  &:not(last-child) {
    margin-right: 10px;
  }

  @media ${device.mobileNeg} {
    margin-bottom: 15px;
  }
`;
