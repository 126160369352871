import { IBankInfo, IFeedbackInfo, IInformation } from "interface/common";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { cutNameFilefromUrl } from "components/forms/shop-checkout/utils";
import { getDownloadFileOrg } from "services/shop-checkout";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SCheckoutItem } from "./styles";
import { useBreakpoint } from "hooks/useBreakpoint";

export const seo = {
  title: "ENERGYTOOL – умные инструменты, направленные для решения задач сфер ЖКХ и Энергетики",
  description:
    "Составные части продуктовой витрины EnergyTool можно выделить на следующие компоненты: 1) Оборудование для энергетических компаний, 2) АСКУЭ в облаке МТС, 3) Канал передачи данных от прибора учёта к АСКУЭ, 4) Поиск коммерческих потерь, 5) Поиск майнинговых ферм.",
};

interface IList {
  name: string;
  title: string;
  isDocument?: boolean;
}

const informationListFl: IList[] = [
  {
    name: "tenant_name",
    title: "Наименование организации",
  },
  {
    name: "inn",
    title: "ИНН",
  },
  {
    name: "ogrnip",
    title: "ОГРНИП",
  },
  {
    name: "ur_adress",
    title: "Юридический адрес",
  },
  {
    name: "owner_name",
    title: "Директор",
  },
  {
    name: "phone",
    title: "Телефон/факс",
  },
  {
    name: "email",
    title: "Электронная почта",
  },
];

const informationListUl: IList[] = [
  {
    name: "tenant_name",
    title: "Наименование организации",
  },
  {
    name: "inn",
    title: "ИНН",
  },
  {
    name: "kpp",
    title: "КПП",
  },
  {
    name: "ur_adress",
    title: "Юридический адрес",
  },
  {
    name: "post_adress",
    title: "Почтовый адрес",
  },
  {
    name: "owner_name",
    title: "Директор",
  },
  {
    name: "document_url",
    title: "Подтверждающий документ",
    isDocument: true,
  },
  {
    name: "phone",
    title: "Телефон/факс",
  },
  {
    name: "email",
    title: "Электронная почта",
  },
  {
    name: "phone_add",
    title: "Дополнительный телефонный номер",
  },
];

const bankInfoList: IList[] = [
  {
    name: "rassch_schet",
    title: "Расчетный счет",
  },
  {
    name: "bank_name",
    title: "Наименование банка",
  },
  {
    name: "corr_schet",
    title: "Корреспондентский счет ",
  },
  {
    name: "bik",
    title: "БИК",
  },
];

const feedbackInfo: IList[] = [
  {
    name: "name",
    title: "Ваше имя",
  },
  {
    name: "user_phone",
    title: "Телефон для обратной связи",
  },
  {
    name: "user_email",
    title: "Электронная почта",
  },
];

const userInfoLists = {
  user_info_fl: informationListFl,
  user_info_ul: informationListUl,
  bank_info: bankInfoList,
  feedback_info: feedbackInfo,
};

type dataType = IInformation | IFeedbackInfo | IBankInfo | null | undefined;

export const isInformationBlock = (data: dataType): data is IInformation => {
  return typeof (data as IInformation).document_url !== "undefined";
};

export const RenderItemsChekoutCards = (
  data: dataType,
  typeCard: "user_info" | "bank_info" | "feedback_info",
  isUrik?: boolean,
) => {
  const { isTablet } = useBreakpoint();
  const nameList = typeCard === "user_info" ? (isUrik ? "user_info_ul" : "user_info_fl") : typeCard;

  if (data) {
    return userInfoLists[nameList].map((item) => (
      <SCheckoutItem key={item.name} isDocument={item.isDocument}>
        <CustomTypography variant={isTablet() ? "c2_regular" : "c1_regular"} font="comp">
          {item.title}
        </CustomTypography>

        {isInformationBlock(data) && item.isDocument ? (
          <CustomTypography
            variant={isTablet() ? "c2_regular" : "c1_regular"}
            font="comp"
            onClick={() => getDownloadFileOrg(cutNameFilefromUrl(data[item.name]) ?? "")}
          >
            {item.isDocument ? cutNameFilefromUrl(data[item.name]) : data[item.name]}
          </CustomTypography>
        ) : (
          <>
            <CustomTypography variant={isTablet() ? "c2_medium" : "c1_medium"} font="comp">
              {data[item.name] ? data[item.name] : EMPTY_CELL_VALUE}
            </CustomTypography>
          </>
        )}
      </SCheckoutItem>
    ));
  }
};
