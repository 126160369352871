import styled from "@emotion/styled";
import { device } from "theme";

export const SCard = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border-radius: 8px;
  padding: 32px;
  overflow: hidden;
  z-index: 1;

  @media ${device.tabletNeg} {
    padding: 24px;
  }
`;

export const STop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SImgWrap = styled.div`
  max-width: 109px;
  margin-right: 10px;

  @media ${device.mobileNeg} {
    display: none;
  }
`;

export const SImg = styled.img`
  display: inline-block;
  height: auto;
  width: 100%;
`;

export const STitle = styled.div`
  & .MuiTypography-root {
    display: inline-block;
    text-align: left;
  }
`;

export const SBottom = styled.div`
  & .MuiTypography-root {
    display: inline-block;
    text-align: left;
    width: 100%;
    color: rgba(29, 32, 35, 0.6);
  }
  & a {
    text-decoration: none;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;

export const SNum = styled.span`
  position: absolute;
  bottom: -35px;
  right: -20px;
  z-index: -1;

  & .MuiTypography-root {
    font-size: 150px;
    line-height: 150px;
    color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  }
`;
