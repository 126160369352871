enum SpinnerColor {
  WHITE = "white",
  BLACK = "black",
  ORANGE = "orange",
  RED = "red",
}
const getSpinnerColor = (spinnerColor: string) => {
  switch (spinnerColor) {
    case SpinnerColor.BLACK:
      return "#000000";
    case SpinnerColor.ORANGE:
      return "#F95721";
    case SpinnerColor.WHITE:
      return "#FFFFFF";
    case SpinnerColor.RED:
      return "#FF0032";
    default:
      return "#FF0032";
  }
};

export default getSpinnerColor;
