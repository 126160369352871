import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import theme, { device } from "theme";
import styled from "@emotion/styled";

const useStyles = makeStyles((STheme: Theme) => ({
  accordion: {
    "&.MuiAccordion-root": {
      margin: 0,
      borderBottom: `1px solid ${theme.mtsColor.grey.greyLight}`,
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      boxShadow: "none",
    },
  },
  accordionSummary: {
    "&.MuiAccordionSummary-root": {
      minHeight: "auto",
      padding: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: "24px 10px 24px 0",
      textAlign: "left",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: 0,
      textAlign: "left",
      marginBottom: "24px",
    },
    "& > ul": {
      paddingTop: "15px",
    },
    "& > ul li": {
      position: "relative",
      paddingLeft: "20px",
      marginBottom: "5px",

      "&:before": {
        content: "''",
        position: "absolute",
        left: "2px",
        top: "9px",
        width: "6px",
        height: "6px",
        borderRadius: "4px",
        background: `${theme.mtsColor.red}`,
      },
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    "& > svg": {
      fill: STheme.mtsColor.text.secondary.darkMode,
      marginRight: "10px",
    },
    "& .MuiTypography-root": {
      color: STheme.mtsColor.text.primaryLink.lightMode,
    },
  },
  pdfSize: {
    paddingLeft: "5px",
    textDecoration: "none",
    "&.MuiTypography-root": {
      color: STheme.mtsColor.text.secondary.darkMode,
    },
  },
}));

export default useStyles;

export const SCustomAccord = styled.div`
  & .MuiAccordionSummary-root {
    & .MuiTypography-root {
      @media ${device.miniTabletNeg} {
        font-size: 18px;
      }
    }
  }
`;
