import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";

import { CustomTypography } from "components/customTypography/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { useShopCatalog } from "hooks/hooks";
import { IShopProduct } from "interface/common";
import { ItemsSlider } from "components/shop/shopSlider/itemsSlider";
import { BannerAskue60, filteredTitles, renderPar } from "../utils";
import { ROUTE_SHOP } from "stream-constants/route-constants";
import { promoQuery, seo, shopText, text, title } from "./const";
import { deviceElectroCategory } from "components/shop/const";
import { SArticle, SContainer, SMain, SBlock, SAside, SLink } from "../styles";

import mainImg from "../images/main_company.jpg";

export const AskueCompany = () => {
  const contentRefs = useRef<any[]>([]);
  const [activeMenuItem, setActiveMenuItem] = useState<number>(0);
  const [prodBanner, setProdBanner] = useState<IShopProduct | undefined>(undefined);
  const navigate = useNavigate();

  const { data, isLoading } = useShopCatalog();

  useEffect(() => {
    if (data) {
      const product = data.find((prod) => prod.id === 16); // id 16 - Энергосфера в облаке МТС по абонентской модели

      setProdBanner(product);
    }
  }, [data]);

  const handleMenuItemClick = (e, index) => {
    e.preventDefault();
    const element = contentRefs.current[index];
    const top = element.offsetTop + 50;
    window.scrollTo({
      top,
      behavior: "smooth",
    });
    setActiveMenuItem(index);
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    let activeIndex = activeMenuItem;
    contentRefs.current.forEach((ref, index) => {
      if (ref) {
        const top = ref.offsetTop - 200;
        const bottom = top + ref.offsetHeight;

        if (scrollTop >= top && scrollTop < bottom) {
          activeIndex = index;
        }
      }
    });

    setActiveMenuItem(activeIndex);
  }, [activeMenuItem]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <SContainer>
          <SAside>
            <section>
              <CustomTypography variant="h4_medium" font="comp">
                Содержание
              </CustomTypography>
              <ul>
                {filteredTitles(text).map((a, index) => (
                  <li
                    key={`li-${index}`}
                    className={activeMenuItem === index ? "active" : ""}
                    onClick={(e) => handleMenuItemClick(e, index)}
                  >
                    <CustomTypography variant="p4_regular" font="comp">
                      {a.title}
                    </CustomTypography>
                  </li>
                ))}
              </ul>
            </section>
          </SAside>
          <SArticle>
            <h1>
              <CustomTypography variant="h1_medium" font="wide">
                {title}
              </CustomTypography>
            </h1>
            <Spacer value="24px" />
            <img src={mainImg} alt={title} />
            <Spacer value="48px" />
            <div>
              {Object.keys(text).map((a, i) => (
                <SBlock key={text[a].id} ref={(ref) => (contentRefs.current[i] = ref)}>
                  {renderPar(text[a])}
                  {text[a]?.banner && prodBanner && !isEmpty(prodBanner) ? (
                    <>
                      <Spacer value="48px" />
                      <BannerAskue60 {...prodBanner} />
                    </>
                  ) : null}
                </SBlock>
              ))}
              {data ? (
                <>
                  <ItemsSlider
                    items={data?.filter(
                      (a) => a.category === deviceElectroCategory && !a.hide_flag,
                    )}
                    isLoading={isLoading}
                  />
                  <Spacer value="12px" />
                  <CustomTypography variant="p4_regular" font="comp">
                    {shopText}{" "}
                    <SLink onClick={() => navigate(`/${ROUTE_SHOP}${promoQuery}`)}>
                      Перейти в магазин
                    </SLink>
                  </CustomTypography>
                </>
              ) : null}
              <Spacer value="24px" />
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
