import { useLocation } from "react-router-dom";

import { NotificationBlock } from "components/notification-block/notification-block";
import { Spacer } from "components/spacer/spacer.component";
import { ILocationState } from "interface/common";
import { CustomTypography } from "components/customTypography/custom-typography";
import { useBreakpoint } from "hooks/useBreakpoint";

export const ShopCheckoutSuccess = () => {
  const { isTablet } = useBreakpoint();
  const locationState = useLocation().state as ILocationState;
  return (
    <>
      <Spacer value="185px" />
      <CustomTypography variant={isTablet() ? "p2_medium" : "h2_medium"}>
        Оформлен заказ №{parseInt(locationState?.shopOrderId! ?? "")}
      </CustomTypography>
      <Spacer value={isTablet() ? "16px" : "40px"} />
      <NotificationBlock type="success" />
      <Spacer value="120px" />
    </>
  );
};
