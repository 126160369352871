enum SpinnerSize {
  S_16 = "S_16",
  M_24 = "M_24",
  L_40 = "L_40",
}

const getSpinnerSize = (size: string): number => {
  switch (size) {
    case SpinnerSize.S_16:
      return 16;
    case SpinnerSize.M_24:
      return 24;
    case SpinnerSize.L_40:
      return 40;
    default:
      return 24;
  }
};

export default getSpinnerSize;
