import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { MTSButton } from "components/mts-button/mts-button";
import { device } from "theme";

export const SCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 320px;
  padding: 12px 6px;
  border-radius: 8px;
  border: 1px solid ${(props) => rgba(props.theme.mtsColor.background.stroke.lightMode, 0.5)};
  text-align: left;

  & > summary {
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-flow: column nowrap;

    & > img {
      display: block;
      object-fit: contain;
      height: 100px;
      max-width: 100%;
      margin-bottom: 4px;
    }
  }

  @media ${device.tabletPlus} {
    min-height: 480px;
    padding: 24px;

    & > summary {
      & > img {
        height: 160px;
        margin-bottom: 16px;
      }
    }
  }
`;

export const STop = styled.div`
  @media ${device.tabletPlus} {
    min-height: 80px;
  }
`;

export const SCategory = styled.div`
  margin-bottom: 8px;
`;

export const SPrice = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media ${device.tabletPlus} {
    margin-bottom: 24px;
  }
`;

export const SDescription = styled.div`
  white-space: pre-wrap;
  @media ${device.tabletNeg} {
    line-height: 0.7;
  }
`;

export const SBtns = styled.div`
  margin-top: auto;

  & ${MTSButton} {
    max-width: 100%;
    width: 100%;
    margin-bottom: 4px;

    & > button {
      max-width: 100%;
      width: 100%;
    }
  }

  @media ${device.tabletPlus} {
    & ${MTSButton} {
      margin-bottom: 16px;
    }
  }
`;

export const SPromoTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  & > div {
    display: inline-block;
    margin-right: 2px;

    & > .MuiTypography-root {
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      color: ${(props) => props.theme.mtsColor.text.headline.darkMode};
      text-transform: uppercase;
      padding: 1px 8px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.mtsColor.text.positive.lightMode};
    }
  }
`;
