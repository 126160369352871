export const SERVER_RESPONSE_ABORT_TIME_OUT = 3000;
export const ERROR_MESSAGE_SPLITTER = "\u21D2";

export enum IHttpMethods {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
