import { IRoles } from "interface/common";

export enum LIST_ROLES {
  ADMIN = "admin",
  OWNER = "owner",
  ANALYTIC = "analytic",
  ANALYST_SYSTEM = "analyst_system",
  ENGINEER = "engineer",
  DEMO_TEST = "demo_test",
  CUSTOMER = "customer",
}

// export enum LIST_ROLES_ID {
//   ADMIN = 1,
//   OWNER = 2,
//   ANALYTIC = 3,
//   ENGINEER = 4,
//   ANALYST_SYSTEM = 5,
//   DEMO_TEST = 6,
// }

export enum LIST_ROLES_NAME {
  ADMIN = "Администратор системы",
  OWNER = "Владелец тенанта",
  ANALYTIC = "Аналитик тенанта",
  ENGINEER = "Инженер тенанта",
  ANALYST_SYSTEM = "Аналитик системы",
  DEMO_TEST = "Демо-тест системы",
  CUSTOMER = "Покупатель",
}

// export const ROLES_ID = (role: string) => {
//   switch (role) {
//     case LIST_ROLES.ADMIN:
//     case LIST_ROLES_NAME.ADMIN:
//       return LIST_ROLES_ID.ADMIN;
//     case LIST_ROLES.OWNER:
//     case LIST_ROLES_NAME.OWNER:
//       return LIST_ROLES_ID.OWNER;
//     case LIST_ROLES.ANALYTIC:
//     case LIST_ROLES_NAME.ANALYTIC:
//       return LIST_ROLES_ID.ANALYTIC;
//     case LIST_ROLES.ENGINEER:
//     case LIST_ROLES_NAME.ENGINEER:
//       return LIST_ROLES_ID.ENGINEER;
//     case LIST_ROLES.ANALYST_SYSTEM:
//     case LIST_ROLES_NAME.ANALYST_SYSTEM:
//       return LIST_ROLES_ID.ANALYST_SYSTEM;
//     case LIST_ROLES.DEMO_TEST:
//     case LIST_ROLES_NAME.DEMO_TEST:
//       return LIST_ROLES_ID.DEMO_TEST;
//   }
// };

export const ROLES_BY_NAME = (role: string) => {
  switch (role) {
    case LIST_ROLES_NAME.ADMIN:
      return LIST_ROLES.ADMIN;
    case LIST_ROLES_NAME.OWNER:
      return LIST_ROLES.OWNER;
    case LIST_ROLES_NAME.ENGINEER:
      return LIST_ROLES.ENGINEER;
    case LIST_ROLES_NAME.ANALYTIC:
      return LIST_ROLES.ANALYTIC;
    case LIST_ROLES_NAME.ANALYST_SYSTEM:
      return LIST_ROLES.ANALYST_SYSTEM;
    case LIST_ROLES_NAME.DEMO_TEST:
      return LIST_ROLES.DEMO_TEST;
  }
};

export const getArrayRoles = (array: IRoles[] | undefined, callback: Function) => {
  const rolesArray = array?.map((item) => item.name) ?? [];
  callback(rolesArray);
};
