import { AccordionDetails, Accordion, AccordionSummary } from "@mui/material";
import styled from "@emotion/styled";
import { customScrollVertical } from "components/table/utils";

const magicOffset = 48;

export const SSummary = styled(AccordionSummary)`
  position: relative;

  &.MuiAccordionSummary-root {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    padding: 0px 12px;
    border-radius: 8px;
    border: 2px solid #dde1e7;
    &.Mui-expanded {
      min-height: 48px;
      background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
      border: 2px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }
    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
  }
  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 12px 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const SDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 254px;
  height: auto;
  overflow-y: auto;

  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 0,
    })};

  & .MuiFormControlLabel-root {
    width: 100%;
  }
`;

export const SAccord = styled(Accordion)`
  &.MuiAccordion-root {
    border: none;
    box-shadow: none;
  }

  & .MuiCollapse-root {
    position: relative;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
  }
`;

export const SAmount = styled.span`
  position: absolute;
  right: ${magicOffset}px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 17px;
  color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
`;
