export const seo = {
  title: "Оферта на поставку оборудования – EnergyTool",
  description:
    "Ознакомьтесь с офертой на поставку оборудования EnergyTool на нашем сайте. Договор не требует двустороннего подписания и действителен в электронном виде.",
};

export const pageTitle = "Оферта на поставку оборудования";
export const pageContent = [
  {
    title: "",
    text: [
      "<b>ПАО «МТС»</b> (далее – <b>МТС</b>) предлагает Покупателям заключить настоящий Договор на поставку оборудования (далее – Договор) на приведённых ниже условиях.",
      "Покупатель подтверждает, что условия Договора принимаются им без каких-либо возражений, Покупатель понял и принял значение используемых в Договоре терминов, слов и выражений согласно их нормативному правовому определению и/или толкованию, указанному в Договоре.",
      "Договор не требует двустороннего подписания и действителен в электронном виде.",
    ],
  },
  {
    title: "1. Термины и определения",
    text: [
      "1.1. <b>Акцепт Договора</b> – полное и безоговорочное принятие Покупателем условий настоящего Договора путем совершения действий, указанных в п. 3.2 Договора.",
      "1.2. <b>МТС</b> – Публичное акционерное общество «Мобильные ТелеСистемы» (место нахождения: Российская Федерация, 109147, г. Москва, ул. Марксистская, д. 4, ИНН/КПП 7740000076/770901001, ОГРН 1027700149124).",
      "1.3. <b>Оборудование</b> – оборудование, поставляемое Покупателю, модель и комплектация которого определяется Сторонами в Спецификации.",
      "1.4. <b>Покупатель</b> – юридическое лицо (или индивидуальный предприниматель), совершившее Акцепт Договора.",
      "1.5. <b>Спецификация</b> – документ, подписываемый между МТС и Покупателем, подтверждающий Акцепт Договора и определяющий перечень Оборудования, цену Оборудования и адрес поставки.",
      "1.6. <b>Представитель</b> – лицо, уполномоченное Покупателем на Акцепт Договора, приемку Оборудования и другие необходимые действия и действующее от имени и в интересах Покупателя.",
      "1.7. <b>Сайт</b> – страница в сети Интернет, размещенная по адресу: <a href='https://energytool.mts.ru/' target='_blank' rel='noreferrer'> https://energytool.mts.ru/</a>.",
      "1.8. <b>Сторона</b> – МТС или Покупатель.",
      "1.9. <b>Стороны</b> – совместно МТС и Покупатель.",
      "Иные термины трактуются в соответствии с действующим законодательством Российской Федерации и практикой делового оборота в части, не противоречащей настоящему Договору.",
    ],
  },
  {
    title: "2. Предмет Договора",
    text: [
      "2.1. В соответствии с условиями настоящего Договора и подписанной Сторонами Спецификацией МТС обязуется передавать Оборудование в собственность Покупателя, а Покупатель обязуется оплачивать и принимать Оборудование в порядке и сроки, указанные в Договоре.",
      "2.2. МТС гарантирует, что Оборудование принадлежит ему на праве собственности, не является предметом залога, под арестом не состоит, свободно от прав третьих лиц.",
    ],
  },
  {
    title: "3. Акцепт Договора",
    text: [
      "3.1. Для присоединения к Договору Покупатель обязан в порядке, установленном Договором, подтвердить, что прочитал, понял, согласен соблюдать настоящий Договор, и присоединиться к нему в целом путём Акцепта Договора.",
      "3.2. Для Акцепта Договора Покупателю необходимо подписать с МТС Спецификацию.",
      "3.3. Договор считается заключенным, а все условия Договора без каких-либо исключений являются обязательными для Сторон с момента подписания Сторонами Спецификации.",
      "3.4. Обязательства Сторон в рамках поставки Оборудования возникают с момента подписания Сторонами Спецификации, если иной срок не согласован Сторонами в Спецификации.",
    ],
  },
  {
    title: "4. Цена и порядок расчетов",
    text: [
      "4.1. Цена Оборудования определяется в Спецификации в зависимости от вида Оборудования и его количества.",
      "4.2. Покупатель обязан оплатить стоимость Оборудования на условиях 100% предоплаты путем перевода денежных средств с расчетного счета Покупателя, на расчетный счет МТС по реквизитам, указанным в счете, в течение 3 (трех) рабочих дней с момента получения счета, если иной порядок оплаты не согласован Сторонами в Спецификации.",
      "4.3. Оплата по настоящему Договору осуществляется исключительно с расчетного счета Покупателя. В случае отсутствия расчетного счета у индивидуальных предпринимателей допускается оплата денежным переводом, при условии совпадения фамилии, имени, отчества, ИНН отправителя денежного перевода с фамилией именем отчеством, ИНН Покупателя-индивидуального предпринимателя.",
      "4.4. Обязательство Покупателя по оплате считается исполненным в момент зачисления денежных средств на корреспондентский счет банка МТС.",
    ],
  },
  {
    title: "5. Условия поставки и приемки Оборудования",
    text: [
      "5.1. МТС осуществляет поставку Оборудования в течение 25 (двадцати пяти) рабочих дней с момента поступления оплаты за Оборудование МТС по соответствующей Спецификации, если иной порядок оплаты не согласован Сторонами в Спецификации. При этом Покупатель дает согласие на осуществление досрочной поставки Оборудования. В некоторых случаях при поставке Оборудования в отдаленные или труднодоступные местности либо при возникновении не зависящих от МТС обстоятельств срок поставки может быть увеличен, о чем МТС уведомляет Покупателя по электронной почте с указанием срока поставки.",
      "5.2. Оборудование передается Покупателю путем доставки до адреса, указанного в Спецификации, силами МТС либо путем сдачи Оборудования транспортной компании для доставки.",
      "5.3. При приемке Оборудования Представитель должен иметь надлежащим образом оформленную доверенность, которая передается МТС или транспортной компании. Предоставление доступа представителей МТС или транспортной компании по указанному Покупателем адресу для доставки Оборудования находится в зоне ответственности Покупателя.",
      "5.4. Одновременно с Оборудованием МТС или транспортная компания передает Покупателю сопроводительные документы: счет-фактуру и товарную накладную по форме ТОРГ-12 (либо универсальный передаточный документ (далее – УПД)).",
      "5.5. При получении Оборудования Покупатель обязан проверить соответствие Оборудования товарной накладной (либо УПД), принять оборудование по количеству, комплектации и качеству (на предмет явных недостатков), подписать товарную накладную (либо УПД) и вернуть один экземпляр МТС или транспортной компании.",
      "5.6. В случаях обнаружения Покупателем при приемке Оборудования недостачи, несоответствия качества или комплектности Оборудования, Покупатель составляет рекламационный акт, в котором указывает соответствующие замечания. Акт составляется в трех экземплярах и подписывается Представителем Покупателя и представителями МТС или транспортной компании. В случае отказа одной из Сторон от подписания и составления акта, в нем делается советующая отметка. После приемки Оборудования претензии Покупателя в отношении Оборудования не принимаются, за исключением случаев, предусмотренных п. 5.7 настоящего Договора.",
      "5.7. Приемка Оборудования по качеству в части скрытых недостатков производится Покупателем в течение 7 (семи) календарных дней с даты приемки. При обнаружении скрытых недостатков в Оборудовании, Покупатель обязан в тот же срок письменно уведомить МТС о выявленных недостатках. В случае неуведомления МТС в указанный срок Оборудование считается принятым Покупателем без возражений и в последующем Покупатель не вправе предъявлять требования и претензии, связанные с качеством поставленного Оборудования.",
      "5.8. В случае обнаружения Покупателем недостатков и уведомления об этом МТС в сроки, указанные в п. 5.6-5.7 МТС обязуется за свой счет устранить недостатки/заменить/допоставить Оборудование в срок, дополнительно согласованный Сторонами по электронной почте. Замена Оборудования осуществляется при наличии аналогичного Оборудования у МТС. При отсутствии аналогичного Оборудования Сторонами по договоренности решается вопрос о возможности его замены другим Оборудованием, о возврате денежных средств за уже поставленное Оборудование ненадлежащего качества либо об осуществлении поставки требуемого Оборудования сразу же после его поступления в МТС. В последнем случае срок поставки увеличивается на срок поступления Оборудования МТС.",
      "5.9. Право собственности на Оборудование, риск случайной гибели, порчи и потери Оборудования переходит от МТС к Покупателю, а МТС считается исполнившим обязанность передать Оборудование Покупателю с момента передачи Оборудования МТС Представителю или сдачи Оборудования транспортной компании для доставки Покупателю в случае, если поставка Оборудования осуществляется с привлечением транспортной компании.",
      "5.10. Покупатель несет ответственность за предоставление точного адреса доставки, дополнительной информации, необходимой для осуществления доставки, обеспечения условий для приемки Оборудования в месте доставки. Необеспечение Покупателем указанных условий и связанная с этим невозможность поставки, влечет последствия, предусмотренные п. 7.2 настоящего Договора.",
      "5.11. Право выбора вида транспорта и определения других условий доставки принадлежит МТС. Расходы по доставке Оборудования Покупателю несет МТС.",
    ],
  },
  {
    title: "6. Порядок обработки персональных данных",
    text: [
      "6.1. Принимая условия Договора, Покупатель, являющийся индивидуальным предпринимателем, и/или Представитель (далее - Субъект ПДн), действуя свободно, своей волей и в своём интересе, даёт конкретное, информированное и сознательное согласие на обработку своих персональных данных МТС, а также подтверждает факт ознакомления с Политикой «Обработка персональных данных в ПАО «МТС» (<a href='https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts/politika-obrabotka-personalnih-dannih-v-pao-mts' target='_blank' rel='noreferrer'>https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts/politika-obrabotka-personalnih-dannih-v-pao-mts</a>).",
      "6.2. Субъект ПДн выражает своё согласие на обработку следующих категорий персональных данных: фамилия, имя и отчество; дата рождения; пол; владелец номера телефона (для корпоративных номеров МТС); паспортные данные (серия и номер паспорта, когда и кем выдан, код подразделения); адрес регистрации; адрес электронной почты; связанные аккаунты; номера телефонов; ИНН.",
      "6.3. Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, обезличивание, удаление, уничтожение персональных данных, в том числе в информационных системах МТС, и совершение иных действий, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».",
      "6.4. Целями обработки персональных данных являются: заключение и исполнение настоящего Договора.",
      "6.5. Полученное согласие действует со дня Акцепта Договора и до достижения целей обработки персональных данных и досрочно может быть отозвано Субъектом ПДн путем направления МТС письменного заявления по адресу: 109147, г. Москва, ул. Марксистская, д. 4.",
    ],
  },
  {
    title: "7. Ответственность Сторон",
    text: [
      "7.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по Договору в соответствии с действующим законодательством РФ.",
      "7.2. В случае необоснованного отказа Покупателя от принятия Оборудования МТС вправе удержать оплаченную Покупателем стоимость Оборудования.",
      "7.3. В случае необоснованного отказа МТС от поставки Оборудования Покупатель вправе отказаться от исполнения Договора и потребовать возврата уплаченной по счету денежной суммы.",
    ],
  },
  {
    title: "8. Обмен электронными сообщениями",
    text: [
      "8.1. Электронные сообщения, отправленные посредством электронной почты, являются электронными документами, равнозначными документам на бумажном носителе, подписанным собственноручной подписью Покупателя (при отправке электронного сообщения Покупателем/Представителем и/или электронного письма с адреса электронной почты, указанного в Заявке) или уполномоченного лица МТС (при отправке личного сообщения от имени МТС и/или электронного письма с корпоративного адреса электронной почты МТС).",
      "8.2. Стороны признают юридическую и доказательную силу электронных сообщений, указанных в п. 8.1 Договора, в том числе в целях доказывания в арбитражном, гражданском, административном и уголовном процессах, в том числе для подтверждения соблюдения обязательного претензионного порядка разрешения споров.",
    ],
  },
  {
    title: "9. Действие, изменение и прекращение Договора",
    text: [
      "9.1. Договор вступает в силу с даты Акцепта Договора Покупателем и действует до полного исполнения обязательств Сторонами.",
      "9.2. МТС вправе в одностороннем порядке вносить изменения в настоящий Договор и Прайс-лист путем уведомления Покупателя посредством размещения новых редакций Договора и Прайс-листа на Сайте не менее чем за 5 (пять) календарных дней до даты внесения изменений.",
    ],
  },
  {
    title: "10. Антикоррупционная оговорка",
    text: [
      "10.1. Покупатель обязуется в рамках исполнения настоящего Договора соблюдать требования применимого антикоррупционного законодательства и не предпринимать никаких действий, которые могут нарушить нормы антикоррупционного законодательства или стать причиной такого нарушения Покупателем, в том числе не требовать, не получать, не предлагать, не санкционировать, не обещать и не совершать незаконные платежи напрямую, через третьих лиц или в качестве посредника, включая (но не ограничиваясь) взятки в денежной или любой иной форме, каким-либо физическим или юридическим лицам, включая (но не ограничиваясь) коммерческим организациям, органам власти и самоуправления, государственным служащим, частным компаниям и их представителям.",
      "10.2. В случае нарушения Покупателем изложенных выше антикоррупционных обязательств, МТС вправе в одностороннем порядке приостановить исполнение своих обязательств по Договору до устранения причин такого нарушения или отказаться от исполнения Договора, направив об этом письменное уведомление.",
    ],
  },
  {
    title: "11. Заключительные положения",
    text: [
      "11.1. Настоящий Договор и отношения между МТС и Покупателем регулируются и толкуются в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Договором, подлежат разрешению в соответствии с законодательством Российской Федерации.",
      "11.2. Если по тем или иным причинам какие-либо из условий Договора являются недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных условий Договора.",
      "11.3. Стороны согласились, что все споры, возникающие из отношений Сторон, регулируемых настоящим Договором, должны разрешаться в компетентном суде в соответствии с законодательством Российской Федерации.",
      "11.4. В течение срока действия Договора, а также по истечении срока его действия Стороны обязуются не разглашать и не использовать в своих интересах, равно как и в интересах любых третьих лиц, любую конфиденциальную информацию, в том числе деловую, коммерческую, техническую и иную информацию, которая не может быть известна Сторонам из общедоступных источников, переданную одной Стороной другой Стороне и которая стала известной Сторонам в связи с заключением и исполнением Договора.",
    ],
  },
];
