import { IHttpMethods } from "stream-constants/server-constants";
import { SERVER_RESPONSE_ABORT_TIME_OUT } from "stream-constants/server-constants";

interface IFetchData {
  url: string;
  body?: any;
  method: IHttpMethods;
  name: string;
  emptyResponse: boolean;
  blobResponse?: boolean;
  contentType?: string;
  responseAbordTimeOut?: boolean;
}

export const fetchData = async ({
  url,
  body,
  method,
  name,
  emptyResponse,
  blobResponse,
  contentType,
  responseAbordTimeOut = true,
}: IFetchData) => {
  let result;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    if (responseAbordTimeOut) controller.abort();
  }, SERVER_RESPONSE_ABORT_TIME_OUT);

  const headers: any = {
    "Content-Type": contentType ? contentType : "application/json",
  };
  if (contentType === "none") {
    delete headers["Content-Type"];
  }

  const obj: RequestInit = {
    signal: controller.signal,
    method,
    mode: "cors",
    cache: "no-store",
    credentials: "include", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
  };

  if (body) {
    obj.body = body;
  }

  const response = await fetch(url, obj);
  clearTimeout(timeoutId);
  const { status, ok } = response;
  if (!ok || status >= 400) {
    let responseError;
    try {
      responseError = await response.json();
    } catch {
      responseError = `ok = ${ok}, status = ${status}`;
    } finally {
      let errMessage = `Ошибка запроса ${name}: status = "${status}"`;

      if (responseError?.description) {
        errMessage = `${responseError?.description}`;
      }
      result = Promise.reject(new Error(errMessage));
    }
  } else {
    if (emptyResponse) {
      result = Promise.resolve(true);
    } else {
      if (blobResponse) result = await response.blob();
      else result = await response.json();
    }
  }

  return result;
};
