import { Helmet } from "react-helmet";

import { SectionTitle } from "components/section-title/section-title";
import { CustomTypography } from "components/customTypography/custom-typography";

import { SArticle, SContainer, SMain, SParag } from "./styles";
import { pageContent, pageTitle, seo } from "./const";

export const OfferEquipment = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <SectionTitle title={pageTitle} />
            <div>
              <SParag>
                {pageContent.map((item, index) => (
                  <>
                    {item.title ? (
                      <CustomTypography key={`title_${index}`} variant="p3_bold">
                        {item.title}
                      </CustomTypography>
                    ) : null}
                    {item.text.map((text, i) => (
                      <CustomTypography variant="p3_regular" key={`text_${i}`}>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                      </CustomTypography>
                    ))}
                  </>
                ))}
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
