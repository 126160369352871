import styled from "@emotion/styled";
import { device } from "theme";

export const SSection = styled.section`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
  position: relative;

  & > div {
    max-width: 1304px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }

  @media ${device.miniTabletNeg} {
    padding: 64px 0;
    & p .MuiTypography-root {
      font-size: 16px;
    }
  }
`;

export const SBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & ul {
    margin-bottom: 32px;
  }
`;

export const SLeft = styled.div`
  width: 40%;

  & img {
    width: 100%;
  }

  @media ${device.miniTabletNeg} {
    display: none;
  }
`;

export const SRight = styled.div`
  width: 60%;
  padding-left: 32px;

  @media ${device.miniTabletNeg} {
    width: 100%;
    padding-left: 0;
  }

  & > div:first-child {
    margin-bottom: 48px;
  }

  & > div:last-child {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    text-align: left;
  }
`;
