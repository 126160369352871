import { useState, useRef, useEffect } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";
import { MTSInput } from "components/mts-input";
import { IAskueEquipments, EAskueCategoryKey } from "pages/askueEquipment/const";
import { useDebounce } from "hooks/useDebounce";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SSearch } from "../styles";

export type ISearch = {
  serchValue: string;
  list?: IAskueEquipments;
  size?: "XS" | "S" | "M";
  onItemClick: (a: string) => void;
  onApplyFilter: ({ filtersArr }) => void;
  setSearchValue: (a: string) => void;
};

export interface Suggestions {
  id: number;
  manufacturer: string;
  models?: string[];
  category: string;
}

export const SimpleSearch: React.FC<ISearch> = ({
  onItemClick,
  onApplyFilter,
  setSearchValue,
  serchValue,
  list,
  size = "XS",
}) => {
  const [isOpen, setOpen] = useState(false);
  const [suggestions, setSuggest] = useState<Suggestions[] | undefined>();
  const debouncedSearch = useDebounce(serchValue, 500);
  const ulRef = useRef(null);

  const handleInput = (e) => {
    setSearchValue(e.target.value);
    setOpen(true);
  };

  const handleSuggClick = (payload) => {
    const { manufacturer, category } = payload;
    onItemClick(payload);
    onApplyFilter({
      filtersArr: [
        { column: "categorys", value: [category] },
        { column: "manufacturer", value: [manufacturer] },
      ],
    });
    setSearchValue("");
    setOpen(false);
  };

  useOnClickOutside(ulRef, () => setOpen(false));

  useEffect(() => {
    const searchList: Suggestions[] = [];

    if (debouncedSearch && list) {
      const edited = debouncedSearch.toLowerCase().trim();

      for (const item in list) {
        list[item].forEach((a) => {
          const containsModels: string[] =
            a.models.filter((model) => model.toLowerCase().includes(edited)) ?? [];

          if (a.manufacturer?.toLowerCase().includes(edited) || containsModels.length) {
            searchList.push({
              id: a.id,
              manufacturer: a.manufacturer,
              models: containsModels.length ? containsModels : [],
              category: EAskueCategoryKey[item],
            });
          }
        });
      }
    }

    setSuggest(searchList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <SSearch>
      <MTSInput
        className="searchField"
        searchIcon
        placeholder="Производитель, модель, наименование"
        onChange={handleInput}
        value={serchValue}
        size={size}
      />

      {suggestions && isOpen ? (
        <ul ref={ulRef}>
          {suggestions?.map((a) => (
            <li key={a.id} onClick={() => handleSuggClick(a)}>
              <div>
                {a.manufacturer}{" "}
                <CustomTypography variant="c1_regular" font="comp">
                  ({a.category})
                </CustomTypography>
              </div>
              <div>
                <CustomTypography variant="c1_regular" font="comp">
                  {a.models ? a.models?.join(", ") : ""}
                </CustomTypography>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </SSearch>
  );
};
