import styled from "@emotion/styled";

import bgImg from "../landing/images/main-landing-bg.png";
import { device } from "theme";

export const SHead = styled.div`
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  padding: 128px 0 64px 0;
  background-size: cover, 924px auto;
  background-position: center, 102% 5%;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.darkMode};
  background-image: ${(props) =>
    `linear-gradient(to right, ${props.theme.mtsColor.background.primaryElevated.darkMode} 23%, rgba(0,0,0,0) 37%, rgba(0,0,0,0) 70%, ${props.theme.mtsColor.background.primaryElevated.darkMode} 100%  ),
    url(${bgImg})`};

  & > div {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1304px;
    width: 100%;

    & > div {
      max-width: 680px;
    }
  }
`;

export const SSubtext = styled.span`
  display: flex;
  & .MuiTypography-root {
    text-align: left;
    color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
    white-space: pre-wrap;
  }
`;

export const SGrid = styled.section`
  display: grid;
  grid-template-columns: 0 1fr;
  padding: 12px 20px 60px 20px;
  margin: 0 auto;
  max-width: 1304px;
  width: 100%;
  background: white;

  @media ${device.tabletPlus} {
    grid-template-columns: 292px 1fr;
    gap: 32px;
    padding: 80px 20px 200px 20px;
  }
`;

export const SSearch = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  margin-bottom: 24px;

  @media ${device.tabletPlus} {
    display: grid;
    grid-template-columns: auto 246px;
    gap: 24px;
  }
`;

export const SVitrina = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 174px);
  justify-content: center;
  row-gap: 6px;
  column-gap: 2px;

  @media ${device.tabletPlus} {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    gap: 24px;

    & > div {
      flex: 0 0 296px;
    }
  }
`;
