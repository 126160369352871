import { IAdditInfoProp } from "components/additional-info-list/additional-info";
import {
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_PAGE,
} from "stream-constants/route-constants";

import block1_pic1 from "./images/block1_pic1.png";
import block1_pic2 from "./images/block1_pic2.png";
import block1_pic3 from "./images/block1_pic3.png";

import block4_pic1 from "./images/block4_pic1.svg";
import block4_pic2 from "./images/block4_pic2.svg";
import block4_pic3 from "./images/block4_pic3.svg";
import block4_pic4 from "./images/block4_pic4.svg";
import block4_pic5 from "./images/block4_pic5.svg";
import block4_pic6 from "./images/block4_pic6.svg";
import block4_pic7 from "./images/block4_pic7.svg";
import block4_pic8 from "./images/block4_pic8.svg";
import block4_pic9 from "./images/block4_pic9.svg";

export const seo = {
  title: "АСКУЭ в облаке МТС",
  description:
    "Облачная платформа учета на базе ПК «Энергосфера» для сбора показаний с интеллектуальных счетчиков, хранения и обработки информации.",
};

export const headerOne = "Мы предоставляем\nспециализированные решения";
export const headerTwo =
  "Облачная платформа учета на базе ПК\u00A0«Энергосфера» обеспечивает решение следующих задач:";
export const headerThree =
  "В web-кабинете Абонента реализованы следующие функции комплексного учета энергоресурсов:";
export const headerFour = "Преимущества использования АСКУЭ\u00A0МТС\nна базе «Энергосфера»";

export const blockOne = [
  {
    title: "Энергосбытовые,\nсетевые компании и ГП",
    pic: block1_pic1,
    steps: [
      "Автоматизация учета энергоресурсов",
      "Интеграция с системами учета других производителей",
      "Поддержка более 300 типов отечественных приборов учета, УСПД и концентраторов",
    ],
  },
  {
    title: "Бизнес-центры\nи предприятия",
    pic: block1_pic2,
    steps: [
      "Контроль потребления электроэнергии",
      "Мониторинг аварий и нештатных ситуаций",
      "Отчеты по форме клиента – возможность представления собранной телеметрии в нужном клиенту формате",
    ],
  },
  {
    title: "УК/ЖКХ/\nСНТ/ТСЖ",
    pic: block1_pic3,
    steps: [
      "Сбор показаний",
      "Мониторинг параметров ести",
      "Создание и поддержка личных кабинетов конечных потребителей",
    ],
  },
];

export const blockTwo = [
  "Автоматизированный сбор данных по учету электроэнергии: показания (суточные, месячные, в т. ч. тарифные), профили нагрузки, журналы событий, текущие измерения параметров режима электрической сети.",
  "Расчет суммарных показателей энергопотребления по различным группам объектов, анализ балансов (приход/расход/отдача/потери электроэнергии на подстанциях, трансформаторных и распределительных пунктах, участках электросетей и прочих объектах, сравнение фактических небалансов с допустимыми значениями).",
  "Эксплуатационный мониторинг состояний приборов учета, каналов связи, программного и аппаратного обеспечения, регистрация и обработка критических событий, в том числе: нарушение нормальной схемы  электроснабжения, вмешательство в оборудование комплекса (санкционированное и несанкционированное), отклонение от режимов потребления, изменение локальных небалансов свыше порогового значения и т. д.",
  "Администрирование системы, включая управление пользователями, правами пользователей и их доступом к объектам на основе ролевой модели разграничения прав доступа.",
  "Удаленное ручное, полуавтоматическое (подготовка заявки по требованиям) ограничение/отключение нагрузки абонента (если это поддерживает счетчик), выдача разрешения на включение нагрузки.",
  "Контроль качества электроэнергии.",
];

export const blockThree = [
  "Показания счетчика — обеспечение абонента достоверной информацией о показаниях приборов учета и потреблении электроэнергии за прошедшие отчетные периоды времени, расчет потребления ресурсов в денежном выражении;",
  "Учетные данные — табличное и графическое представление информации о потребляемых энергоресурсах (электроэнергия, тепловая энергия, ГВС, ХВС, газ) в разрешенной зоне доступа к приборам учета;",
  "Журналы событий — детальный анализ событий, зарегистрированных прибором учета, общая статистика событий на заданном интервале времени, фильтры по категориям и статусу (важности) событий, группировка парных событий;",
  "Параметры электрической сети — анализ качества потребляемой электрической энергии по данным измерений параметров электрической сети. Для указанного момента времени можно получить полную характеристику режима электрической сети в заданной точке учета: текущие значения параметров режима, векторную диаграмму токов и напряжений, характер нагрузки по фазам, составляющие вектора полной мощности и др;",
  "Предоставление минимального функционала ИСУЭ в соответствии с требованиями ПП №890.",
];

export const blockFour = [
  {
    pic: block4_pic1,
    title: "Соответствие нормативным требованиям в области энергетики",
    sub: "АСКУЭ МТС обеспечивают соблюдение энергетических норм, таких как ПП РФ от 19.06.2020г. №890 и 522-ФЗ.",
  },
  {
    pic: block4_pic2,
    title: "Снижайте аварийность",
    sub: "Формирование в реальном времени тревожных сообщений (alarm) пользователям системы. Рассылка сообщений на АРМы, e-mail, мобильный телефон в виде SMS.",
  },
  {
    pic: block4_pic3,
    title: "Контроль в любом месте в любое время",
    sub: "Используйте web-версию на любом компьютере или мобильное приложение на смартфоне для мониторинга параметров сети.",
  },
  {
    pic: block4_pic4,
    title: "Центр обработки данных",
    sub: "В качестве инфраструктуры используются собственные ЦОДы – географически разнесенные и соответствующие требованиям ФЗ №152 «О персональных данных» от 27.07.2006.",
  },
  {
    pic: block4_pic5,
    title: "Формируйте отчетность",
    sub: "Отчеты по форме клиента – возможность представления собранной телеметрии в нужном клиенту формате.",
  },
  {
    pic: block4_pic6,
    title: "Учет потребления электроэнергии",
    sub: "Контролируйте расход электроэнергии во всех удаленных подразделениях с обычного компьютера.",
  },
  {
    pic: block4_pic7,
    title: "Управляйте нагрузкой",
    sub: "Удаленно управляйте реле нагрузки, включением, выключением, ограничением нагрузки.",
  },
  {
    pic: block4_pic8,
    title: "Снижайте расходы",
    sub: "Организуйте технический учет, выявляйте проблемные места и оптимизируйте потребление именно там, где это даст наибольшую экономию.",
  },
  {
    pic: block4_pic9,
    title: "Access Point Name трафик (APN)",
    sub: "Позволяет абоненту мобильной сети осуществлять защищенную передачу данных между прибором учета (ПУ) и АСКУЭ.",
  },
];

export const additionalInfo: IAdditInfoProp[] = [
  {
    title: "Облачная платформа\u00A0учета",
    icon: "cloud",
    link: `/${ROUTE_ASKUE_PAGE}`,
  },
  {
    title: "Поддерживаемые виды связи\u00A0и\u00A0оборудование",
    icon: "router",
    link: `/${ROUTE_ASKUE_EQUIPMENT}`,
  },
  {
    title: "Стоимость внедрения\u00A0АСКУЭ",
    icon: "ruble",
    link: `/${ROUTE_ASKUE_INTEGRATION}`,
  },
];
