import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Cart = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M14.5834 17.3335C14.5834 16.6432 14.0237 16.0835 13.3334 16.0835C12.643 16.0835 12.0834 16.6432 12.0834 17.3335V21.3335C12.0834 22.0239 12.643 22.5835 13.3334 22.5835C14.0237 22.5835 14.5834 22.0239 14.5834 21.3335V17.3335Z"
        fill="white"
      />
      <path
        d="M18.6667 16.0835C19.3571 16.0835 19.9167 16.6432 19.9167 17.3335V21.3335C19.9167 22.0239 19.3571 22.5835 18.6667 22.5835C17.9764 22.5835 17.4167 22.0239 17.4167 21.3335V17.3335C17.4167 16.6432 17.9764 16.0835 18.6667 16.0835Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 6.74008C12.9961 6.3195 12.78 5.91082 12.393 5.67857C12.1933 5.55876 11.9736 5.5012 11.7565 5.50026C11.3299 5.49841 10.9133 5.71503 10.678 6.10732L7.74235 11H4.25C3.55964 11 3 11.5597 3 12.25C3 12.8859 3.47484 13.4109 4.08929 13.4898C4.0331 14.3125 3.99973 15.1563 3.99973 16C3.99973 16.9327 4.0405 17.8654 4.1078 18.7696C4.36739 22.2576 4.49719 24.0016 6.24768 25.7521C7.99818 27.5026 9.74217 27.6324 13.2302 27.892C14.1343 27.9593 15.067 28 15.9997 28C16.9324 28 17.8651 27.9593 18.7693 27.892C22.2573 27.6324 24.0013 27.5026 25.7518 25.7521C27.5023 24.0016 27.6321 22.2576 27.8917 18.7696C27.9589 17.8654 27.9997 16.9327 27.9997 16C27.9997 15.1563 27.9664 14.3125 27.9102 13.4899C28.5249 13.4113 29 12.8861 29 12.25C29 11.5597 28.4404 11 27.75 11H24.2578L21.322 6.10707C21.0857 5.71324 20.6668 5.49646 20.2385 5.50004C20.023 5.50185 19.8052 5.55943 19.607 5.67832C19.2199 5.91057 19.0039 6.31925 19 6.73983C18.9979 6.96246 19.0553 7.18841 19.1783 7.39331L21.3423 11H10.6578L12.8217 7.39356C12.9446 7.18866 13.002 6.9627 13 6.74008ZM6.59474 13.5H25.4047C25.4645 14.3261 25.4997 15.1672 25.4997 16C25.4997 16.8611 25.462 17.7311 25.3986 18.5841C25.1265 22.24 24.9762 22.9922 23.984 23.9843C22.9918 24.9765 22.2397 25.1268 18.5837 25.3989C17.7308 25.4624 16.8608 25.5 15.9997 25.5C15.1387 25.5 14.2687 25.4624 13.4157 25.3989C9.75976 25.1268 9.00761 24.9765 8.01545 23.9843C7.02329 22.9922 6.87299 22.24 6.6009 18.5841C6.53742 17.7311 6.49973 16.8611 6.49973 16C6.49973 15.1672 6.53499 14.3261 6.59474 13.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Cart;
