import styled from "@emotion/styled/macro";
import { device } from "theme";

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(90deg, rgba(0, 20, 36, 1) 40%, rgba(0, 20, 36, 0) 100%);
  }

  @media ${device.miniTabletNeg} {
    &:before {
      background: linear-gradient(180deg, rgba(0, 20, 36, 1) 60%, rgba(0, 20, 36, 0) 100%);
    }
  }
`;

export const SBody = styled.div<{ maxWidth?: string }>`
  width: 100%;
  z-index: 1;
  position: relative;

  ${(props) =>
    props.maxWidth
      ? `
		max-width: ${props.maxWidth}
	  `
      : ``};

  @media ${device.miniTabletNeg} {
    max-width: inherit;
  }
`;

export const SSection = styled.section`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  background-color: #001424;
  z-index: 1;

  @media ${device.miniTabletNeg} {
    padding: 0;

    ${SContainer} {
      padding: 0;
    }

    ${SBody} {
      padding: 80px 20px 160px;
    }
  }
`;

export const SImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  z-index: -1;

  @media ${device.miniTabletNeg} {
    top: inherit;
    bottom: 0;
    width: 200%;
    height: auto;
  }
`;
