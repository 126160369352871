import { css } from "@emotion/react";

export const table_header_height = 40;
export const scrollbarWidth = 4;

export const customScrollVertical = ({
  theme,
  width = scrollbarWidth,
  trackStart = table_header_height,
  trackEnd = 0,
}) => css`
  &::-webkit-scrollbar {
    width: ${width}px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
    margin-top: ${trackStart}px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: ${trackEnd}px;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 4px;
    width: 6px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.mtsColor.background.stroke.lightMode};
    border-radius: 4px 4px 2px 4px;
  }
`;
