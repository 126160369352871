import { Fragment, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { unformatPhone } from "utils/formatters";
import { Spacer } from "components/spacer/spacer.component";
import { ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { Checkbox } from "components/checkbox/checkbox";
import { CustomTypography } from "components/customTypography/custom-typography";
import Icon from "components/icons";
import { login } from "services/auth";
import { useCreateDemoAskue } from "hooks/hooks";
import { defaultValues, FALLBACK_MSG, schema } from "./validation-schema";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SAddNew, SBtns, SDelIcon, SField, SInp, SLink, SPers, SWrap } from "./styles";

export const FormRegDemoAskue = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const [isShowForm, setIsShowForm] = useState<boolean>(true);
  const watchSn = watch("sn");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sn",
  });

  const toggleShowForm = () => {
    setIsShowForm((s) => !s);
  };
  const { mutate, isSuccess, isError, error } = useCreateDemoAskue();

  const onSubmit = async (submData) =>
    await mutate(
      {
        userName: submData.userName,
        phone: unformatPhone(submData.phone),
        sn: submData.sn.map((e) => e.name),
      },
      {
        onSuccess: () => {
          setIsShowForm(false);
        },
        onError: (err) => {
          setIsShowForm(false);
        },
      },
    );

  if (isSuccess && !isShowForm) {
    return (
      <SWrap>
        <ModalNotifyBody
          title="Вы успешно зарегестрированы"
          textDescrioption="Для входа в личный кабинет используйте указанный номер телефона"
          isSuccess
        />
        <Spacer value="48px" />
        <MTSButton size="M" variant="primary" onClick={login} style={{ width: "100%" }}>
          Войти в личный кабинет
        </MTSButton>
        <Spacer value="48px" />
      </SWrap>
    );
  } else if (isError && !isShowForm) {
    return (
      <SWrap>
        <ModalNotifyBody
          title="Ошибка регистрации"
          isError
          textDescrioption={error?.message ?? FALLBACK_MSG}
        />
        <Spacer value="48px" />
        <MTSButton size="M" variant="secondary" onClick={toggleShowForm} style={{ width: "100%" }}>
          Назад
        </MTSButton>
        <Spacer value="48px" />
      </SWrap>
    );
  } else {
    return (
      <>
        <SModalTitle>Регистрация Демо-АСКУЭ</SModalTitle>
        <Spacer value="8px" />
        <CustomTypography variant="p4_regular">
          Укажите свои контактные данные и серийный номер прибора учета
        </CustomTypography>
        <Spacer value="32px" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <SField>
            <MTSInput
              label="Ваше имя"
              control={control}
              {...register("userName", {
                required: true,
              })}
              errorMessage={errors?.userName?.message}
            />
          </SField>
          <SField>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputMask mask={PHONE_MASK} onChange={onChange} value={value}>
                    {() => (
                      <MTSInput label="Номер телефона" errorMessage={errors?.phone?.message} />
                    )}
                  </InputMask>
                );
              }}
            />
          </SField>
          {fields.map((field, index) => (
            <Fragment key={field.id}>
              <SInp>
                <MTSInput
                  label="Серийный номер устройства"
                  control={control}
                  {...register(`sn.${index}.name`, { required: index === 0 })}
                  errorMessage={
                    errors?.sn?.[index]?.name ? errors?.sn?.[index]?.name.message : null
                  }
                />
                {index !== 0 ? (
                  <SDelIcon
                    onClick={() => remove(index)}
                    isError={!!errors?.sn?.[index]?.name.message}
                  >
                    <Icon.Close sx={{ width: 20 }} />
                  </SDelIcon>
                ) : null}
              </SInp>
              {index === fields.length - 1 && (
                <SAddNew>
                  <MTSButton
                    size="S"
                    variant="secondary"
                    onClick={() => {
                      append({
                        name: "",
                      });
                    }}
                    icon={<Icon.Plus sx={{ width: 14, height: 14 }} />}
                    disabled={index !== 0 && !watchSn[index].name.length}
                  >
                    Новое устройство
                  </MTSButton>
                </SAddNew>
              )}
            </Fragment>
          ))}
          <Spacer value="16px" />
          <SBtns>
            <Controller
              name="agree"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    value="true"
                    checked={value === "true"}
                    onChange={(a) => onChange(a === value ? undefined : a)}
                    label={
                      <CustomTypography variant="c1_regular" font="comp">
                        Вы соглаcны на{" "}
                        <SLink target="_blank" rel="noreferrer" to={`/${ROUTE_USER_AGREEMENT}`}>
                          обработку персональных данных
                        </SLink>
                      </CustomTypography>
                    }
                    color="red"
                  />
                );
              }}
            />
            <Spacer value="40px" />
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!isValid}
            >
              Зарегистрироваться
            </MTSButton>
          </SBtns>
        </form>
        <Spacer value="24px" />
        <CustomTypography variant="p4_regular" font="comp">
          Уже зарегистрированы?
        </CustomTypography>
        <SPers variant="p4_regular" font="comp" onClick={login}>
          Войдите в личный кабинет
        </SPers>
        <Spacer value="32px" />
      </>
    );
  }
};
