import { IHttpMethods } from "stream-constants/server-constants";
import { FORM_CONSULTATION } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

export const getSubmitFormConsultation = async (data: any) => {
  let result = fetchData({
    url: FORM_CONSULTATION,
    method: IHttpMethods.POST,
    name: "formConsultation",
    body: JSON.stringify(data),
    emptyResponse: false,
  });

  return result;
};
