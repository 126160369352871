import styled from "@emotion/styled";
import { MTS_COMPACT } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 80px 0 80px 0;
  text-align: left;
`;

export const SParag = styled.div`
  & > span {
    display: inline-block;
    margin-bottom: 20px;
  }

  & > p {
    margin-bottom: 20px;
    font-family: ${MTS_COMPACT};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const STable = styled.div`
  & > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    height: auto;
    text-align: center;
    margin-bottom: 20px;
  }
  & > table,
  & > table td,
  & > table th {
    font-size: 17px;
    font-family: ${MTS_COMPACT};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid ${(props) => props.theme.mtsColor.grey.greyMedium};
  }
  & > table td,
  & > table th {
    padding: 3px;
    width: 30px;
    height: 35px;
  }
  & > table th {
    background: ${(props) => props.theme.mtsColor.grey.greyLight};
    color: #fff;
    font-weight: normal;
  }
`;
