import styled from "@emotion/styled";
import { device } from "theme";

export const SSectionTitle = styled.h2<{ isWhite?: boolean }>`
  margin-bottom: 12px;
  text-align: left;

  @media ${device.tabletPlus} {
    margin-bottom: 48px;
  }

  ${(props) =>
    props.isWhite
      ? `
			& .MuiTypography-root {
			color: ${props.theme.mtsColor.text.inverted.lightMode};
			};
		`
      : ``}
`;
