import * as yup from "yup";

import validationMethod from "../validation-method";
import { USER_IP } from "./const";

const REQUIRED = "Поле не заполнено";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";
const INCORRECT = "Некорректно заполнено поле";
const INC_INN = "Должен содержать 10 или 12 цифр";
const INC_OGRN = "Должен содержать 13 цифр";
const INC_OGRNIP = "Должен содержать 15 цифр";
const INC_LENGTH_9 = "Должен содержать 9 цифр";
const INC_LENGTH_20 = "Должен содержать 20 цифр";
const INC_ORG =
  "Только кириллица, с заглавной буквы названия компании (либо аббревиатура правовой формы)";

export const defaultValBankInfo = {
  rassch_schet: "",
  bank_name: "",
  corr_schet: "",
  bik: "",
};

export const defaultValFeedbackInfo = {
  name: "",
  user_phone: "",
  user_email: "",
};

export const defaultValInformation = {
  tenant_name: "",
  inn: "",
  ogrnip: "",
  ogrn: "",
  kpp: "",
  ur_adress: "",
  owner_name: "",
  phone: "",
  email: "",
  post_adress: "",
  phone_add: "",
  copy_ur_adress: false,
};

export const schemaBank = yup.object().shape({
  rassch_schet: yup
    .string()
    .typeError(REQUIRED)
    .test("correctRasschSchet", INC_LENGTH_20, validationMethod.correctLengthVal20)
    .required(REQUIRED),
  bank_name: yup.string().required(REQUIRED),
  corr_schet: yup
    .string()
    .typeError(REQUIRED)
    .test("correctCoorSchet", INC_LENGTH_20, validationMethod.correctLengthVal20)
    .required(REQUIRED),
  bik: yup
    .string()
    .typeError(REQUIRED)
    .test("correctBik", INC_LENGTH_9, validationMethod.correctLengthVal9)
    .required(REQUIRED),
});

export const schemaFeedback = yup.object().shape({
  name: yup.string().required(REQUIRED),
  user_phone: yup.string().required(REQUIRED),
  user_email: yup.string().email(INCORRECT).optional(),
});

export const schemaInformation = yup
  .object()
  .shape({
    tenant_name: yup
      .mixed()
      .required(REQUIRED)
      .test("requiredOrganizationName", INC_ORG, validationMethod.correctOrganization),
    inn: yup
      .string()
      .min(10, INC_INN)
      .max(12, INC_INN)
      .test("correctInn", INC_INN, validationMethod.correctInn)
      .nullable()
      .required(REQUIRED),
    ogrnip: yup.string().when("type_user", {
      is: USER_IP,
      then: yup
        .string()
        .test("correctOgrn", INC_OGRNIP, validationMethod.correctOgrnip)
        .required(REQUIRED)
        .nullable(),
      otherwise: yup.string().nullable().typeError(INCORRECT),
    }),
    ogrn: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup
        .string()
        .test("correctOgrn", INC_OGRN, validationMethod.correctOgrn)
        .nullable()
        .required(REQUIRED),
    }),
    kpp: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup
        .string()
        .test("correctKpp", INC_LENGTH_9, validationMethod.correctLengthVal9)
        .nullable()
        .required(REQUIRED),
    }),
    ur_city: yup.string().required(REQUIRED),
    ur_street: yup.string().required(REQUIRED),
    ur_house: yup.string().required(REQUIRED),
    ur_flat: yup.string().required(REQUIRED),
    ur_index: yup.string().required(REQUIRED),
    post_city: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string().required(REQUIRED),
    }),
    post_street: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string().required(REQUIRED),
    }),
    post_house: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string().required(REQUIRED),
    }),
    post_flat: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string().required(REQUIRED),
    }),
    post_index: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string().required(REQUIRED),
    }),
    owner_name: yup.string().nullable().required(REQUIRED),
    phone: yup.string().required(REQUIRED),
    email: yup.string().email(INCORRECT).optional().nullable().required(REQUIRED),
    phone_add: yup.string().when("type_user", {
      is: USER_IP,
      then: yup.string().nullable().typeError(INCORRECT),
      otherwise: yup.string(),
    }),
  })
  .required();

export const schemaOrder = yup.object().shape({
  agree: yup.string().test("a", (v) => (v && v === "true") as any),
  oferta: yup.string().test("a", (v) => (v && v === "true") as any),
});

export const schemaPromo = yup.object().shape({
  promocode: yup.string().min(1, INCORRECT).typeError(INCORRECT),
});
