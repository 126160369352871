import { forwardRef, PropsWithChildren } from "react";
import styled from "@emotion/styled";

import { InputErrorMessage } from "components/input-error-message";
import { InputLabel } from "components/input-label";
import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { SCont, SInpIcon, SInput, SLabel, STextarea, SWrap } from "./styles";
import { Spinner } from "components/spinner";

interface IMTSInputProps {
  type?: string;
  placeholder?: string;
  label?: string;
  searchIcon?: boolean;
  errorMessage?: string | undefined;
  [x: string]: any;
  touched: boolean;
  size?: "XS" | "S" | "M";
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  maxLength?: number;
}

const SMTSInput = forwardRef<HTMLDivElement, PropsWithChildren<IMTSInputProps>>((props, ref) => {
  const {
    label = "",
    searchIcon,
    type = "text",
    placeholder = "",
    errorMessage,
    touched,
    size = "M",
    className = "",
    disabled = false,
    isLoading,
    maxLength,
    ...rest
  } = props;

  return (
    <SWrap ref={ref} className={className}>
      {label ? (
        <>
          <SLabel>
            <InputLabel label={label} disabled={disabled} hasError={Boolean(errorMessage)} />
          </SLabel>
          <Spacer value="4px" />
        </>
      ) : null}
      <SCont>
        {searchIcon ? <Icon.Search className="icon--search" /> : null}
        {type === "textarea" ? (
          <STextarea
            placeholder={placeholder}
            rows={1}
            wrap="soft"
            spellCheck
            hasError={Boolean(errorMessage)}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <SInput
            autoComplete="off"
            type={type}
            placeholder={placeholder}
            hasError={Boolean(errorMessage)}
            sizing={size}
            withSearch={searchIcon}
            disabled={disabled}
            maxLength={maxLength}
            {...rest}
          />
        )}

        {isLoading ? (
          <SInpIcon>
            <Spinner />
          </SInpIcon>
        ) : null}

        {touched || errorMessage ? (
          <SInpIcon>
            {errorMessage ? (
              <Icon.InputError sx={{ width: 18, height: 18 }} />
            ) : (
              <Icon.InputOk sx={{ width: 24, height: 24 }} />
            )}
          </SInpIcon>
        ) : null}
      </SCont>
      {errorMessage && <InputErrorMessage errorMessage={errorMessage} />}
    </SWrap>
  );
});

export const MTSInput = styled(SMTSInput)``;
