import Icon from "components/icons";

export const renderIcons = (val: "check" | "router" | "ruble" | "cloud") => {
  switch (val) {
    case "check":
      return <Icon.CheckSquare sx={{ width: 24, height: 24 }} />;
    case "router":
      return <Icon.Router sx={{ width: 24, height: 24 }} />;
    case "ruble":
      return <Icon.RubleSquare sx={{ width: 24, height: 24 }} />;
    case "cloud":
      return <Icon.Cloud sx={{ width: 24, height: 24 }} />;
  }
};
