import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Icon from "components/icons";

import getSpinnerColor from "./utils/get-spinner-color";
import getSpinnerSize from "./utils/get-spinner-size";

const useStyles = makeStyles((theme: Theme) => ({
  rotateIcon: {
    animation: "spin 1s linear infinite",
  },
}));

interface ISpinnerProps {
  size?: "S_16" | "M_24" | "L_40";
  color?: "white" | "black" | "orange" | "red";
}

export const Spinner = (props: ISpinnerProps): JSX.Element => {
  const { size = "M_24", color = "red" } = props;
  const classes = useStyles();

  return (
    <Icon.Spinner
      className={classes.rotateIcon}
      sx={{
        fontSize: getSpinnerSize(size),
        color: getSpinnerColor(color),
        "@keyframes spin": {
          from: { transform: "rotate(0deg)" },
          to: { transform: "rotate(360deg)" },
        },
      }}
    />
  );
};

// по умолчанию full page
export const Loader = ({ isFullPage = true }) => {
  return (
    <SPos isFullPage={isFullPage}>
      <Spinner />
    </SPos>
  );
};

const SPos = styled.summary<{ isFullPage?: boolean }>`
  min-width: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(props) =>
    props.isFullPage &&
    `
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(128, 128, 128, 0.5);
	z-index: 20;
  `}
`;
