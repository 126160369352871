import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

export const SDrop = styled.div<{ isOpen?: boolean; isActive?: boolean }>`
  position: relative;
  min-width: 0;
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 100%;
  user-select: none;
  cursor: pointer;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: ${(props) => (props.isActive ? props.theme.mtsColor.red : "transparent")};
  }

  & > span {
    margin-right: 4px;
    white-space: nowrap;
    font-size: 16px;
    transition: color 0.3s linear;

    &:first-of-type {
      color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
    }
  }

  & > svg {
    transition: transform 0.3s linear;
    ${(props) =>
      props.isOpen &&
      css`
        transform: rotate(180deg);
      `}
  }

  & > svg {
    & path {
      transition: fill 0.3s linear;
      fill: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
    }
  }

  &:hover {
    & > span {
      color: white;
    }

    & > svg {
      & path {
        fill: white;
      }
    }
  }
`;

export const SInner = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 64px;
  left: 0;
  padding: 32px 32px 32px 208px;
  max-width: 100%;
  width: 100%;
  min-height: 240px;
  height: auto;
  z-index: 10;
  background: white;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
  text-align: left;
  cursor: default;

  ${(props) =>
    props.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const SHead = styled(Typography)`
  display: block;
  margin-bottom: 24px;
  & > span {
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }
`;

export const SRow = styled.div<{ hasSelected?: boolean }>`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;

  & > div {
    &:nth-of-type(1) {
      position: relative;
      min-width: 0;
      flex: 0 0 330px;
      display: flex;
      flex-flow: column nowrap;

      &:after {
        position: absolute;
        display: ${(props) => (props.hasSelected ? "block" : "none")};
        right: 0;
        top: 0;
        content: "";
        width: 1px;
        height: 100%;
        background: ${(props) => props.theme.mtsColor.grey.greyLight};
      }
    }

    &:nth-of-type(2) {
      flex: 1 1 auto;
      min-width: 0;
      display: flex;
      flex-flow: column nowrap;
      padding-left: 24px;
    }
  }
`;

export const SMain = styled(NavLink)`
  position: relative;
  display: inline-block;
  padding: 10px 12px;
  width: min-content;
  white-space: nowrap;
  transition: color 0.3s linear;
  text-decoration: none;
  cursor: pointer;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    transition: color 0.3s linear;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    & > span {
      color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    }
  }

  & > svg {
    display: none;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &.active {
    & > span {
      font-weight: 500;
      color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    }

    & > svg {
      display: block;
    }
  }
`;

export const SSub = styled(NavLink)`
  display: inline-block;
  padding: 10px 12px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    transition: color 0.3s linear;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    & > span {
      color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    }
  }
`;
