import React from "react";

interface IProp {
  className?: string;
  width?: string;
  border?: string;
  fill?: string;
}

export const Base: React.FC<IProp> = ({ className, border = "#BCC3D0", fill = "white" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.08056 9.92453C3.27526 7.30854 3.37261 6.00055 4.68548 4.68768C5.99835 3.3748 7.30635 3.27746 9.92233 3.08276C10.6005 3.03229 11.3 3.00171 11.9995 3.00171C12.699 3.00171 13.3986 3.03229 14.0767 3.08276C16.6927 3.27746 18.0007 3.3748 19.3135 4.68768C20.6264 6.00055 20.7238 7.30854 20.9185 9.92453C20.9689 10.6027 20.9995 11.3022 20.9995 12.0017C20.9995 12.7012 20.9689 13.4008 20.9185 14.0789C20.7238 16.6949 20.6264 18.0029 19.3135 19.3157C18.0007 20.6286 16.6927 20.726 14.0767 20.9207C13.3986 20.9711 12.699 21.0017 11.9995 21.0017C11.3 21.0017 10.6005 20.9711 9.92233 20.9207C7.30635 20.726 5.99835 20.6286 4.68548 19.3157C3.37261 18.0029 3.27526 16.6949 3.08056 14.0789C3.0301 13.4008 2.99951 12.7012 2.99951 12.0017C2.99951 11.3022 3.0301 10.6027 3.08056 9.92453Z"
      fill={fill}
    />
    <path
      d="M3.8285 9.9802C4.02694 7.31382 4.13044 6.30338 5.21581 5.21801C6.30118 4.13264 7.31162 4.02914 9.978 3.83069C10.6408 3.78137 11.3215 3.75171 11.9995 3.75171C12.6775 3.75171 13.3583 3.78137 14.021 3.83069C16.6874 4.02914 17.6978 4.13264 18.7832 5.21801C19.8686 6.30338 19.9721 7.31382 20.1705 9.9802C20.2199 10.643 20.2495 11.3237 20.2495 12.0017C20.2495 12.6797 20.2199 13.3605 20.1705 14.0232C19.9721 16.6896 19.8686 17.7 18.7832 18.7854C17.6978 19.8708 16.6874 19.9743 14.021 20.1727C13.3583 20.2221 12.6775 20.2517 11.9995 20.2517C11.3215 20.2517 10.6408 20.2221 9.978 20.1727C7.31162 19.9743 6.30118 19.8708 5.21581 18.7854C4.13044 17.7 4.02694 16.6896 3.8285 14.0232C3.77917 13.3605 3.74951 12.6797 3.74951 12.0017C3.74951 11.3237 3.77917 10.643 3.8285 9.9802Z"
      stroke={border}
      strokeOpacity="0.5"
      strokeWidth="1.5"
    />
  </svg>
);

export const Filled: React.FC<IProp> = ({ className, fill = "" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.08056 9.92453C3.27526 7.30854 3.37261 6.00055 4.68548 4.68768C5.99835 3.3748 7.30635 3.27746 9.92233 3.08276C10.6005 3.03229 11.3 3.00171 11.9995 3.00171C12.699 3.00171 13.3986 3.03229 14.0767 3.08276C16.6927 3.27746 18.0007 3.3748 19.3135 4.68768C20.6264 6.00055 20.7238 7.30854 20.9185 9.92453C20.9689 10.6027 20.9995 11.3022 20.9995 12.0017C20.9995 12.7012 20.9689 13.4008 20.9185 14.0789C20.7238 16.6949 20.6264 18.0029 19.3135 19.3157C18.0007 20.6286 16.6927 20.726 14.0767 20.9207C13.3986 20.9711 12.699 21.0017 11.9995 21.0017C11.3 21.0017 10.6005 20.9711 9.92233 20.9207C7.30635 20.726 5.99835 20.6286 4.68548 19.3157C3.37261 18.0029 3.27526 16.6949 3.08056 14.0789C3.0301 13.4008 2.99951 12.7012 2.99951 12.0017C2.99951 11.3022 3.0301 10.6027 3.08056 9.92453Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2069 10.2071C16.5974 9.81658 16.5974 9.18342 16.2069 8.79289C15.8163 8.40237 15.1832 8.40237 14.7927 8.79289L10.9998 12.5858L9.70686 11.2929C9.31634 10.9024 8.68317 10.9024 8.29265 11.2929C7.90212 11.6834 7.90212 12.3166 8.29265 12.7071L10.2926 14.7071C10.6832 15.0976 11.3163 15.0976 11.7069 14.7071L16.2069 10.2071Z"
      fill="white"
    />
  </svg>
);
