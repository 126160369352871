import styled from "@emotion/styled";
import { device } from "theme";
import { Link } from "react-router-dom";

export const SWrap = styled.div`
  background-color: ${(props) => props.theme.mtsColor.base.darkNight};
  padding-top: 80px;
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const STop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 80px;

  @media ${device.miniTabletNeg} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const SText = styled.div`
  text-align: left;

  @media ${device.miniTabletNeg} {
    text-align: center;
  }

  & > .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.grey.greyMedium};
    display: block;
    text-align: left;

    @media ${device.miniTabletNeg} {
      text-align: center;
    }
  }
`;

export const SIcon = styled.div`
  max-width: 191px;
  position: absolute;
  top: 50%;
  right: 7.5%;
  transform: translateY(-50%);
  & svg {
    width: 100%;
    height: 100%;
  }

  @media ${device.miniTabletNeg} {
    max-width: 100px;
    position: relative;
    top: inherit;
    right: inherit;
    transform: none;
  }
`;

export const SBottom = styled.div`
  border-top: 1px solid #2e3f50;
  padding-top: 32px;

  @media ${device.miniTabletNeg} {
    padding-top: 0;
  }
`;

export const SInfo = styled.div`
  display: flex;

  @media ${device.miniTabletNeg} {
    display: none;
  }
`;

export const SAbout = styled.div`
  width: 54%;
  padding-right: 118px;
`;

export const SAboutTitle = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 8px;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  }
`;

export const SAboutDescript = styled.div`
  display: block;
  text-align: left;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  }
`;

export const SLinks = styled.div`
  flex: 1;
  padding-left: 70px;
`;

export const SListItem = styled.li`
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const SItemLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }
  &:hover .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.headline.darkMode};
  }
`;

export const SCopyright = styled.div`
  text-align: left;
  padding: 24px 0;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }
`;
