import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/styles";

import Icon from "components/icons";
import { OFFSET_24 } from "theme";
import { CustomTypography } from "components/customTypography/custom-typography";

const useStyles = makeStyles((theme: Theme) => ({
  sortIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sorting: {
    position: "relative",
    width: "min-content",
    zIndex: "1",
    overflow: "initial !important",
    "&:before": {
      position: "absolute",
      content: "''",
      width: "calc(100% + 12px)",
      right: "-4px",
      top: "1px",
      height: "100%",
      backgroundColor: theme.mtsColor.controls.inactive.lightMode,
      borderRadius: "8px",
      zIndex: "-1",
    },
  },
}));

interface IRenderFilterProps {
  isSorted: boolean;
  isSortedDesc: boolean;
  title: string;
  withPadding?: boolean;
  tooltip?: any;
  showHints?: boolean;
  disableSortBy?: boolean;
}

export const SWrap = styled.div`
  display: flex;
`;

export const RenderFilter = ({
  isSorted,
  isSortedDesc,
  title,
  withPadding,
  disableSortBy,
}: IRenderFilterProps) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  return (
    <CustomTypography
      variant="c1_bold"
      styles={{
        color: theme.mtsColor.text.secondary.lightMode,
        display: "flex",
        cursor: "pointer",
        whiteSpace: "nowrap",
        ...(withPadding ? { margin: `0 0 0 ${OFFSET_24}` } : {}),
      }}
      className={isSorted ? classes.sorting : ""}
    >
      {disableSortBy ? (
        title
      ) : (
        <HeaderItem isSorted={isSorted} isSortedDesc={isSortedDesc} title={title} />
      )}
    </CustomTypography>
  );
};

export const HeaderItem = ({ isSorted, isSortedDesc, title }: IRenderFilterProps) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <>
      {title}
      <div className={classes.sortIcon}>
        {isSorted ? (
          isSortedDesc ? (
            <Icon.ArrowDownMin
              sx={{
                fontSize: 16,
                color: theme.mtsColor.icons.primary.lightMode,
              }}
            />
          ) : (
            <Icon.ArrowUpMin
              sx={{
                fontSize: 16,
                color: theme.mtsColor.icons.primary.lightMode,
              }}
            />
          )
        ) : (
          <Icon.ArrowDownMin
            sx={{
              fontSize: 16,
              color: theme.mtsColor.text.secondary.lightMode,
            }}
          />
        )}
      </div>
    </>
  );
};
