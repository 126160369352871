import styled from "@emotion/styled";
import theme, { MTS_COMPACT } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 80px 0 80px 0;
  text-align: left;
`;

export const SParag = styled.div`
  & > .MuiTypography-root {
    margin-bottom: 20px;
    display: block;
  }

  & ul {
    margin-left: 10px;
    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 22px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        height: 5px;
        width: 5px;
        border-radius: 3px;
        background-color: ${theme.mtsColor.background.primary.darkMode};
      }

      & .MuiTypography-root {
        & > span {
          font-family: ${MTS_COMPACT};
        }
      }
    }
  }
`;

export const STable = styled.div`
  & > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    height: auto;
    text-align: center;
    margin-bottom: 20px;
  }
  & > table,
  & > table td,
  & > table th {
    border: 1px solid ${(props) => props.theme.mtsColor.grey.greyMedium};
  }
  & > table td,
  & > table th {
    padding: 3px;
    width: 30px;
    height: 35px;
  }
  & > table th {
    background: ${(props) => props.theme.mtsColor.grey.greyLight};
    color: #fff;
    font-weight: normal;
  }
`;
