import styled from "@emotion/styled";

export const SWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: min-content;
  cursor: pointer;

  & > svg {
    margin-right: 14px;
  }

  & > span {
    white-space: nowrap;
  }
`;
