import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import useOnClickOutside from "hooks/useOnClickOutside";
import { getTypographyVariant } from "./const";
import Icon from "components/icons";
import { InputLabel } from "components/input-label";
import { InputErrorMessage } from "components/input-error-message";
import { CustomTypography } from "components/customTypography/custom-typography";
import {
  SIconBlock,
  SResultBlock,
  SContainer,
  SList,
  SOption,
  SSelect,
  SPlaceholder,
  SInline,
  SInlineTypo,
} from "./styles";

type IOption = {
  val: string;
  friendlyName?: string;
};

interface ISelect {
  initValue?: string;
  isDefaultValue?: boolean;
  label?: string;
  size?: "S" | "M" | "L";
  view?: "standard" | "minimal";
  placeholder?: string;
  optionsList?: IOption[];
  errorMessage?: string;
  disabled?: boolean;
  onChange?: Function;
  className?: string;
}

const ComponentSelect: React.FC<ISelect> = ({
  isDefaultValue,
  initValue,
  label,
  size = "S",
  view = "standard",
  placeholder,
  optionsList,
  errorMessage,
  disabled,
  onChange,
  className = "",
}) => {
  const [isShowList, setShowList] = useState(false);
  const [isError, setError] = useState(false);
  const [selectVal, setSelectVal] = useState<string | null | undefined>(initValue);
  const containerRef = useRef<HTMLDivElement>(null);

  const closeList = () => {
    setShowList(false);
  };

  const handleToogleList = () => {
    setShowList((prev) => !prev);
  };

  const handleSelectOption = (opt: IOption) => {
    setSelectVal(opt.friendlyName ?? opt.val);
    setError(false);
    setShowList((prev) => !prev);
    onChange?.(opt.val);
  };

  const renderValue = (val: string) => {
    return <CustomTypography variant={getTypographyVariant(size)}>{val}</CustomTypography>;
  };

  useEffect(() => {
    if (initValue) setSelectVal(initValue);
  }, [initValue]);

  useEffect(() => {
    setError(!!errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useOnClickOutside(containerRef, closeList);

  return (
    <SContainer ref={containerRef} disabled={disabled} className={className} view={view}>
      {label ? <InputLabel label={label} /> : null}
      {view === "standard" ? (
        <SSelect size={size} onClick={handleToogleList} hasError={isError}>
          <SResultBlock>
            {!isDefaultValue ? (
              renderValue(selectVal ?? "")
            ) : (
              <SPlaceholder>
                <CustomTypography variant={getTypographyVariant(size)}>
                  {placeholder}
                </CustomTypography>
              </SPlaceholder>
            )}
          </SResultBlock>
          <SIconBlock isOpen={isShowList}>
            <Icon.ArrowDown sx={{ width: 16 }} />
          </SIconBlock>
        </SSelect>
      ) : (
        <SInline onClick={handleToogleList}>
          <SInlineTypo>{selectVal ?? ""}</SInlineTypo>
          <SIconBlock isOpen={isShowList}>
            <Icon.ArrowDown sx={{ width: 16 }} />
          </SIconBlock>
        </SInline>
      )}

      {isError && <InputErrorMessage errorMessage={errorMessage} />}
      {isShowList ? (
        <SList>
          {optionsList?.map((option) => {
            return (
              <SOption
                data-name={option.val}
                key={option.val}
                onClick={() => handleSelectOption(option)}
              >
                <CustomTypography variant={getTypographyVariant(size)}>
                  {renderValue(option.friendlyName ?? option.val)}
                </CustomTypography>
              </SOption>
            );
          })}
        </SList>
      ) : null}
    </SContainer>
  );
};

export const Select = styled(ComponentSelect)``;
