import styled from "@emotion/styled";
import { device } from "theme";

export const SItem = styled.li`
  text-align: left;
  margin-bottom: 16px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    height: 8px;
    width: 8px;
    background-color: ${(props) => props.theme.mtsColor.red};
    border-radius: 4px;
  }

  @media ${device.miniTabletNeg} {
    & .MuiTypography-root {
      font-size: 16px;
    }
  }
`;
