import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import App from "components/app";
import ReactQueryWrapper from "react-query-wrapper";

import theme from "theme";
import { ContextProvider } from "context";
import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <ContextProvider>
    <ThemeProvider theme={theme}>
      <ReactQueryWrapper>
        <Router>
          <App />
          <ToastContainer
            position={"bottom-right"}
            autoClose={false}
            pauseOnHover
            closeOnClick
            closeButton={false}
            draggable={false}
            theme="dark"
          />
        </Router>
      </ReactQueryWrapper>
    </ThemeProvider>
  </ContextProvider>,
  document.getElementById("root"),
);
