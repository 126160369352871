import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowUp = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="mask0_1459_67639" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M0 24H24V2.09815e-06L2.09815e-06 0L0 24Z" />
      </mask>
      <g mask="url(#mask0_1459_67639)">
        <path d="M18.9875 15C19.8877 15 20.3386 13.9234 19.702 13.2938L16.5727 10.1984C14.4171 8.0661 13.3393 7 12 7C10.6607 7 9.5829 8.0661 7.4273 10.1984L4.298 13.2938C3.6614 13.9234 4.1123 15 5.0125 15C5.2804 15 5.5375 14.8947 5.7269 14.7073L8.8563 11.6118C9.9745 10.5058 10.6724 9.8214 11.2456 9.3888C11.7629 8.9984 11.9435 8.9988 11.9972 8.999H12H12.0027C12.0565 8.9988 12.2371 8.9984 12.7544 9.3888C13.3276 9.8214 14.0255 10.5058 15.1437 11.6118L18.2731 14.7073C18.4626 14.8947 18.7196 15 18.9875 15Z" />
      </g>
    </SvgIcon>
  );
};

export default ArrowUp;
