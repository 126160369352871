import { SBody, SContainer, SImg, SSection } from "./styles";

interface SectionPromoProps {
  bgImg?: string;
  children?: React.ReactNode | string;
  maxWidth?: string;
}

export const PromoSection = ({ bgImg, children, maxWidth }: SectionPromoProps) => {
  return (
    <SSection style={{ backgroundImage: "none" }}>
      <SContainer>
        <SBody maxWidth={maxWidth}>{children}</SBody>
        <SImg src={bgImg} alt="background" />
      </SContainer>
    </SSection>
  );
};
