import { useCallback, useMemo } from "react";

import { tableMobStructure, tableStructure } from "./const";
import { constructTableData } from "./utils";
import { ITableFilterClm, Table } from "components/table/table.component";
import { useBreakpoint } from "hooks/useBreakpoint";

interface ITableProps {
  data: any[];
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  title?: string;
  onRowsChange?: Function;
}

export const TableAskueEquipments: React.FC<ITableProps> = ({
  data,
  filterByColumn,
  globalFilter,
  title,
  onRowsChange,
}) => {
  const { isTablet } = useBreakpoint();
  const tableData = useMemo(() => constructTableData(data, title) ?? [{}], [data, title]);
  const tableColumns = useMemo(() => tableStructure, []);
  const tableMobColumns = useMemo(() => tableMobStructure, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  return (
    <Table
      columns={isTablet() ? tableMobColumns : tableColumns}
      data={tableData}
      globalFilter={globalFilter}
      title={title}
      filterByColumn={filterByColumn}
      onRowsChange={handleRowsChange}
    />
  );
};
