export const seo = {
  title: "Оферта на лицензии – EnergyTool",
  description:
    "Ознакомьтесь с офертой на лицензии EnergyTool на нашем сайте. Соглашение не требует двустороннего подписания и действительно в электронном виде.",
};

export const pageTitle = "Оферта на лицензии EnergyTool";
export const pageContent = [
  {
    title: "",
    text: [
      "<b>ПАО «МТС»</b> предлагает Пользователям заключить настоящее Пользовательское соглашение (далее – <b>Соглашение</b>) на приведённых ниже условиях.",
      "Пользователь удостоверяет, что условия Соглашения принимаются им без каких-либо возражений, Пользователь понял и принял значение используемых в Соглашении терминов, слов и выражений согласно их нормативно-правовому определению и/или толкованию, указанному в Соглашении.",
      "Соглашение не требует двустороннего подписания и действительно в электронном виде.",
    ],
  },
  {
    title: "1. Термины и определения",
    text: [
      "1.1. Авторизация - процедура предоставления доступа к функционалу ПО, производимая на основании успешной Аутентификации.",
      "1.2. Идентификация – процесс подтверждения данных Пользователя.",
      "1.3. Акцепт Соглашения –совершение действий в соответствии с п. 3.2 настоящего Соглашения. После совершения Акцепта Соглашения Пользователь считается принявшим безоговорочно и в целом все условия настоящего Соглашения.",
      "1.4. Аутентификация - процедура проверки подлинности Пользователя с помощью метода аутентификации, указанного в п. 4.1 настоящего Соглашения, необходимая для Авторизации такого Пользователя.",
      "1.5. Личный кабинет – защищенная часть ПО, размещенная на Сайте, доступ к которой предоставляется в соответствии с условиями настоящего Соглашения, и предназначенная для управления ПО.",
      "1.6. Отчетный период – один календарный квартал.",
      "1.7. МТС - Публичное акционерное общество «Мобильные ТелеСистемы» (место нахождения: Российская Федерация, 109147, г. Москва, ул. Марксистская, д. 4, ИНН/КПП 7740000076/770901001, ОГРН 1027700149124), предоставляющее право использования ПО.",
      "1.8. Программное обеспечение «EnergyTool» – программа для ЭВМ, право использования которой МТС предоставляет Пользователю на условиях настоящего Соглашения и соответствующей Спецификации. Программное обеспечение позволяет выявлять конкретные приборы учета, в отношении которых с высокой степенью вероятности следует ожидать, что фактическое потребление и/или потери электроэнергии в данном месте существенно отличаются от регистрируемых, т.е. имеет место безучётное потребление, взлом прибора учета. Программное обеспечение показывает вероятностную оценку (скоринг-балл) некорректного функционирования прибора учета. В случае выявления отклонений система позволяет составить отчет о потреблении электроэнергии. Источником данных для программного обеспечения выступают автоматизированные системы коммерческого учета электроэнергии (далее - АСКУЭ).",
      "1.9. Программный комплекс «Энергосфера» - программа для ЭВМ, право использования которой МТС предоставляет Пользователю на условиях настоящего Соглашения и соответствующей Спецификации. Программное обеспечение представляет собой информационно-измерительная систему, предназначенную для автоматизации учета электроэнергии, тепловой энергии и расхода энергоносителей на предприятиях энергетики, промышленности, жилищно-коммунального хозяйства и других объектах. Программное обеспечение с интегрированной средой разработки, гибко обеспечивающий необходимую функциональность верхнего уровня систем учета различного вида. Приказом Минкомсвязи России от 06.09.2016 № 426 программное обеспечение включено в Единый реестр российских программ для электронных вычислительных машин и баз данных под номером 1691. МТС не является правообладателем программного обеспечения. Право использования на программное обеспечение предоставляется со стороны МТС Пользователю на основании лицензионного договора, заключенного между МТС и правообладателем программного обеспечения.",
      "1.10. ПО - Программное обеспечение «EnergyTool» или Программный комплекс «Энергосфера».",
      "1.11. Спецификация – документ, подписываемый между МТС и Пользователем, подтверждающий Акцепт Соглашения и определяющий параметры использования ПО, срок использования ПО и размер лицензионного вознаграждения МТС.",
      "1.12. Прибор учета - средство измерения, устанавливаемое на одно жилое или нежилое помещение в многоквартирном доме, на жилой дом (часть жилого дома) или домовладение и используемое для определения объемов потребления электрической энергии.",
      "1.13. Пользователь – юридическое лицо или индивидуальный предприниматель, принявший условия Соглашения, имеющий все права для его заключения и доступа к ПО.",
      "1.14. Представитель – лицо, уполномоченное Пользователем на Акцепт Соглашения, создание Личного кабинета, использование ПО и другие необходимые действия и действующее от имени и в интересах Пользователя.",
      "1.15. Сайт - страница в сети Интернет, размещенная по адресу: <br /> <a href='https://energytool.mts.ru/' target='_blank' rel='noreferrer'>https://energytool.mts.ru/</a>",
      "1.16. Территория - территория, в пределах которой допустимо использование ПО, а именно: территория Российской Федерации.",
      "1.17. Условия Единого доступа – Условия Единого доступа к сервисам ПАО «МТС», размещенные в сети Интернет по адресу: <br /> <a href='https://static.ssl.mts.ru/mts_rf/images/usloviya_edinogo_dostupa_k_servisam_MTS.html' target='_blank' rel='noreferrer' > https://static.ssl.mts.ru/mts_rf/images/usloviya_edinogo_dostupa_k_servisam_MTS.html</a>.",
      "1.18. Устройство – устройство, используемое для получения доступа к ПО, в том числе, но не ограничиваясь, мобильные (сотовые) телефоны, смартфоны, коммуникаторы, персональный компьютер, другое переносное компьютерное устройство, включая ноутбук, планшет и т.п.",
      "1.19. Стороны – МТС и Пользователь, упоминаемые вместе.",
      "Иные термины трактуются в соответствии с Условиями Единого доступа, действующим законодательством Российской Федерации и практикой делового оборота в части, непротиворечащей настоящему Соглашению.",
    ],
  },
  {
    title: "2. Предмет Соглашения",
    text: [
      "2.1. МТС в порядке и на условиях, изложенных в Соглашении, обязуется предоставлять Пользователю в рамках подписываемых Сторонами Спецификаций право использования на условиях простой (неисключительной) лицензии на Программное обеспечение «EnergyTool» и/или право использования на Программный комплекс «Энергосфера» на условиях простой (неисключительной) сублицензии на Территории и на весь срок действия Спецификации способами, указанными в п. 7.1 Соглашения.",
      "2.2. Все вопросы приобретения прав доступа в Интернет, покупки и наладки для этого Устройства и программных продуктов решаются Пользователем самостоятельно и не подпадают под действие Соглашения.",
      "2.3. С момента подписания Спецификации МТС также предоставляет Пользователю Программного обеспечения «EnergyTool» возможность доступа к функционалу организации проверок посредством Телеграмм-бота:</br>- Планирование выездов;</br>- Обратная связь по выполненному выезду.",
    ],
  },
  {
    title: "3. Акцепт Соглашения",
    text: [
      "3.1. Для использования ПО Пользователь обязан в порядке, установленном Соглашением, подтвердить, что прочитал, понял, согласен соблюдать настоящее Соглашение, и присоединиться к нему в целом путём Акцепта Соглашения.",
      "3.2. Для Акцепта Соглашения Пользователю необходимо ознакомиться с настоящим Соглашением и подписать с МТС Спецификацию.",
      "3.3. С момента Акцепта Соглашения Пользователем настоящее Соглашение считается заключённым с Пользователем, Пользователь приобретает и осуществляет права и обязанности, предусмотренные Соглашением.",
      "3.4. Право на ПО считается предоставленным с момента подписания Сторонами Спецификации, если иной срок не согласован Сторонами в Спецификации.",
      "3.5. Принимая условия Соглашения, Пользователь соглашается на получение от МТС сообщений по указанным Пользователем в Личном кабинете контактным данным о следующих событиях: изменение ПО, появление специальных предложений, проведение акций, иной информации, а также рекламных/маркетинговых сообщений.",
    ],
  },
  {
    title: "4. Авторизация и Аутентификация",
    text: [
      "4.1. Доступ к ПО предоставляется Пользователю после подписания Сторонами Спецификации. Для получения доступа к функционалу ПО (Авторизации) Пользователю необходимо пройти Аутентификацию в соответствии с Условиями Единого доступа.",
      "4.2. Пользователь несет ответственность за безопасность логина и пароля, а также за все, что будет сделано в рамках ПО после Авторизации под его логином и паролем. Действия, совершаемые в ПО с использованием логина и пароля Пользователя, признаются действиями Пользователя и порождают у такого Пользователя соответствующие права и обязанности.",
      "4.3. Пользователь обязан немедленно уведомить МТС о любом случае неавторизованного доступа под его логином и паролем и/или о любом нарушении безопасности. МТС не отвечает за возможную потерю или порчу данных, которая может произойти из-за нарушения Пользователем положений настоящего пункта Соглашения.",
      "4.4. В случае передачи логина/пароля третьему лицу, всю ответственность несет непосредственно сам Пользователь. За ущерб, причиненный в результате несанкционированного доступа к учетной записи Пользователя, МТС ответственности не несет.",
    ],
  },
  {
    title: "5. Порядок использования Аккаунта",
    text: [
      "5.1. После прохождения Пользователем Авторизации ПО получает доступ к следующим данным Аккаунта: фамилия, имя, отчество, контактный номер и адрес электронной почты Представителя, данные Пользователя (ИНН, ОГРН, фирменное наименование, адрес местонахождения).",
      "5.2. В рамках функционала ПО возможность установки/изменения данных, содержащихся в Аккаунте, не предоставляется.",
      "5.3. В случае блокировки Пользователю доступа к Аккаунту в соответствии с Условиями Единого доступа МТС приостанавливает доступ к ПО до момента возобновления доступа Пользователя к Аккаунту.",
    ],
  },
  {
    title: "6. Идентификация",
    text: [
      "6.1. Идентификация Пользователя осуществляется МТС до подписания Сторонами Спецификации в порядке, установленном МТС.",
    ],
  },
  {
    title: "7. Правила использования ПО",
    text: [
      "7.1. Пользователю разрешается:",
      "7.1.1. получать доступ к ПО дистанционным способом посредством Сайта;",
      "7.1.2. использовать (эксплуатировать) ПО по функциональному назначению с полной реализацией функций ПО, предоставленных его интерфейсом;",
      "7.2. Пользователю запрещается:",
      "7.2.1. модифицировать ПО, в том числе (но не ограничиваясь) изменять, декомпилировать, дизассемблировать, дешифровать и производить иные действия с объектным кодом ПО, имеющие целью получение информации о реализации алгоритмов, используемых в ПО;",
      "7.2.2. создавать производные произведения с использованием ПО;",
      "7.2.3. распространять, копировать или иным образом осуществлять обнародование ПО;",
      "7.2.4. сублицензировать ПО, использовать его для коммерческих целей, передавать или уступать права (полностью или частично), предоставленные Пользователю по Соглашению любому другому лицу;",
      "7.2.5. создавать условия для доступа к ПО и/или использования ПО третьими лицами, не имеющими прав на использование ПО;",
      "7.2.6. Использовать ПО не предусмотренным Соглашением способом.",
    ],
  },
  {
    title: "8. Права и обязанности Сторон",
    text: [
      "8.1.1. использовать в полной мере все функциональные возможности, предоставляемые ПО, согласно условиям Соглашения.",
      "8.1.2. получать необходимую информацию об МТС, условиях предоставления ПО;",
      "8.1.3. в случае возникновения вопросов относительно функционирования ПО в течение срока действия настоящего Соглашения направить МТС обращение по адресу электронной почты ea.support@mts.ru. Обработка запросов происходит в течение 1 дня. Ответ Пользователю предоставляется МТС письменно с указанного адреса электронной почты. Если в обращении Пользователь укажет контактный телефон, то возможен звонок с понедельника по пятницу с 9:00 до 18:00 (время московское).",
      "8.2. Пользователь обязан:",
      "8.2.1. полностью ознакомиться с условиями Соглашения и соблюдать их;",
      "8.2.2. не использовать ПО для совершения каких-либо действий, запрещенные настоящим Соглашением и законодательством Российской Федерации;",
      "8.2.3. не осуществлять какие-либо действия, направленные на дестабилизацию работы ПО, осуществление попыток несанкционированного доступа к ПО, результатам интеллектуальной деятельности, размещенным на нем/доступным через ПО, а также не осуществлять любых иных действий, нарушающих права МТС и/или третьих лиц;",
      "8.2.4. своевременно оплачивать установленный МТС на Сайте размер вознаграждения.",
      "8.2.5. при расторжении Соглашения любой Стороной и по любым основаниям прекратить использование ПО полностью и уничтожить все копии ПО, установленные на Устройстве, включая все их компоненты.",
      "8.2.6. для возможности использования Программного обеспечения «EnergyTool»:",
      "(1) обеспечивать работоспособность АСКУЭ и иных информационных систем в своей зоне ответственности, а также соблюдать следующие требования:</br>- Минимальные требования к данным АСКУЭ для осуществления поиска",
      "isTable",
      "(2) предоставить МТС доступ на чтение к данным потребления электроэнергии абонентов в базе данных СУЭЭ(М), среди которых производится поиск приборов учета с вероятностью потерь, а также проводить инструментальные проверки выявленных посредством ПО некорректно функционирующих приборов учета и производить фиксацию проверок и результата посредством интерфейса ПО (Личного кабинета), Телеграмм-бота или иным способом.",
      "8.2.7. для получения доступа к функционалу Программного комплекса «Энергосфера»:",
      "(1) получить учетные данные для входа в Личный кабинет (логин и пароль) со стороны МТС. МТС в течение 5 (пяти) рабочих дней с момента подписания Спецификации Сторонами направляет Пользователю информацию о порядке получения доступа к Личному кабинету, а также логин и пароль для доступа к Личному кабинету посредством электронной почты.",
      "(2) Пользователь несет ответственность за безопасность логина и пароля, а также за все, что будет сделано в рамках Личного кабинета под его логином и паролем. Действия, совершаемые в Личном кабинете с использованием логина и пароля Пользователя, признаются действиями Пользователя и порождают у такого Пользователя соответствующие права и обязанности.",
      "(3) Пользователь обязан немедленно уведомить МТС о любом ставшем известном Пользователю случае неавторизованного доступа к Личному кабинету под его логином и паролем и/или о любом ставшем известном Пользователю нарушении безопасности. МТС не отвечает за возможную потерю или порчу данных, которая может произойти из-за нарушения Пользователем положений настоящего пункта Соглашения.",
      "(4) В случае передачи Пользователем логина/пароля третьему лицу, всю ответственность несет непосредственно сам Пользователь. За ущерб, причиненный в результате несанкционированного доступа к Личному кабинету, МТС ответственности не несет.",
      "8.3. МТС вправе:",
      "8.3.1. определять состав, структуру и внешний вид ПО, разрешать и ограничивать доступ к ПО в соответствии с Соглашением и законодательством Российской Федерации;",
      "8.3.2. вносить изменения в функционал ПО, в ПО;",
      "8.3.3. решать вопросы, связанные с коммерческим использованием ПО, в частности вопросы о возможности размещения в ПО рекламы, участия в партнерских программах и т.д.",
      "8.3.4. приостанавливать доступ к ПО в порядке, предусмотренном п. 12.1 Соглашения, не отвечая за любой вред, который может быть причинен Пользователю таким действием.",
      "8.3.5. удалять информацию Пользователя из ПО в случае расторжения Соглашения по любому основанию;",
      "8.3.6. привлекать в целях реализации прав и обязанностей в рамках Соглашения любых третьих лиц;",
      "8.3.7. размещать рекламную и/или иную информацию в любом разделе ПО, прерывать работу ПО рекламной информацией, на что Пользователь дает согласие путем Акцепта Соглашения.",
      "8.3.8. Устанавливать возрастные ограничения при доступе к ПО/его разделам, предназначенным для аудитории определенного возраста. Это означает, что лица, не достигшие указанного возраста, обязуются воздержаться от доступа к ПО, о чем МТС может уведомлять Пользователя указанием в ПО знаков информационной продукции или посредством информационных сообщений при попытке Пользователя воспользоваться ПО/его разделами, предназначенными для аудитории определенного возраста;",
      "8.3.9. осуществлять иные права, предусмотренные Соглашением и/или законодательством Российской Федерации.",
      "8.4. МТС обязан:",
      "8.4.1. предоставить Пользователю доступ к ПО в соответствии с условиями Соглашения;",
      "8.4.2. принимать необходимые организационные меры для защиты персональных данных Пользователя при предоставлении ПО и соблюдать иные установленные требования к обеспечению конфиденциальности сведений о Пользователе;",
      "8.4.3. обеспечивать техническую возможность пользования ПО в порядке, определенном Соглашением.",
    ],
    table: [
      ["Поле", "Тип данных", "Описание"],
      [
        "id_pp",
        "(int, null)",
        "Идентификатор канала приём/отдача активной энергии в системе АСКУЭ",
      ],
      ["measure_date", "(datetime, null)", "Дата показаний"],
      ["sum_val", "(float, null)", "Суммарное потребление эл.эн. за сутки"],
      ["day_val", "(float, null)", "Дневное потребление эл.эн."],
      ["night_val", "(float, null)", "Ночное потребление эл.эн."],
      ["address", "(text, null)", "Адрес установки прибора учета"],
      ["SN", "(varchar(50), null)", "Серийный номер прибора учета"],
      ["Consumer_type", "(text, null)", "Категория абонента (физ/юр)"],
    ],
  },
  {
    title: "9. Размер вознаграждения и порядок расчетов",
    text: [
      "9.1. Размер вознаграждения за право использования ПО определяется Сторонами в Спецификации.",
      "9.2. Размер вознаграждения может быть изменен МТС в любой момент в одностороннем порядке с предварительным уведомлением Пользователя за 5 (пять) календарных дней до вступления таких изменений в силу путем размещения соответствующего информационного сообщения на Сайте и/или в ПО и/или путем направления информационного сообщения на адрес электронной почты Представителя.",
      "9.3. Пользователь обязан оплатить размер вознаграждения в следующем в течение 20 (двадцати) календарных дней с даты выставления счёта со стороны МТС.",
      "9.4. Пользователь может оплатить размер вознаграждения следующими способами:",
      "<ul><li><CustomTypography variant='p3_regular' font='comp'>с расчетного счета;</CustomTypography></li><li><CustomTypography variant='p3_regular' font='comp'>со счета указанной им банковской карты.</CustomTypography></li></ul>",
    ],
  },
  {
    title: "10. Интеллектуальная собственность",
    text: [
      "10.1. Все используемые и размещенные в ПО результаты интеллектуальной деятельности, а также само ПО являются интеллектуальной собственностью их законных правообладателей и охраняются законодательством об интеллектуальной собственности Российской Федерации, а также соответствующими международными правовыми конвенциями.",
      "10.2. Любое использование размещенных в ПО объектов интеллектуальной собственности (в том числе элементов визуального оформления ПО, символики, текстов, графических изображений, иллюстраций, фото, видео, программ, музыки, и других объектов) за пределами Соглашения без разрешения МТС или законного правообладателя является незаконным и может послужить причиной для судебного разбирательства и привлечения нарушителей к гражданско-правовой, административной и уголовной ответственности в соответствии с законодательством Российской Федерации.",
      "10.3. Любое использование ПО или результатов интеллектуальной деятельности, размещенных на нем, кроме разрешенного в Соглашении или в случае явно выраженного согласия автора (правообладателя) на такое использование, без предварительного письменного разрешения МТС, надлежащего правообладателя категорически запрещено.",
      "10.4. Пользователь не имеет права воспроизводить, повторять, копировать, продавать, перепродавать, а также использовать любым способом для каких-либо коммерческих целей ПО и (или) какие-либо части содержимого ПО без согласия МТС, или уполномоченных правообладателей результатов интеллектуальной деятельности, размещенных в ПО.",
      "10.5. Пользователь понимает и соглашается с тем, что МТС может удалять или перемещать любые результаты интеллектуальной деятельности, размещенные в ПО, по своему личному усмотрению, по любой причине или без причины, включая без всяких ограничений перемещение или удаление результатов интеллектуальной деятельности ПО.",
    ],
  },
  {
    title: "11. Порядок обработки персональных данных",
    text: [
      "11.1. Принимая условия Соглашения, Пользователь, являющийся индивидуальным предпринимателем и/или Представитель (далее - Субъект ПДн), действуя свободно, своей волей и в своём интересе, даёт конкретное, информированное и сознательное согласие на обработку своих персональных данных МТС, а также подтверждает факт ознакомления с Политикой «Обработка персональных данных в ПАО «МТС».",
      "11.2. Субъект ПДн выражает своё согласие на обработку следующих категорий персональных данных: фамилия, имя, отчество, контактный номер телефона, адрес электронной почты.",
      "11.3. Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, обезличивание, удаление, уничтожение персональных данных, в том числе в информационных системах МТС, и совершение иных действий, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».",
      "11.4. Целями обработки персональных данных являются: формирование Спецификации и исполнение обязательств Сторонами в соответствии с условиями настоящего Соглашения.",
      "11.5. Полученное согласие действует со дня Акцепта Соглашения и до достижения целей обработки персональных данных и досрочно может быть отозвано Субъектом ПДн путем направления МТС письменного заявления по адресу: 109147, г. Москва, ул. Марксистская, д. 4.",
    ],
  },
  {
    title: "12. Ответственность Пользователя",
    text: [
      "12.1. В случае нарушения Пользователем настоящего Соглашения и/или законодательства Российской Федерации, или совершения Пользователем противоправных действий или действий, потенциально угрожающих нарушению прав третьих лиц или их имущественных интересов, МТС вправе приостановить доступ к ПО, не отвечая за любой вред, который может быть причинен Пользователю таким действием. Пользователь обязан устранить нарушение, ставшее основанием для приостановления доступа к ПО, в течение 5 (пяти) дней с момента приостановления МТС доступа к ПО. МТС вправе в одностороннем порядке отказаться от Соглашения в случае, если Пользователь не устранит нарушение в течение вышеуказанного срока.",
      "12.2. Пользователь несет полную личную ответственность за соответствие способов использования им информации, представленной в ПО, нормам российского или международного законодательства (в том числе нормам права об интеллектуальной собственности и о защите информации).",
      "12.3. Пользователь соглашается с тем, что возместит МТС любые убытки, причиненные МТС в связи с использованием Пользователем ПО и/или нарушением Пользователем Соглашения и/или прав (в том числе авторских, смежных, патентных, информационных и любых иных) третьих лиц.",
      "12.4. В случае предъявления третьими лицами претензий к МТС, связанных с использованием Пользователем ПО с нарушением Соглашения, Пользователь обязуется своими силами и за свой счет урегулировать указанные претензии с третьими лицами, оградив МТС от возможных убытков и разбирательств.",
    ],
  },
  {
    title: "13. Ограничение ответственности МТС",
    text: [
      "13.1. ПО предоставляется по принципу «как есть», и МТС не гарантирует, что все его функциональные возможности будут отвечать ожиданиям и целям Пользователя, или смогут быть применимы для конкретной его цели, или что функционирование ПО будет бесперебойным, защищенным или безошибочным. МТС не гарантирует исправление всех дефектов, предотвращение перерывов, вызванных третьими сторонами или несанкционированным доступом третьих лиц.",
      "13.2. МТС не несет ответственности за любые ошибки, упущения, прерывания, удаление, дефекты, задержку в обработке или передаче данных, кражу, уничтожение или неправомерный доступ третьих лиц к результатам интеллектуальной деятельности, размещенным в ПО (доступным через него).",
      "13.3. МТС не несет ответственности перед Пользователем за любые убытки, понесенные Пользователем из-за использования ПО, возникшие, в том числе, вследствие прекращения работы, компьютерного сбоя, проблемами с доступом к сети Интернет или ненадлежащей работой ПО, а также частичную или полную потерю любой информации Пользователя, связанную с использованием или с невозможностью использования ПО, в том числе в случае предварительного уведомления со стороны Пользователя о возможности такого ущерба.",
      "13.4. МТС не несет ответственности за наличие или отсутствие в ПО нужных Пользователю данных.",
      "13.5. МТС не инициирует и не контролирует размещение Пользователем любых объектов в процессе использования ПО, не влияет на содержание и целостность информации/материалов, а также в момент их размещения не знает и не может знать, нарушают ли они охраняемые законом права и интересы третьих лиц, международные договоры и законодательство Российской Федерации.",
      "13.6. МТС не несет ответственности перед Пользователем или любыми третьими лицами за:",
      "13.6.1. действия Пользователя при использовании ПО;",
      "13.6.2. за содержание, законность, достоверность и последствия применения информации, размещаемой в ПО третьими лицами;",
      "13.6.3. за достоверность рекламной информации, размещаемой в ПО третьими лицами.",
      "13.7. Если при использовании ПО будут обнаружены ошибки, МТС предпримет меры для их исправления в максимально короткие сроки. Стороны соглашаются, что точное определение срока устранения ошибок, если такие будут найдены, не может быть установлено.",
      "13.8. ПО может содержать ссылки на другие ресурсы сети Интернет. Пользователь признает и соглашается с тем, что МТС не контролирует и не несет никакой ответственности за доступность этих ресурсов и за их содержание, а также за любые последствия, связанные с использованием этих ресурсов. Любые переходы по ссылкам, осуществляемые Пользователем, последний производит на свой страх и риск.",
      "13.9. В любом случае ответственность МТС по Соглашению в соответствии со статьей 15 ГК РФ ограничивается суммой 10 000 (десять тысяч) рублей. Ответственность может быть возложена на МТС только при наличии в его действиях вины.",
    ],
  },
  {
    title: "14. Порядок разрешения споров",
    text: [
      "14.1. Стороны примут все меры для разрешения возникающих разногласий посредствомпереговоров.",
      "14.2. В случае не достижения соглашения в ходе переговоров заинтересованная Сторона направляет претензию в письменной форме, подписанную уполномоченным лицом.",
      "14.3. Претензия направляется любым из следующих способов:",
      "14.3.1. заказным письмом с уведомлением о вручении по адресу, указанному в ЕГРЮЛ/ЕГРИП;",
      "14.3.2. курьерской доставкой по адресу, указанному в ЕГРЮЛ/ЕГРИП. В этом случае факт получения претензии должен подтверждаться распиской, которая должна содержать наименование документа и дату его получения, а также фамилию, инициалы, должность и подпись лица, получившего данный документ.",
      "14.4. Претензия влечет гражданско-правовые последствия для Стороны, которой направлена, с момента ее доставки указанной Стороне или ее представителю. Такие последствия возникают и в случае, когда претензия не была вручена адресату по зависящим от него обстоятельствам.",
      "14.5. К претензии должны прилагаться документы, обосновывающие предъявленные заинтересованной Стороной требования (в случае их отсутствия у другой Стороны), и документы, подтверждающие полномочия лица, подписавшего претензию. Указанные документы представляются в форме надлежащим образом заверенных копий. Если претензия направлена без документов, подтверждающих полномочия лица, которое ее подписало, то она считается непредъявленной и рассмотрению не подлежит.",
      "14.6. Сторона, которой направлена претензия, обязана рассмотреть полученную претензию и о результатах уведомить заинтересованную Сторону в течение 15 (пятнадцати) рабочих дней со дня получения претензии. Ответ на претензию направляется любым из способов, указанных в п. 14.3 Соглашения.",
      "14.7. В случае не урегулирования разногласий в претензионном порядке, а также в случае неполучения ответа на претензию в течение срока, указанного в п. 14.6 Соглашения, в соответствии со статьей 37 Арбитражного процессуального кодекса РФ Стороны пришли к соглашению об изменении подсудности, установленной статьями 35 и 36 Арбитражного процессуального кодекса РФ, следующим образом.",
      "14.7.1. Если иное не установлено в Соглашении, то иск (заявление), вытекающий из Соглашения, предъявляется в арбитражный суд по месту нахождения истца или по адресу любого из филиалов истца. Выбор между арбитражными судами, которым согласно настоящему пункту подсудно дело, принадлежит истцу.",
      "14.7.2. Если цена иска, вытекающего из Соглашения, превышает 15 000 000 рублей, то такой иск предъявляется в Арбитражный суд города Москвы.",
    ],
  },
  {
    title: "15. Действие, изменение и прекращение Соглашения",
    text: [
      "15.1. Настоящее Соглашение вступает в силу с даты Акцепта Соглашения и действует до момента полного исполнения обязательств Сторон по Соглашению.",
      "15.2. МТС оставляет за собой право по своему личному усмотрению изменять и (или) дополнять Соглашение в любое время с предварительным уведомлением Пользователя на Сайте или в ПО или любым другим способом по выбору МТС. Действующая редакция Соглашения доступна на Сайте.",
      "15.3. Дальнейшее использование ПО после любых подобных изменений означает согласие Пользователя с такими изменениями и/или дополнениями. Если Пользователь не согласен соблюдать настоящее Соглашение, он обязан прекратить использование ПО.",
      "15.4. Пользователь обязуется регулярно просматривать Сайт для ознакомления с действующей редакцией Соглашения и несёт ответственность за последствия такого ознакомления.",
      "15.5. Действие Соглашения распространяется на все последующие обновления/новые версии ПО. Устанавливая обновление/новую версию ПО, Пользователь соглашается с настоящим Соглашением, если обновление/установка новой версии ПО не сопровождается новой версией Соглашения.",
      "15.6. Соглашение может быть расторгнуто досрочно:",
      "15.6.1. По взаимному соглашению Сторон;",
      "15.6.2. По инициативе МТС в одностороннем порядке с предварительным уведомлением Пользователя за 10 (десять) календарных дней путем размещения соответствующего информационного сообщения на Сайте или в ПО (в Личном кабинете).",
      "15.6.3. По инициативе Пользователя в одностороннем порядке путём удаления ПО с Устройства.",
    ],
  },
  {
    title: "16. Антикоррупционная оговорка",
    text: [
      "16.1. Пользователь обязуется в рамках исполнения настоящего Соглашения соблюдать требования применимого антикоррупционного законодательства и не предпринимать никаких действий, которые могут нарушить нормы антикоррупционного законодательства или стать причиной такого нарушения Пользователем, в том числе не требовать, не получать, не предлагать, не санкционировать, не обещать и не совершать незаконные платежи напрямую, через третьих лиц или в качестве посредника, включая (но не ограничиваясь) взятки в денежной или любой иной форме, каким-либо физическим или юридическим лицам, включая (но не ограничиваясь) коммерческим организациям, органам власти и самоуправления, государственным служащим, частным компаниям и их представителям.",
      "16.2. В случае нарушения Пользователем изложенных выше антикоррупционных обязательств, МТС вправе в одностороннем порядке приостановить исполнение своих обязательств по Соглашению до устранения причин такого нарушения или отказаться от исполнения Соглашения, направив об этом письменное уведомление.",
    ],
  },
  {
    title: "17. Заключительные положения",
    text: [
      "17.1. Настоящее Соглашение и отношения между МТС и Пользователем регулируются и толкуются в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации.",
      "17.2. Если по тем или иным причинам какие-либо из условий Соглашения являются недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных условий Соглашения.",
      "17.3. В течение срока действия Соглашения, а также по истечении срока его действия Стороны обязуются не разглашать и не использовать в своих интересах, равно как и в интересах любых третьих лиц, любую конфиденциальную информацию, в том числе деловую, коммерческую, техническую и иную информацию, которая не может быть известна Сторонам из общедоступных источников, переданную одной Стороной другой Стороне и которая стала известной Сторонам в связи с заключением и исполнением Соглашения.",
    ],
  },
];
