import {
  ROUTE_ASKUE_EQUIPMENT,
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_OPP,
  ROUTE_COMMERCIAL_LOSSES_PAGE,
  ROUTE_MINING_PAGE,
} from "stream-constants/route-constants";
import { IAccordionCustom } from "interface/common";
import { IAdditInfoProp } from "components/additional-info-list/additional-info";

import productImg1 from "./images/product-1.png";
import productImg3 from "./images/product-3.png";
import advantageImg1 from "./images/advantage-1.svg";
import advantageImg2 from "./images/advantage-2.svg";
import advantageImg7 from "./images/advantage-7.svg";

export const advantageData: Array<{ icon: any; title: string; description: string }> = [
  {
    icon: advantageImg2,
    title: "Экономично",
    description: "Платите только за подключенное количество счетчиков",
  },
  {
    icon: advantageImg7,
    title: "Просто",
    description:
      "Решение масштабируется без существенных дополнительных затрат - просто установите новый счетчик",
  },
  {
    icon: advantageImg1,
    title: "Надежно",
    description: "Обеспечим надежный сбор данных с ваших объектов",
  },
];

export const productsCard: Array<{
  title: string;
  description?: string;
  nameBtn: string;
  isCallbackBlock?: boolean;
  img?: string;
  link?: string;
}> = [
  {
    title: "Поиск коммерческих потерь",
    description:
      "Программная платформа позволит быстро и\u00A0своевременно обнаружить точки коммерческих потерь и\u00A0выявить конкретные участки сети, на\u00A0которых происходит хищение",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg1,
    link: ROUTE_COMMERCIAL_LOSSES_PAGE,
  },
  {
    title: "Выявление майнинга",
    description:
      "Наше решение поможет своевременно обнаружить подозрительную активность потенциальных майнинговых\u00A0ферм с\u00A0указанием их\u00A0расположения",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg3,
    link: ROUTE_MINING_PAGE,
  },
];

export const energySphereList: Array<{
  item: string;
  sublist?: Array<string>;
}> = [
  { item: "Удаленное управление прибором учета" },
  { item: "Интеграция с системами учета других производителей" },
  { item: "Создание и поддержка личных кабинетов конечных потребителей" },
  {
    item: "Поддержка большого количества отечественных и зарубежных приборов учета, УСПД и концентраторов (более 300 типов)",
  },
  {
    item: "Отчеты по форме клиента – возможность представления собранной телеметрии в нужном клиенту формате",
  },
];

export const accordionInfo: IAccordionCustom[] = [
  {
    title: "Соответствует ли система требованиям ФЗ №522 и ПП №890?",
    text: "Да. Наше решение полностью соответствует законодательству РФ.",
  },
  {
    title: "Где хранятся данные с приборов учета?",
    text: "Данные после опроса приборов учета хранятся на географически разнесенных серверах в ЦОДах МТС.",
  },
  {
    title: "Доступен ли пробный период?",
    text: "Доступен. Пробный период предоставляется на 1 месяц.",
  },
];

export const seo = {
  title: "АСКУЭ в облаке - Автоматическая Система Коммерческого Учёта Электроэнергии",
  description:
    "АСКУЭ в облаке. Программный комплекс «Энергосфера» установлен на облачных серверах ПАО МТС и предназначен для автоматизации процессов по сбору данных и управлению систем коммерческого и технического учета различных видов энергоресурсов: электроэнергии, тепловой энергии, расхода воды, пара, газа и др.",
};

export const additionalInfo: IAdditInfoProp[] = [
  {
    title: "Какие возможности предоставляет платформа",
    icon: "check",
    link: `/${ROUTE_ASKUE_OPP}`,
  },
  {
    title: "Поддерживаемые виды связи\u00A0и\u00A0оборудование",
    icon: "router",
    link: `/${ROUTE_ASKUE_EQUIPMENT}`,
  },
  {
    title: "Стоимость внедрения\u00A0АСКУЭ",
    icon: "ruble",
    link: `/${ROUTE_ASKUE_INTEGRATION}`,
  },
];
