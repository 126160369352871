export enum HTTPStatusCode {
  OK_200 = 200,
  UNAUTHORIZED_401 = 401,
  NOT_FOUND_404 = 404,
}

export enum FIELD_NAMES {
  ADRESS_NORM = "adress_norm",
  ADRESS_TOO = "adress_too",
  CITY = "city",
  CITY_DISTRICT = "city_district",
  FACTORY_COUNT_NUM = "factory_count_num",
  FL_UL = "fl_ul",
  GEO_LAT = "geo_lat",
  GEO_LON = "geo_lon",
  HOUSE = "house",
  ID_POINT = "id_point",
  ID_PP = "id_pp",
  ID_PP_DT = "id_pp_dt",
  ID_TP = "id_tp",
  POSTAL_CODE = "postal_code",
  PRIEM = "priem",
  REGION = "region",
  SKOR_POINT = "skor_point",
  SOURCE_NAME = "source_name",
  STREET = "street",
  TELEBOT_REQUEST_DT = "telebot_request_dt",
  TELEBOT_REQUEST_DT_TEXT = "telebot_request_dt_text",
  TELEBOT_REQUEST_RESULT = "telebot_request_result",
  TYPE_PU = "type_pu",
}

export enum DEPARTURE_RESULT {
  NORMAL = "Нормальный",
  IN_WORK = "В работе",
  BREAKING = "Взлом",
  SUSPICION = "Подозрение",
}

export const EMPTY_CELL_VALUE = "-";
export const PHONE_MASK = "+7 (999) 999-99-99";
export const INN_MASK = "9999999999";

export const CURRENT_YEAR = new Date().getFullYear();
