import styled from "@emotion/styled";
import { device } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SSection = styled.section<{ greyBg?: boolean }>`
  padding: 80px 0;
  width: 100%;

  ${(props) =>
    props.greyBg
      ? `
		background-color: ${props.theme.mtsColor.background.primary.lightMode};
  		`
      : ``}

  @media ${device.miniTabletNeg} {
    padding: 64px 0;
  }
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const SAdvantage = styled.section`
  padding: 80px 0;
  width: 100%;

  @media ${device.miniTabletNeg} {
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 32px;
  row-gap: 32px;

  @media ${device.tabletNeg} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    rowgap: 16px;
  }

  @media ${device.miniTabletNeg} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SPromoTitle = styled.h2`
  text-align: left;
  margin-bottom: 16px;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.red};
    text-transform: uppercase;
    font-size: 100px;
    text-align: left;

    @media ${device.miniTabletNeg} {
      font-size: 80px;
    }
  }
`;

export const SPromoText = styled.p`
  text-align: left;
  margin-bottom: 24px;
  max-width: 667px;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};

    @media ${device.miniTabletNeg} {
      font-size: 20px;
    }
  }
`;

export const SPromoSubText = styled.p`
  text-align: left;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};

    @media ${device.miniTabletNeg} {
      font-size: 16px;
    }
  }
`;

export const InfoBody = styled.div`
  & > p {
    text-align: left;
  }
  & a {
    text-decoration: none;

    & .MuiTypography-root {
      color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    }
  }
`;
