import { CustomTypography } from "components/customTypography/custom-typography";
import { SItem } from "./styles";

interface MTSListProps {
  items: any;
  styles?: React.CSSProperties;
  fontWeight?: string | number;
}

export const MTSList = (props: MTSListProps) => {
  const { items, styles } = props;

  return (
    <ul>
      {items.map((elem: any, index: number) => {
        return (
          <SItem key={index}>
            <CustomTypography variant="h4_regular" styles={{ ...styles }} font="comp">
              {elem.item}
            </CustomTypography>
          </SItem>
        );
      })}
    </ul>
  );
};
