import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Sort = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4493_3140)">
        <path
          d="M7.84716 4.60543C7.95167 4.74396 7.99924 4.89267 8.00001 5.03515C8.002 5.40399 7.69033 5.73101 7.24109 5.73101C6.97339 5.73101 6.72214 5.60316 6.55974 5.39034C6.3877 5.16492 6.17834 4.90394 5.93756 4.59579C5.87042 4.50987 5.80809 4.43018 5.74996 4.35611L5.74997 12.75C5.74997 13.1642 5.41418 13.5 4.99997 13.5C4.58575 13.5 4.24997 13.1642 4.24997 12.75L4.24996 4.35628C4.19187 4.43029 4.12958 4.50994 4.0625 4.59579C3.82171 4.90394 3.61232 5.16492 3.44028 5.39034C3.27788 5.60316 3.02663 5.73101 2.75893 5.73101C2.30969 5.73101 1.99802 5.40399 2.00001 5.03515C2.00078 4.89267 2.04835 4.74396 2.15286 4.60543C2.35917 4.332 2.59986 4.02398 2.87472 3.67223C3.60783 2.73402 3.98023 2.26489 4.4279 2.10127C4.61263 2.03376 4.80633 2 5.00002 2C5.19372 2 5.38742 2.03376 5.57215 2.10127C6.01983 2.26489 6.39217 2.734 7.12529 3.67221C7.40015 4.02396 7.64085 4.33201 7.84716 4.60543Z"
          fill="#969FA8"
        />
        <path
          d="M13.2417 10.2463C13.6915 10.2463 14.0041 10.5726 14 10.9415C13.9985 11.0807 13.9519 11.226 13.8506 11.3621C13.6431 11.641 13.399 11.9567 13.1184 12.3195C12.3912 13.2599 12.0259 13.7302 11.5819 13.8965C11.205 14.0376 10.7899 14.0376 10.413 13.8965C9.96902 13.7302 9.60893 13.26 8.8817 12.3196C8.60119 11.9568 8.35692 11.6409 8.14943 11.3621C8.04815 11.226 8.00155 11.0807 8.00001 10.9415C7.99592 10.5726 8.30853 10.2463 8.75835 10.2463C9.0301 10.2463 9.28483 10.3772 9.44843 10.5942C9.62015 10.822 9.8212 11.087 10.0648 11.402C10.131 11.4876 10.1926 11.5672 10.25 11.6411L10.25 3.25C10.25 2.83579 10.5858 2.5 11 2.5C11.4142 2.5 11.75 2.83579 11.75 3.25L11.75 11.6345C11.806 11.5625 11.8658 11.4851 11.9301 11.402C12.1737 11.087 12.3799 10.822 12.5516 10.5942C12.7152 10.3772 12.9699 10.2463 13.2417 10.2463Z"
          fill="#969FA8"
        />
      </g>
      <defs>
        <clipPath id="clip0_4493_3140">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Sort;
