import { useMemo, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { useBreakpoint } from "hooks/useBreakpoint";
import { SImg, SThumb } from "./styles";

const makeImgs = (photos: string[]) =>
  photos.map((a, i) => (
    <SImg
      className="item"
      style={{
        background: `url(${a}) center center / contain no-repeat`,
      }}
      data-value={i + 1}
      key={i}
    />
  ));

const thumbItems = (imagesArr, [setThumbIndex, setThumbAnimation]) => {
  return imagesArr.map((imgUrl, i) => (
    <SThumb
      onClick={() => {
        setThumbIndex(i);
        setThumbAnimation(true);
      }}
      style={{ backgroundImage: `url(${imgUrl})` }}
      data-value={i + 1}
      key={i}
      className="thumb"
    />
  ));
};

export const PhotosSlider = ({ photos }) => {
  const { isTablet } = useBreakpoint();
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs] = useState(thumbItems(photos, [setThumbIndex, setThumbAnimation]));

  const items = useMemo(() => makeImgs(photos), [photos]);

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === "action") {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return (
    <>
      <AliceCarousel
        activeIndex={mainIndex}
        animationType="slide"
        animationDuration={400}
        disableDotsControls={!isTablet()}
        disableButtonsControls
        items={items}
        mouseTracking={!thumbAnimation}
        onSlideChange={syncMainBeforeChange}
        onSlideChanged={syncMainAfterChange}
        touchTracking={!thumbAnimation}
      />
      {isTablet() ? null : (
        <div className="thumbs">
          <AliceCarousel
            activeIndex={thumbIndex}
            animationType="slide"
            autoWidth
            disableDotsControls
            disableButtonsControls
            items={thumbs}
            mouseTracking={false}
            onSlideChanged={syncThumbs}
            touchTracking={!mainAnimation}
          />
        </div>
      )}
    </>
  );
};
