import styled from "@emotion/styled";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > section {
    width: 100%;
  }

  & > article {
    width: 100%;
    min-height: 100vh;
  }
`;

export const SContainer = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 88px 0 40px;

  & a {
    text-decoration: none;
  }
`;

export const SBlockLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

export const STitle = styled.span`
  margin-bottom: 24px;

  & .MuiTypography-root {
    font-size: 150px;
    line-height: 1;
    color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const SText = styled.div`
  margin-bottom: 48px;

  & .MuiTypography-root {
    display: block;
    max-width: 832px;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;
