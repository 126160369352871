import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import theme from "theme";

import { login } from "services/auth";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ContextSettings } from "context/context-settings";
import { ROUTE_SHOP_CHECKOUT } from "stream-constants/route-constants";
import { CustomTypography } from "components/customTypography/custom-typography";
import { MTSInput } from "components/mts-input";
import { SCategory, SPromoTags } from "../shopCard/styles";
import { formatPrice } from "../shopCard/utils";
import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { ICheckCartRes, IShopProduct } from "interface/common";
import { emptyPrice, emptyPriceDisclaim, promoCategory, renderPriceProduct } from "../const";
import { SClose, SCont, SInfo, SPrice, SPriceWrap, STotal } from "./styles";

interface IProp {
  product: IShopProduct | ICheckCartRes;
  quantity: number;
  type?: "cart" | "service";
}

export const CartProductCard: React.FC<IProp> = ({ product, quantity, type = "cart" }) => {
  const { isDesktop } = useBreakpoint();
  const { setCartAmount, removeFromCart } = useContext(ContextSettings);

  const renderPrice = () =>
    type === "cart" || product.price !== -1 ? (
      <SPriceWrap>
        <Icon.Minus onClick={() => setCartAmount({ id: product.id, action: "decr" })} />
        <MTSInput
          type="tel"
          placeholder="0"
          onChange={(event) =>
            setCartAmount({ id: product.id, quant: parseInt(event.target.value ?? "") || 1 })
          }
          value={quantity || ""}
          maxLength={4}
          style={{ textAlign: "center" }}
        />
        <Icon.Plus onClick={() => setCartAmount({ id: product.id, action: "inc" })} />
        <SPrice>
          <CustomTypography variant="h3_bold" font="comp">
            {formatPrice(product.price * quantity)} {type === "service" ? "/месяц" : null}
          </CustomTypography>
        </SPrice>
      </SPriceWrap>
    ) : (
      <div>
        <SPrice>
          <CustomTypography variant="h3_bold" font="comp">
            {renderPriceProduct(
              product.price,
              Boolean("promo_disclamer" in product && product?.promo_disclamer),
            )}
          </CustomTypography>
        </SPrice>
      </div>
    );

  return (
    <SCont>
      {type !== "service" ? <img src={product.image_url} alt={product.name} /> : null}
      <div>
        <SCategory>
          <CustomTypography variant="c1_regular" font="comp">
            {product.category}
          </CustomTypography>
        </SCategory>
        <div>
          <CustomTypography variant="h4_medium" font="comp">
            {product.name}
          </CustomTypography>
          <Spacer value={isDesktop() ? "0px" : "8px"} />
        </div>
        {!isEmpty((product as IShopProduct).promotions) ? (
          <>
            <SPromoTags>
              {(product as IShopProduct).promotions.map((promo) => (
                <div key={promo.promotion_id}>
                  <CustomTypography font="wide">{promo.promotion_bubble}</CustomTypography>
                </div>
              ))}
            </SPromoTags>
            <Spacer value={isDesktop() ? "0px" : "12px"} />
          </>
        ) : null}
        {isDesktop() ? null : renderPrice()}
      </div>
      {isDesktop() ? renderPrice() : null}
      <SClose onClick={() => removeFromCart(product.id)}>
        <Icon.Close />
      </SClose>
    </SCont>
  );
};

interface IPropService {
  product: ICheckCartRes;
  type?: "cartService";
}

export const CartServiceCard: React.FC<IPropService> = ({ product }) => {
  const { isDesktop } = useBreakpoint();

  const renderPrice = () => (
    <SPriceWrap>
      <SPrice>
        <CustomTypography variant="h3_bold" font="comp">
          0₽*
        </CustomTypography>
      </SPrice>
    </SPriceWrap>
  );
  return (
    <SCont>
      <div>
        <div>
          <CustomTypography variant="h4_medium" font="comp">
            {product.name}
          </CustomTypography>
        </div>
        <div>
          <CustomTypography variant="p4_medium" font="comp">
            {product.type}
          </CustomTypography>
          <Spacer value={isDesktop() ? "0px" : "8px"} />
        </div>
        <div>
          <CustomTypography variant="c1_regular" font="comp">
            {product.promo_desc}
          </CustomTypography>
          <Spacer value={isDesktop() ? "0px" : "12px"} />
        </div>
        {isDesktop() ? null : renderPrice()}
      </div>
      {isDesktop() ? renderPrice() : null}
    </SCont>
  );
};

export const Total = ({ totalPrice, combo }) => {
  const navigate = useNavigate();
  const { cart, isAuthorized } = useContext(ContextSettings);
  const { isDesktop } = useBreakpoint();
  return (
    <STotal>
      <section>
        <CustomTypography
          variant="c1_regular"
          styles={{ color: theme.mtsColor.text.secondary.lightMode }}
        >
          ИТОГО
        </CustomTypography>
        <SPrice variant="h4_bold">{totalPrice}</SPrice>
      </section>
      <MTSButton
        size={isDesktop() ? "L" : "S"}
        variant="primary"
        onClick={
          isAuthorized
            ? () =>
                navigate(`/${ROUTE_SHOP_CHECKOUT}`, {
                  state: combo
                    .map((product) => ({
                      id: product.id,
                      name: product.name,
                      category: (product as any).promo_desc ? promoCategory : product.category,
                      qty: cart[product.id]?.qty || 1,
                      price: product.price,
                      price_type: product.price_type ?? undefined,
                    }))
                    .filter((a) => a !== null),
                })
            : (e) => login(e, "cart")
        }
      >
        Перейти к оформлению
      </MTSButton>
      {!isAuthorized ? (
        <>
          <Spacer value={isDesktop() ? "24px" : "12px"} />
          <SInfo variant="c2_regular" font="comp">
            Для оформления товара необходимо пройти авторизацию
          </SInfo>
        </>
      ) : null}
      {totalPrice.includes(emptyPrice) ? (
        <>
          <Spacer value={isDesktop() ? "24px" : "12px"} />
          <SInfo variant="c2_regular" font="comp">
            {emptyPriceDisclaim}
          </SInfo>
        </>
      ) : null}
    </STotal>
  );
};
