import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { MTSButton } from "components/mts-button/mts-button";
import { ROUTE_MAIN_PAGE } from "stream-constants/route-constants";
import { Logo } from "components/logo/logo.component";
import { CustomTypography } from "components/customTypography/custom-typography";

import { SBlockLogo, SContainer, SMain, SText, STitle } from "./styles";

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404</title>
        <meta name="prerender-status" content="404" />
      </Helmet>
      <SMain>
        <section>
          <article>
            <SContainer>
              <div>
                <SBlockLogo>
                  <Logo />
                </SBlockLogo>
                <STitle>
                  <CustomTypography variant="promo1_black">404</CustomTypography>
                </STitle>
                <SText>
                  <CustomTypography variant="h4_regular">
                    Страница, которую вы запрашиваете, не существует. Возможно она устарела, была
                    удалена, или был введен неверный адрес в адресной строке
                  </CustomTypography>
                </SText>
                <div>
                  <Link to={ROUTE_MAIN_PAGE}>
                    <MTSButton size="L" onClick={() => {}}>
                      Перейти на главную
                    </MTSButton>
                  </Link>
                </div>
              </div>
            </SContainer>
          </article>
        </section>
      </SMain>
    </>
  );
};
