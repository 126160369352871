import { FC, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { LIST_ROLES } from "utils/roles";
import { ContextSettings, ICart, ISetCart } from "./context-settings";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

export const ContextProvider: FC = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [roles, setRoles] = useState<LIST_ROLES[]>([]);
  const [isOpenFormConsultation, changeStateFormConsultation] = useState(false);
  const [cart, setCart] = useState<ICart>({});

  const setCartAmount = ({ id, quant, action }: ISetCart) =>
    setCart((s) => {
      let res;
      if (!action && quant) {
        res = {
          ...s,
          [id]: {
            qty: quant,
          },
        };
        StorageAPI.save(EStorageKeys.SHOP_ITEMS, JSON.stringify(res));
        return res;
      }

      if (action) {
        if (action === "inc") {
          if (s.hasOwnProperty(id)) {
            res = {
              ...s,
              [id]: {
                qty: s[id].qty + 1,
              },
            };
          } else {
            res = {
              ...s,
              [id]: {
                qty: 1,
              },
            };
          }
          StorageAPI.save(EStorageKeys.SHOP_ITEMS, JSON.stringify(res));
          return res;
        } else {
          const copy = { ...s };
          if (s[id].qty <= 1) {
            delete copy[id];
            res = copy;
          } else {
            res = {
              ...s,
              [id]: {
                qty: s[id].qty - 1,
              },
            };
          }
          StorageAPI.save(EStorageKeys.SHOP_ITEMS, JSON.stringify(res));
          return res;
        }
      }
    });

  const removeFromCart = (val: number) =>
    setCart((s) => {
      if (s.hasOwnProperty(val)) {
        let newObj = Object.assign({}, s);
        delete newObj[val];
        StorageAPI.save(EStorageKeys.SHOP_ITEMS, JSON.stringify(newObj));
        return newObj;
      }
      return s;
    });

  const clearCart = () => {
    StorageAPI.remove(EStorageKeys.SHOP_ITEMS);
    setCart({});
  };

  useEffect(() => {
    const localItems = JSON.parse(StorageAPI.get(EStorageKeys.SHOP_ITEMS) || "{}") ?? {};
    if (localItems && !isEmpty(localItems)) {
      setCart(localItems);
    } else {
      StorageAPI.save(EStorageKeys.SHOP_ITEMS, JSON.stringify({}));
    }
  }, []);

  const value = {
    isAuthorized,
    setIsAuthorized,
    roles,
    setRoles,
    isOpenFormConsultation,
    changeStateFormConsultation,
    cart,
    removeFromCart,
    setCartAmount,
    clearCart,
  };

  return <ContextSettings.Provider value={value}>{children}</ContextSettings.Provider>;
};

ContextProvider.displayName = "ContextProvider";
