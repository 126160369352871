import * as yup from "yup";

import validationMethod from "../validation-method";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";

export const FALLBACK_MSG = "Попробуйте зарегистрироваться позже";

export const schema = yup.object().shape({
  phone: yup
    .mixed()
    .test("correctPhone", INCORRECT, validationMethod.correctPhone)
    .test("requiredPhone", REQUIRED, validationMethod.requiredPhone),
  email: yup
    .mixed()
    .test("requiredEmail", REQUIRED, validationMethod.requiredEmail)
    .test("correctEmail", INCORRECT, validationMethod.correctEmail),
  userName: yup
    .mixed()
    .test("requiredUserName", REQUIRED, validationMethod.requiredUserName)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
});
