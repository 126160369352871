import styled from "@emotion/styled";
import { device } from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  & > section:not(:first-of-type) {
    width: 100%;
    padding: 80px 0;
  }
`;

export const SContainer = styled.section`
  width: 100%;
  max-width: 1304px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const SInfoProd = styled.section`
  background-color: #f5f5f5;

  & > section {
    text-align: left;
  }
`;

export const STop = styled.div`
  max-width: 724px;

  & h2 {
    max-width: inherit;
  }

  & > .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SInfo = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 941px;
    padding: 12px 24px;
    background-color: ${(props) => props.theme.mtsColor.background.inverted.darkMode};

    @media ${device.miniTabletPlus} {
      flex-direction: row;
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    & div {
      display: flex;

      &:first-child {
        width: inherit;

        @media ${device.miniTabletPlus} {
          width: 40%;
        }
      }

      &:last-of-type {
        width: inherit;

        @media ${device.miniTabletPlus} {
          width: 60%;
        }
      }
    }
  }
`;

export const STariffs = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 24px;
  flex-wrap: wrap;

  @media ${device.tabletPlus} {
    flex-direction: row;
  }
`;

export const STariffCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 307px;
  padding: 24px;
  text-align: left;
  border: 1px solid ${(props) => props.theme.mtsColor.grey.greyDirty};
  border-radius: 8px;

  & h3 {
    min-height: inherit;
    margin-bottom: 24px;

    @media ${device.tabletPlus} {
      min-height: 80px;
    }

    & span {
      display: block;

      &:first-of-type {
        max-width: inherit;

        @media ${device.tabletPlus} {
          max-width: 190px;
        }
      }
    }
  }

  & > div:first-of-type {
    margin-bottom: 48px;

    @media ${device.tabletPlus} {
      margin-bottom: 83px;
    }

    & > span {
      display: block;
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  & > div:last-of-type {
    & button {
      @media ${device.mobilePlus} {
        width: 320px;
      }

      @media ${device.tabletPlus} {
        width: 100%;
      }
    }
  }
`;

export const SPrice = styled.div`
  margin-bottom: 24px;
`;

export const SAnnotation = styled.div`
  & > span {
    display: block;
    text-align: left;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SSectionProd = styled.div`
  position: relative;
  width: 100%;
  top: -62px;
  margin-bottom: -62px;
`;

export const SProducts = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${device.tabletPlus} {
    flex-direction: row;
  }
`;

export const SProdCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 150px;
  padding: 32px;
  text-align: left;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 16px 0px rgba(0, 0, 0, 0.08);

  & > h3 {
    margin-bottom: 16px;
    flex: 1;

    & > span {
      font-size: 18px;
      @media ${device.mobilePlus} {
        font-size: 24px;
      }
    }
  }

  & > div {
    min-height: 47px;
    margin-bottom: 13px;

    & span {
      display: block;
      color: ${(props) => props.theme.mtsColor.red};
    }
  }

  & > a {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.mtsColor.text.headline.lightMode};
    text-decoration: none;

    & > svg {
      height: 16px;
      margin-left: 8px;
    }
  }
`;
