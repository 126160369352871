import { isEmpty } from "lodash";

import { useBreakpoint } from "hooks/useBreakpoint";
import { Checkbox } from "components/checkbox/checkbox";
import { Spacer } from "components/spacer/spacer.component";
import { FilterAccordion } from "./filter-accordion/filter-accordion";
import { SBody, SBoxWrap, STitle } from "../styles";

interface IProps {
  isAllChecked: boolean;
  toggleAll: Function;
  toggleSingle: Function;
  currFilters: IKek;
  filterKey: string;
  targetArray?: {
    id: number;
    name: string | null;
    friendlyName?: string;
  }[];
  titleByKey: { [key: string]: string };
  isShowMobView?: boolean;
}
export type IKek = { [key: string]: string[] };

export const ChxRenderer: React.FC<IProps> = ({
  targetArray,
  filterKey,
  isAllChecked,
  toggleAll,
  toggleSingle,
  currFilters,
  titleByKey,
  isShowMobView = false,
}) => {
  const { isTablet } = useBreakpoint();

  const renderCommon = () => (
    <>
      <Checkbox
        id={`all${filterKey}`}
        label="Выбрать все"
        name={`all${filterKey}`}
        onChange={() => toggleAll(filterKey, targetArray, isAllChecked)}
        checked={isAllChecked}
      />
      {targetArray?.map((item: any) => {
        return (
          <Checkbox
            key={`${item.id}_${filterKey}`}
            label={item.friendlyName ?? item.name}
            name={`${item.id}_${filterKey}`}
            checked={currFilters[filterKey]?.includes(item.name) || false}
            id={`${item.id}_${filterKey}`}
            onChange={() => toggleSingle(filterKey, item.name)}
          />
        );
      })}
    </>
  );

  return isTablet() && isShowMobView ? (
    <div style={{ margin: "0 0 48px 0" }}>
      <FilterAccordion
        name={filterKey}
        title={titleByKey[filterKey]}
        amountChecked={currFilters[filterKey]?.length ?? 0}
      >
        {targetArray && !isEmpty(targetArray) ? <>{renderCommon()}</> : null}
      </FilterAccordion>
    </div>
  ) : (
    <SBoxWrap>
      <STitle variant="h4_medium" font="comp">
        {titleByKey[filterKey]}
      </STitle>
      <Spacer value="12px" />
      {targetArray && !isEmpty(targetArray) ? <SBody>{renderCommon()}</SBody> : null}
    </SBoxWrap>
  );
};
