import { toast } from "react-toastify";

import { Toast } from "components/toast/toast";
import { USER_IP, infoKeysFl, infoKeysUl } from "./const";
import { defaultValInformation } from "./validation-schema";

export const splitAdressIntoParts = (urAdress: string, flAdress: string) => {
  const arrayUrAdress = urAdress.split(",");
  const arrayPostAdress = flAdress.split(",");

  return {
    ur_city: (arrayUrAdress[0] ?? "").trim(),
    ur_street: (arrayUrAdress[1] ?? "").trim(),
    ur_house: (arrayUrAdress[2] ?? "").trim(),
    ur_flat: (arrayUrAdress[3] ?? "").trim(),
    ur_index: (arrayUrAdress[4] ?? "").trim(),
    post_city: (arrayPostAdress[0] ?? "").trim(),
    post_street: (arrayPostAdress[1] ?? "").trim(),
    post_house: (arrayPostAdress[2] ?? "").trim(),
    post_flat: (arrayPostAdress[3] ?? "").trim(),
    post_index: (arrayPostAdress[4] ?? "").trim(),
  };
};

export const composeSubmitData = (sbmtData, isParam: boolean, isFileReplaced: boolean) => {
  let newRes = new FormData();
  const source = isParam ? infoKeysUl : infoKeysFl;
  const sourceWithFile = isFileReplaced ? source : source.filter((a) => a !== "file");

  const filteredInfo = Object.fromEntries(
    Object.entries(sbmtData).filter((m) => sourceWithFile.includes(m[0])),
  );

  for (var key in filteredInfo) {
    newRes.append(key, sbmtData[key]);
  }

  return newRes;
};

export const cutNameFilefromUrl = (str: string) => (str ? str.split("/").pop() : "");

export const composeUserDefault = (data, user) => {
  return {
    ...defaultValInformation,
    ...data,
    ...splitAdressIntoParts(data?.ur_adress ?? "", data?.post_adress ?? ""),
    type_user: user ?? USER_IP,
    file: data?.document_url
      ? {
          name: cutNameFilefromUrl(data.document_url),
          size: data?.document_size,
        }
      : null,
  };
};

export const toastError = (e) => {
  toast(<Toast title={e?.message ?? "Информация не обновлена"} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastSucc = () => {
  toast(<Toast title="Информация обновлена" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
