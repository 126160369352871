import * as yup from "yup";
import validationMethod from "../validation-method";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
const INC_SN = "Должен содержать минимум 5 цифр";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";
export const SUCCESS_DESCR = "Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00";

export const schema = yup.object().shape({
  userName: yup
    .string()
    .required(REQUIRED)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
  phone: yup
    .string()
    .required(REQUIRED)
    .test("correctPhone", INCORRECT, validationMethod.correctPhone),
  sn: yup.array().of(
    yup.object().shape({
      name: yup.string().required(REQUIRED).test("correctSn", INC_SN, validationMethod.correctSn),
    }),
  ),
  agree: yup.string().test("a", (v) => (v && v === "true") as any),
});

export const defaultValues = {
  userName: "",
  phone: "",
  sn: [{ name: null }],
  agree: undefined,
};
