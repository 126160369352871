import Icon from "components/icons";
import { SCont, SLabel } from "./styles";
import { CustomWidthTooltip } from "components/tooltip/tooltip";

interface InputLabelProps {
  label?: string;
  id?: string;
  disabled?: boolean;
  hasError?: boolean;
  tooltip?: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  label,
  id,
  disabled,
  hasError,
  tooltip,
}) => {
  return (
    <SCont id={id}>
      <SLabel variant="p4_regular" hasError={hasError} font="comp">
        {label}
        {tooltip ? (
          <CustomWidthTooltip
            title={<div style={{ fontWeight: 400 }}>{tooltip}</div>}
            arrow
            placement="right"
          >
            <span>
              <Icon.Info sx={{ width: 16, height: 16, margin: "0 0 0 8px", alignSelf: "end" }} />
            </span>
          </CustomWidthTooltip>
        ) : null}
      </SLabel>
      {disabled ? <Icon.Lock width={16} height={16} className="label__icon" /> : null}
    </SCont>
  );
};
