import styled from "@emotion/styled";
import { device } from "theme";

export const SBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: white;
  text-align: left;

  & > div {
    margin: 0 auto;
    padding: 48px 20px 0 20px;
    max-width: 1304px;
    width: 100%;
    height: auto;
  }
`;

export const SGrid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  @media ${device.tabletPlus} {
    display: grid;
    grid-template-columns: 508px 1fr;
    gap: 32px;
  }
`;
