import { RenderFilter } from "components/table/subcomponent/renderFilter.component";
import { CustomTypography } from "components/customTypography/custom-typography";
import { getUniqueManufacturer } from "./utils";

export const seo = {
  title: "Поддерживаемые виды связи и оборудование",
  description: "Облачная платформа учета поддерживает все популярные виды связи и оборудование",
};

export const title = "Поддерживаемые\nвиды связи и\u00A0оборудование";
export const subtitle =
  "Облачная платформа учета поддерживает все популярные виды связи и\u00A0оборудование";

export const ASKUE_FILTER_DEFAULT = {
  categorys: [],
  manufacturer: [],
};

export enum EAskueCategoryKey {
  "electric_meters" = "Электросчетчики",
  "spodes" = "Электросчетчики СПОДЭС",
  "plc" = "PLC-концентраторы",
  "uspd" = "УСПД",
  "heatmeter" = "Расходомеры, тепло- и газосчетчики",
  "telemechanics" = "Устройства для систем телемеханики",
  "uso" = "Прочие модули УСО",
}

export type IAskueScheme = {
  [key: string]: {
    id: number;
    name: string;
    key?: string;
  }[];
};

export type IAskueEquipment = {
  id: number;
  manufacturer: string;
  categorys?: string;
  models: string[];
};

export type IAskueEquipments = {
  [key: string]: IAskueEquipment[];
};

export const ASKUE_EQUIPMENTS: IAskueEquipments = {
  electric_meters: [
    { id: 1, manufacturer: "Прософт-Системы", models: ["Цифровой счетчик ARIS ЕМ45"] },
    {
      id: 2,
      manufacturer: "Эльстер-Метроника",
      models: [
        "Альфа А1Т",
        "А1R",
        "ЕвроАльфа",
        "АльфаПлюс",
        "A1700",
        "A1800",
        "AS1440",
        "AS3500",
        "AS220",
        "A1140",
      ],
    },
    {
      id: 3,
      manufacturer: "ТехноЭнерго",
      models: [
        "ТЕ3000",
        "СЭТ-4ТМ.03МТ",
        "ПСЧ-4ТМ.05МКТ",
        "ПСЧ-4ТМ.05МДТ",
        "ТЕ1000",
        "ТЕ2000",
        "ПСЧ-4ТМ.05МНТ",
        "ПСЧ-4ТМ.06Т",
        "СЭБ-1ТМ.03Т",
        "СЭБ-1ТМ.04Т",
      ],
    },
    {
      id: 4,
      manufacturer: "ННПО имени М.В. Фрунзе",
      models: [
        "СЭТ-4ТМ.03МК",
        "СЭТ-4ТМ.01",
        "СЭТ-4ТМ.02",
        "СЭТ-4ТМ.03",
        "СЭТ‑4ТМ.02М",
        "СЭТ-4ТМ.03М",
        "ПСЧ-3ТМ.05",
        "ПСЧ-4ТМ.05",
        "ПСЧ-3ТМ.05М",
        "ПСЧ‑4ТМ.05М",
        "ПСЧ-3ТМ.05Д",
        "ПСЧ‑4ТМ.05Д",
        "ПСЧ-4ТМ.05МД",
        "ПСЧ‑4ТМ.05МК",
        "ПСЧ-4ТМ.05МН",
        "СЭТ-1М.01М",
        "СЭТ-1М.01М.01",
        "СЭБ-1ТМ.01",
        "СЭБ-1ТМ.02(Д,М)",
        "СЭБ-1ТМ.03",
        "ПСЧ-4ТА.03.2",
        "ПСЧ-3ТА.03.2",
        "ПСЧ-3ТА.07",
        "ПСЧ-3АРТ.07(Д)",
        "ПСЧ-3АРТ.08-09",
        "СЭБ‑2А.07",
        "ПСЧ-3А.06Т",
      ],
    },
    { id: 5, manufacturer: "ЛЭМЗ", models: ["ЦЭ2727"] },
    {
      id: 6,
      manufacturer: 'НПФ "Маяк"',
      models: ["МАЯК-101АТ", "МАЯК-102AT", "МАЯК-301АРТ", "МАЯК-302АРТ"],
    },
    { id: 7, manufacturer: "ГРПЗ", models: ["СЭТ 3а"] },
    { id: 8, manufacturer: "МЗЭП", models: ["СТС5605"] },
    {
      id: 9,
      manufacturer: "ПКК Миландр",
      models: [
        "МИЛУР-104",
        "МИЛУР-105",
        "МИЛУР-107",
        "МИЛУР-107S",
        "МИЛУР-304",
        "МИЛУР-305",
        "МИЛУР-306",
        "МИЛУР-307",
        "МИЛУР-307.52",
        "МИЛУР-3-307.62",
        "МИЛУР-3-307S",
      ],
    },
    { id: 10, manufacturer: "ELGAMA-ELEKTRONIKA", models: ["EPQS", "GAMA300", "EMS"] },
    {
      id: 11,
      manufacturer: "Корпорация Сайман",
      models: ["Отан САР4У", "Отан САР3У", "ДАЛА", "ОРМАН"],
    },
    { id: 12, manufacturer: 'НТЦ "АРГО"', models: ["МУР1001.5 SmartON EE1 (BIN2)"] },
    { id: 13, manufacturer: "ACTARIS", models: ["SL7000", "ACE6000", "ARIS"] },
    {
      id: 14,
      manufacturer: "Iskraemeco, Ltd.",
      models: [
        "ISKRA MT851",
        "ISKRA TE851",
        "ISKRA МТ855",
        "ISKRA MT831",
        "ISKRA MT860",
        "ISKRA МТ372, МТ382",
        "ISKRA MT880",
        "ISKRA AM550",
      ],
    },
    {
      id: 15,
      manufacturer: "Концерн ЭНЕРГОМЕРА",
      models: [
        "ЦЭ6850(М)",
        "ЦЭ6823М",
        "ЦЭ6822",
        "CE102",
        "CE102 R5.1",
        "CE102M",
        "CE201",
        "CE207 (СПОДЭС)",
        "CE208 (IEC 1107-96, СПОДЭС/DLMS)",
        "CE301M",
        "CE306",
        "CE307",
        "CE307 (СПОДЭС)",
        "CE301",
        "CE303",
        "CE304",
        "CE308 (IEC61107, СПОДЭС/DLMS)",
      ],
    },
    {
      id: 16,
      manufacturer: "Инкотекс",
      models: [
        "Меркурий 230",
        "Меркурий 231",
        "Меркурий 232",
        "Меркурий 233",
        "Меркурий 234",
        "Меркурий 236",
        "Меркурий 203.2TD",
        "Меркурий 204",
        "Меркурий 203.2Т",
        "Меркурий 206",
        "Меркурий 200.04",
        "Меркурий 200.02(05)",
      ],
    },
    {
      id: 17,
      manufacturer: "ПЗИП",
      models: ["Вектор-3АRT(2)", "Вектор-3A(T)", "Вектор-300", "Вектор-300 v35"],
    },
    {
      id: 18,
      manufacturer: "Power Measurement Ltd.",
      models: ["ION 6200", "ION 7500", "ION 8300", "ION 8600", "ION 7330", "ION 7650"],
    },
    {
      id: 19,
      manufacturer: "Holley Metering Ltd.",
      models: ["DSSD546", "DTSD536", "DTS543", "DSS533", "DTS541", "DSS531", "DTSD545"],
    },
    { id: 20, manufacturer: "Гран-Система-С", models: ["CC-301"] },
    { id: 21, manufacturer: "Landis&Gyr AG", models: ["ZMD400CT"] },
    { id: 22, manufacturer: 'НПП "Электромеханика"', models: ["ПЦ6806-17", "ПЦ6806-03М"] },
    { id: 23, manufacturer: "Системы связи и телемеханики", models: ["КИПП-2М"] },
    { id: 24, manufacturer: "Систел Автоматизация", models: ["Протон-К", "ФОТОН"] },
    {
      id: 25,
      manufacturer: "Satec",
      models: ["PM130E(H)", "PM175", "EM132", "EM133", "EM133R", "EM720", "BFM136", "BFM II"],
    },
    {
      id: 26,
      manufacturer: 'НПО "МИР"',
      models: ["МИР С-01", "МИР C-02", "МИР С-03", "МИР С-04", "МИР C-05", "МИР C-07"],
    },
    { id: 27, manufacturer: 'НТЦ "ГОСАН"', models: ["БИМ 3/4/5"] },
    {
      id: 28,
      manufacturer: "РиМ",
      models: [
        "РиМ 189.21",
        "РиМ 189.23",
        "РиМ 189.25",
        "РиМ 189.27",
        "РиМ 289.21",
        "РиМ 289.23",
        "РиМ 489.03-06",
        "РИМ-489.13",
        "РИМ-489.14-17",
        "РиМ-489.07",
        "РиМ 189.22",
        "РиМ 189.24",
        "РиМ 189.26",
        "РиМ 189.28",
        "РиМ 289.22",
        "РиМ 289.24",
        "РиМ 489.24",
        "РиМ 489.26-29",
        "РиМ-489.30",
        "РиМ-489.32",
        "РиМ-384",
        "РиМ-389",
      ],
    },
    { id: 29, manufacturer: 'НПП "Энерготехника"', models: ["РЕСУРС-Е4", " РЕСУРС-UF2-4.30"] },
    { id: 30, manufacturer: "МИРТЕК", models: ["МИРТЕК-1", "МИРТЕК-3"] },
    {
      id: 31,
      manufacturer: "Тайпит",
      models: [
        "Нева МТ1хх",
        "Нева МТ3хх",
        "Нева MT115",
        "Нева МТ315",
        "Нева СП1",
        "Нева СП3",
        "Нева МТ1*2AR",
        "Нева СТ4",
      ],
    },
    { id: 32, manufacturer: "Алгоритм", models: ["BINOM334i", "BINOM339i"] },
    { id: 33, manufacturer: 'НПП "Марс-Энерго"', models: ["СКВТ-Ф-МАРСЕН"] },
    { id: 34, manufacturer: "Матрица", models: ["NP73E", "AD11", "AD13"] },
    { id: 35, manufacturer: "Тошэлектроаппарат", models: ["TE73"] },
    {
      id: 36,
      manufacturer: 'РУП "Гомельэнерго"',
      models: ["Энергия-9BY СТК3", "Аист-1", "Аист-3"],
    },
    { id: 37, manufacturer: "Tesmec", models: ["SMT"] },
    { id: 38, manufacturer: 'Инженерный центр "Энергосервис"', models: ["ESM"] },
    {
      id: 39,
      manufacturer: "Различные производители",
      models: ["СПОДЭС-A", "СПОДЭС-B", "СПОДЭС-C", "СПОДЭС-D"],
    },
    { id: 40, manufacturer: "EMH metering GmbH & Co. KG.", models: ["LZQJ-XC"] },
    { id: 41, manufacturer: 'ООО "СИ-АРТ"', models: ["СТЭМ-300"] },
    {
      id: 42,
      manufacturer: "Schneider Electric",
      models: ["IEM3255", "PowerLogic PM5110", "PM5111"],
    },
    { id: 43, manufacturer: "Телематические Решения", models: ["ФОБОС 1", "ФОБОС 3", "ФОБОС 3Т"] },
    { id: 44, manufacturer: "Системы и технологии", models: ["КВАНТ ST2000-12"] },
    { id: 45, manufacturer: "Электролаборатория ЭЛПЭК", models: ["ЭМИР-1", "ЭМИР-3"] },
  ],
  spodes: [
    {
      id: 46,
      manufacturer: "ТехноЭнерго",
      models: ["ТЕ3000", "ТЕ1000", "ТЕ2000", "ПСЧ-4ТМ.06Т", "СЭБ-1ТМ.04Т"],
    },
    { id: 47, manufacturer: "Инкотекс", models: ["Меркурий 23х", "Меркурий 204"] },
    {
      id: 48,
      manufacturer: "МИРТЕК",
      models: [
        "МИРТЕК-1",
        "МИРТЕК-12-РУ",
        "МИРТЕК-3",
        "МИРТЕК-32-РУ",
        "МИРТЕК-135-РУ",
        "МИРТЕК-212-РУ",
        "МИРТЕК-232-РУ",
      ],
    },
    { id: 49, manufacturer: "Электролаборатория ЭЛПЭК", models: ["ЭМИР-1", "ЭМИР-3"] },
    {
      id: 50,
      manufacturer: "Лартех",
      models: ["LarTech Metering Service (CE208 Концерн ЭНЕРГОМЕРА)"],
    },
    { id: 51, manufacturer: "ЭМИС", models: ["ЭМИС-ЭЛЕКТРА 971", "ЭМИС-ЭЛЕКТРА 976"] },
    { id: 52, manufacturer: "Курганский приборостроительный завод", models: ["М2М-1", "М2М-3"] },
    { id: 53, manufacturer: "НПК РоТеК", models: ["РОТЕК РТМ-01", "РОТЕК РТМ-03"] },
    { id: 54, manufacturer: 'НПЦ "Спектр"', models: ["SP 101", "SP 301"] },
    { id: 55, manufacturer: "Промэнерго", models: ["i-prom.1", "i-prom.3"] },
    { id: 56, manufacturer: 'НПП "ТЕПЛОВОДОХРАН"', models: ["Пульсар-1Ф4Т", "Пульсар-3Ф4Т"] },
    { id: 57, manufacturer: "РОКИП", models: ["SM40X", "ST40X"] },
    {
      id: 58,
      manufacturer: "Завод НАРТИС",
      models: ["НАРТИС-100", "НАРТИС-И100", "НАРТИС-300", "НАРТИС-И300"],
    },
  ],
  plc: [
    { id: 59, manufacturer: "РиМ", models: ["РиМ МКС 099.02", "РиМ МКС 099.03"] },
    { id: 60, manufacturer: "Корпорация Сайман", models: ["SAIMAN-1000E"] },
    { id: 61, manufacturer: "ECHELON", models: ["DC-1000"] },
    {
      id: 62,
      manufacturer: "Инкотекс",
      models: ["Меркурий 225.1/11", "Меркурий 225.2/21", "Меркурий 250"],
    },
    { id: 63, manufacturer: "ННПО имени М.В. Фрунзе", models: ["УСД-2.01", "УСД-2.04 (Микрон 2)"] },
    { id: 64, manufacturer: 'НТЦ "ГОСАН"', models: ["УСПД БИМ 42хх"] },
    {
      id: 65,
      manufacturer: "Концерн ЭНЕРГОМЕРА",
      models: ["УСПД 164-01Б", "УСПД 164-01М", "CE805", "CE805M"],
    },
    { id: 66, manufacturer: 'НПО "МИР"', models: ["УСПД-01", "МК-01"] },
    { id: 67, manufacturer: "Эльстер-Метроника", models: ["RTU+Server"] },
    { id: 68, manufacturer: "Матрица", models: ["УСПД RTR7E", "УСПД RTR8A", "УСПД RTR512"] },
    { id: 69, manufacturer: "Неро Электроникс", models: ["УСПД 3021"] },
  ],
  uspd: [
    {
      id: 70,
      manufacturer: "Прософт-Системы",
      models: ["ЭКОМ", "Многофункциональный контроллер ARIS", "SOFTBasic контроллер"],
    },
    { id: 71, manufacturer: "Эльстер-Метроника", models: ["RTU-325", "RTU-327"] },
    {
      id: 72,
      manufacturer: "Системы и технологии",
      models: [
        "Сикон С10",
        "Сикон С50",
        "Сикон С70",
        "Сикон С120",
        "SM160",
        "SM160-02",
        "SM160-02M",
      ],
    },
    {
      id: 73,
      manufacturer: "Различные производители",
      models: ["Modbus-RTU, ASCII", "TCP контроллер (функции 1-5, 16)"],
    },
    {
      id: 74,
      manufacturer: "ИндаСофт",
      models: ['MOSCAD-М (только для СуперФлоу-21В ЗАО "Совтигаз")'],
    },
    { id: 75, manufacturer: "Волгаэнергоприбор", models: ["ВЭП-01"] },
    { id: 76, manufacturer: "Современные Радио Технологии", models: ["СТРИЖ-Сервер"] },
    {
      id: 77,
      manufacturer: "ПиЭлСи Технолоджи",
      models: ["Сервер доступа к данным TOPAZ IEC DAS MX240"],
    },
    { id: 78, manufacturer: "Инкотекс", models: ["NB-IOT"] },
    { id: 79, manufacturer: "Телематические Решения", models: ["Waviot"] },
    { id: 80, manufacturer: "Лартех", models: ["LarTech Metering Service (LarTechMS)"] },
    {
      id: 81,
      manufacturer: 'АО "Связь инжиниринг М"',
      models: ["УМ-31RTU", "УМ-40RTU.2"],
    },
    {
      id: 82,
      manufacturer: "TELEOFIS",
      models: ["RTU602"],
    },
    {
      id: 83,
      manufacturer: "МИРТЕК",
      models: ["МИРТ-881"],
    },
    {
      id: 84,
      manufacturer: "Корпорация Сайман",
      models: ["Saiman-сервер (LoRaWAN)"],
    },
  ],
  heatmeter: [
    {
      id: 85,
      manufacturer: "Взлёт",
      models: [
        "Взлёт УРСВ-010М",
        "Взлёт УРСВ-020",
        "Взлёт УРСВ-110",
        "Взлёт УРСВ-510",
        "Взлёт УРСВ-542",
        "Взлёт УРСВ-510Ц",
        "Взлёт УРСВ-520Ц",
        "Взлёт УРСВ-522Ц",
        "Взлёт УРСВ-530Ц",
        "Взлёт УРСВ-540Ц",
        "Взлёт УРСВ-542Ц",
        "Взлёт УРСВ-544Ц",
        "Взлёт ТСР",
        "ТСРВ-020",
        "ТСРВ-021",
        "ТСРВ-022",
        "ТСРВ-023",
        "ТСРВ-027 (ТСР-М/ТСР-027)",
        "ТСРВ-030",
        "ТСРВ‑031",
        "ТСРВ‑032",
        "ТСРВ-024(М)",
        "Взлёт ТСРВ-026(М)",
        "ТСРВ-033",
        "ТСРВ-034",
        "ТСРВ-043",
        "Взлёт РСЛ",
        "Взлёт ЭМ Эксперт",
        "ИВК-102",
      ],
    },
    {
      id: 86,
      manufacturer: 'НПФ "Логика"',
      models: [
        "СПТ 942.01-06",
        "СПТ 943.1 (ниже версии v2)",
        "СПТ 943.2",
        "СПТ 961",
        "СПТ 961М",
        "СПТ 961.1-2",
        "СПГ 741",
        "СПГ 761",
        "СПГ 762",
        "СПГ 762.1-2",
        "СПГ 761.1-2",
        "СПТ 941.01-08",
        "СПТ 941.10-11",
        "СПТ 941.20",
        "СПТ 944",
        "СПТ 962",
      ],
    },
    {
      id: 87,
      manufacturer: "ТБН Энергосервис",
      models: ["ТБН КМ-5-1", "ТБН КМ-5-2", "ТБН РМ-5-1", "ТБН РМ-5-Т"],
    },
    { id: 88, manufacturer: "Интелприбор", models: ["Многоканальный теплосчётчик МКТС"] },
    { id: 89, manufacturer: "Fischer & Porter", models: ["Fisher NOC"] },
    {
      id: 90,
      manufacturer: "ИВП Крейт",
      models: ["ТЭКОН-10", "ТЭКОН-17 (протокол FT1.2)", "ТЭКОН-19"],
    },
    { id: 91, manufacturer: 'Фирма "Борец"', models: ["Каскад1-100"] },
    {
      id: 92,
      manufacturer: 'ОКБ "Маяк"',
      models: [
        "ИМ2300 K",
        "ИМ2300 M",
        "ИМ2300 Z",
        "ИМ2300 A",
        "ИМ2300 B",
        "ИМ2300 C",
        "ИМ2300 D",
        "ИМ2300 E",
        "ИМ2300 F",
      ],
    },
    { id: 93, manufacturer: 'НПФ "Вымпел"', models: ["Гиперфлоу-3ПМ"] },
    {
      id: 94,
      manufacturer: 'НПП "Уралтехнология"',
      models: ["Эльф", "Карат", "Карат-М", "Карат-307 (до 2015 года выпуска)"],
    },
    {
      id: 95,
      manufacturer: 'НПФ "Теплоком"',
      models: ["Теплосчетчик ВКТ-5", "Теплосчетчик ВКТ-9", "Теплосчетчик  ВКТ-7"],
    },
    {
      id: 96,
      manufacturer: "Эльстер Газэлектроника",
      models: [
        "Корректор объёма газа ЕК 260",
        "Корректор объема газа EK270",
        "Корректор объема газа ТС215",
        "Корректор объема газа ТС220",
      ],
    },
    {
      id: 97,
      manufacturer: "Турбулентность ДОН",
      models: ["Расходомер Turbo Flow GFG-F (до 2017 года выпуска)"],
    },
    { id: 98, manufacturer: "АРВАС", models: ["Теплосчётчик ТЭМ-104", "Теплосчётчик ТЭМ-106"] },
    {
      id: 99,
      manufacturer: 'ИПФ "Сибнефтеавтоматика"',
      models: ["Блок контроля теплоты БКТ.М", "Миконт-186"],
    },
    {
      id: 100,
      manufacturer: 'НПП "ИРВИС"',
      models: ["Одноканальные ВРСГ-1/РИ-3", "Одноканальные РИ-5 (РС4(М), РС4(М)-Ультра)"],
    },
    {
      id: 101,
      manufacturer: 'СКБ "Промавтоматика"',
      models: ["Вычислитель УВП-280", "Вычислитель УВП-280А.01", "Вычислитель УВП-280Б.01"],
    },
    { id: 102, manufacturer: 'ПНП "Сигнур"', models: ["ЭХО-Р-02", "Акрон-01-1"] },
    {
      id: 103,
      manufacturer: "Промышленная группа “Метран”",
      models: ["Счётчик газа Метран-333", "Счётчик пара Метран-334"],
    },
    {
      id: 104,
      manufacturer: "ИВК-САЯНЫ",
      models: ["Счетчик-расходомер ВРтК-2000 РМД через УСПД «МОСТ»"],
    },
    {
      id: 105,
      manufacturer: "Компания Kamstrup,  Дания",
      models: ["MULTICAL-402", "MULTICAL-602"],
    },
    { id: 106, manufacturer: "ИРВИС, Москва", models: ["ИРВИКОН СВ-200"] },
    { id: 107, manufacturer: "Промсервис, Дмитровград", models: ["ПРАМЕР-52ХХ"] },
    {
      id: 108,
      manufacturer: "Emerson",
      models: ["Floboss S600  (в ограниченном наборе конфигураций)"],
    },
    { id: 109, manufacturer: 'НИЦ "Инкомсистем"', models: ["АБАК+ (АБАК 1-2-1)"] },
    { id: 110, manufacturer: "Термотроник", models: ["ТВ7", "АДИ"] },
    { id: 111, manufacturer: 'НПО "Промприбор"', models: ["ТМК-Н120"] },
    {
      id: 112,
      manufacturer: 'НПП "ТЕПЛОВОДОХРАН"',
      models: ["Пульсар-24М", "Пульсар-2М", "Пульсар-6М"],
    },
  ],
  telemechanics: [
    { id: 113, manufacturer: 'Группа предприятий "АЛЕКТО"', models: ["МИП AET"] },
    { id: 114, manufacturer: 'НПП "Электромеханика"', models: ["ПЦ6806"] },
    { id: 115, manufacturer: "Прософт-Системы", models: ["TS32", "TC32", "TC4", "ТМ32"] },
    { id: 116, manufacturer: 'МНПП "Антракс"', models: ["Указатель положения РПН УП-23"] },
    { id: 117, manufacturer: "Siemens", models: ["Преобразователь Simeas P 7KG7100"] },
    { id: 118, manufacturer: "Satec", models: ["Преобразователь PM130P PLUS"] },
    { id: 119, manufacturer: 'НПП "Электронные Табло"', models: ['Электронное табло "Рубин"'] },
    { id: 120, manufacturer: 'МНПП "Электроприбор"', models: ["ЦП 8506"] },
    {
      id: 121,
      manufacturer: "Различные производители",
      models: [
        "Протокол ТМ800А",
        "Модули с протоколами МЭК870-5.101, МЭК870-5.104",
        "Модули с протоколами ModbusRTU, ASCII, TCP",
      ],
    },
    { id: 122, manufacturer: 'ОДО "Энергоприбор"', models: ["МИП ET"] },
    {
      id: 123,
      manufacturer: "НПФ «ПРОЭЛ», Санкт‑Петербург",
      models: ["Устройство оптоволоконной дуговой защиты ОВОД-МД"],
    },
    { id: 124, manufacturer: "Дон-РТСофт", models: ["Протокол ЦСПА-FT3 (связь со Smart-Base)"] },
    { id: 125, manufacturer: "Энерго-Союз", models: ["МИП ЦА9256", "МИП ЦВ9257"] },
    {
      id: 126,
      manufacturer: "РАДИУС Автоматика",
      models: [
        "Микропроцессорное устройство защиты, автоматики, управления и сигнализации Сириус-2",
      ],
    },
    { id: 127, manufacturer: "Vaisala", models: ["Метеокомплекс WXT520"] },
  ],
  uso: [
    {
      id: 128,
      manufacturer: "Прософт-Системы",
      models: ["Устройство сбора и обработки данных с дискретных/аналоговых датчиков DAS16"],
    },
    { id: 129, manufacturer: "Advantech Co., Ltd", models: ["Модули ADAM-40xx"] },
    {
      id: 130,
      manufacturer: 'НТЦ "АРГО"',
      models: ["Шлюз МУР1001.2RC", "МУР1001.9 (ARGOserver)", "RMAv4 Модем"],
    },
    { id: 131, manufacturer: 'ФНПЦ ФГУП "ПО "Старт"', models: ["ЕМ441М", "ЕМ443М", "ИСТОК-ТМ"] },
    {
      id: 132,
      manufacturer: "Различные производители",
      models: ["ГЛОНАСС-приёмники (протокол NMEA)"],
    },
    {
      id: 133,
      manufacturer: "Hilscher Gesellschaft für Systemautomation mbH",
      models: ["Платы-контроллеры шины Profibus: CIFxxxx, COMxxxx"],
    },
    { id: 134, manufacturer: 'ЗАО НПП "Дана-Терм"', models: ["ИТВ2605-8"] },
    { id: 135, manufacturer: 'НПП "Метра"', models: ["М0801"] },
    {
      id: 136,
      manufacturer: "American Power Conversion Corp.",
      models: ["Источник бесперебойного питания APC Smart"],
    },
    { id: 137, manufacturer: "ЛЭМЗ", models: ["Устройство передачи данных УПД-600"] },
    {
      id: 138,
      manufacturer: 'АО "ПромСервис"',
      models: ["Блок автоматический регистрационно-связной БАРС-02"],
    },
    { id: 139, manufacturer: "ННПО имени М.В. Фрунзе", models: ["Модем PLC M-2.01"] },
    {
      id: 140,
      manufacturer: "Инкотекс",
      models: ["GSM-шлюз Меркурий-228 (без буферизации пакетов)"],
    },
    {
      id: 141,
      manufacturer: "ПКК Миландр",
      models: ["Милур IC Преобразователь интерфейса (MilurlCsrv)"],
    },
    { id: 142, manufacturer: "АТБ Электроника", models: ["ATBNB-сервер"] },
    { id: 143, manufacturer: "Взлёт", models: ["Адаптер Взлёт АССВ-030"] },
    { id: 144, manufacturer: "РиМ", models: ["RimServer"] },
    { id: 145, manufacturer: "Различные производители", models: ["IdTCPsrv"] },
    { id: 146, manufacturer: "Инкотекс", models: ["MercurySrv"] },
    { id: 147, manufacturer: "TELEOFIS", models: ["TeleofiSrv"] },
    {
      id: 148,
      manufacturer: "Тайпит",
      models: ["MTserver (включая протокол MOST), NetLink2, ETRGserver"],
    },
    {
      id: 149,
      manufacturer: "ТехноЭнерго",
      models: ["TEserver (через коммуникаторы TE101, C-1.02/03)"],
    },
    { id: 150, manufacturer: "Концерн ЭНЕРГОМЕРА", models: ["CENCserver"] },
    { id: 151, manufacturer: "НПК РоТеК", models: ["РОТЕК-srv"] },
    { id: 152, manufacturer: "Различные производители", models: ["SPODES-srv"] },
  ],
};

export const ASKUE_SCHEME_HARDCODED: IAskueScheme = {
  categorys: [
    { id: 1, name: "Электросчетчики", key: "electric_meters" },
    { id: 2, name: "Электросчетчики СПОДЭС", key: "spodes" },
    { id: 3, name: "PLC-концентраторы", key: "plc" },
    { id: 4, name: "УСПД", key: "uspd" },
    { id: 5, name: "Расходомеры, тепло- и газосчетчики", key: "heatmeter" },
    { id: 6, name: "Устройства для систем телемеханики", key: "telemechanics" },
    { id: 7, name: "Прочие модули УСО", key: "uso" },
  ],
  manufacturer: getUniqueManufacturer(ASKUE_EQUIPMENTS),
};

export const ASKUE_EQUIPMENTS_FILTERING = {
  uso: [
    {
      id: 1,
      name: "Прософт-Системы",
      models: ["Устройство сбора и обработки данных с дискретных/аналоговых датчиков DAS16"],
    },
    { id: 2, name: "Advantech Co., Ltd", models: ["Модули ADAM-40xx"] },
    {
      id: 3,
      name: 'НТЦ "АРГО"',
      models: ["Шлюз МУР1001.2RC", "МУР1001.9 (ARGOserver)", "RMAv4 Модем"],
    },
    { id: 4, name: 'ФНПЦ ФГУП "ПО "Старт"', models: ["ЕМ441М", "ЕМ443М", "ИСТОК-ТМ"] },
    { id: 5, name: "Различные производители", models: ["ГЛОНАСС-приёмники (протокол NMEA)"] },
    {
      id: 6,
      name: "Hilscher Gesellschaft für Systemautomation mbH",
      models: ["Платы-контроллеры шины Profibus: CIFxxxx, COMxxxx"],
    },
    { id: 7, name: 'ЗАО НПП "Дана-Терм"', models: ["ИТВ2605-8"] },
    { id: 8, name: 'НПП "Метра"', models: ["М0801"] },
    {
      id: 9,
      name: "American Power Conversion Corp.",
      models: ["Источник бесперебойного питания APC Smart"],
    },
    { id: 10, name: "ЛЭМЗ", models: ["Устройство передачи данных УПД-600"] },
    {
      id: 11,
      name: 'АО "ПромСервис"',
      models: ["Блок автоматический регистрационно-связной БАРС-02"],
    },
    { id: 12, name: "ННПО имени М.В. Фрунзе", models: ["Модем PLC M-2.01"] },
    { id: 13, name: "Инкотекс", models: ["GSM-шлюз Меркурий-228 (без буферизации пакетов)"] },
    { id: 14, name: "ПКК Миландр", models: ["Милур IC Преобразователь интерфейса (MilurlCsrv)"] },
    { id: 15, name: "АТБ Электроника", models: ["ATBNB-сервер"] },
    { id: 16, name: "Взлёт", models: ["Адаптер Взлёт АССВ-030"] },
    { id: 17, name: "РиМ", models: ["RimServer"] },
    { id: 18, name: "Различные производители", models: ["IdTCPsrv"] },
    { id: 19, name: "Инкотекс", models: ["MercurySrv"] },
    { id: 20, name: "TELEOFIS", models: ["TeleofiSrv"] },
    { id: 21, name: "Тайпит", models: ["MTserver (включая протокол MOST), NetLink2, ETRGserver"] },
    { id: 22, name: "ТехноЭнерго", models: ["TEserver (через коммуникаторы TE101, C-1.02/03)"] },
    { id: 23, name: "Концерн ЭНЕРГОМЕРА", models: ["CENCserver"] },
    { id: 24, name: "НПК РоТеК", models: ["РОТЕК-srv"] },
    { id: 25, name: "Различные производители", models: ["SPODES-srv"] },
  ],
};

function multiSelectCategorys(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(String(row.original["categorys"])));
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Производитель"
      />
    ),
    accessor: "manufacturer",
    width: 250,
    canFilter: true,
    filter: "includesSome",
    Cell: ({ value }) => (
      <div style={{ minWidth: 250, maxWidth: 250 }}>
        <CustomTypography variant="p4_medium" font="comp">
          {value}
        </CustomTypography>
      </div>
    ),
  },
  {
    Header: ({ column }) => (
      <RenderFilter isSorted={false} isSortedDesc={false} title="Модель" disableSortBy />
    ),
    accessor: "models",
    width: 230,
    disableSortBy: true,
    canFilter: true,
    Cell: ({ value }) => (
      <div>
        <CustomTypography variant="p4_regular">{value.join(", ")}</CustomTypography>
      </div>
    ),
  },
  {
    accessor: "categorys",
    canFilter: true,
    filter: multiSelectCategorys,
    maxWidth: 0,
    Cell: false,
  },
];

export const tableMobStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Производитель"
      />
    ),
    accessor: "manufacturer",
    width: 150,
    canFilter: true,
    filter: "includesSome",
    Cell: ({ value }) => (
      <div style={{ minWidth: 150, maxWidth: 150 }}>
        <CustomTypography variant="p4_medium" font="comp">
          {value}
        </CustomTypography>
      </div>
    ),
  },
  {
    Header: ({ column }) => (
      <RenderFilter isSorted={false} isSortedDesc={false} title="Модель" disableSortBy />
    ),
    accessor: "models",
    width: 230,
    disableSortBy: true,
    canFilter: true,
    Cell: ({ value }) => (
      <div>
        <CustomTypography variant="p4_regular">{value.join(", ")}</CustomTypography>
      </div>
    ),
  },
  {
    accessor: "categorys",
    canFilter: true,
    filter: multiSelectCategorys,
    maxWidth: 0,
    Cell: false,
  },
];

export const titleByKey = {
  categorys: "Категория",
  manufacturer: "Производитель",
};
