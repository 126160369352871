import styled from "@emotion/styled";
import theme from "theme";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 80px 0 80px 0;
  text-align: left;
`;

export const SParag = styled.p`
  margin-bottom: 20px;
  display: inline-block;
`;

export const SList = styled.ul`
  padding-top: 22px;
  margin-left: 10px;
`;

export const SItem = styled.li`
  position: relative;
  padding-left: 15px;
  margin-bottom: 22px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    height: 5px;
    width: 5px;
    border-radius: 3px;
    background-color: ${theme.mtsColor.background.primary.darkMode};
  }
`;
