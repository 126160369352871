import styled from "@emotion/styled";
import { device } from "theme";
import { CustomTypography } from "components/customTypography/custom-typography";

import bgSectionList from "./images/section-list-bg.png";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SSection = styled.section`
  padding: 80px 0;
  width: 100%;

  @media ${device.miniTabletNeg} {
    padding: 64px 0;
  }
`;

export const SAdvantage = styled.section`
  padding: 80px 0;
  width: 100%;

  @media ${device.miniTabletNeg} {
    padding: 64px 0 0 0;
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

export const SListSection = styled.section`
  padding: 80px 0;
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  background-image: url(${bgSectionList});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80px 0;

  & section {
    max-width: 700px;
  }

  @media ${device.miniTabletNeg} {
    background-image: none;
    padding: 64px 0;
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 32px;
  row-gap: 32px;

  @media ${device.tabletNeg} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    rowgap: 16px;
  }

  @media ${device.miniTabletNeg} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SAbonent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;

  & > img {
    max-width: 150px;
    max-height: 150px;
  }

  @media ${device.miniTabletPlus} {
    flex-direction: row;
    align-items: flex-start;

    & > img {
      max-width: 250px;
      max-height: 250px;
      width: 100%;
    }
  }
`;

export const SContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 825px;
`;

export const SAbonTitle = styled(CustomTypography)`
  text-align: left;
  text-transform: uppercase;
`;

export const SAbonText = styled(CustomTypography)`
  text-align: left;
`;
