import styled from "@emotion/styled";
import { device } from "theme";
import { MTSButton } from "components/mts-button/mts-button";
import { HEADER_HEIGHT } from "components/header/styles";

export const SMenu = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  right: -130vw;
  bottom: 0;
  max-width: 400px;
  padding: 36px 20px;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  z-index: 10;
  transition: all 0.5s ease-out;
  overflow: auto;

  ${(props) =>
    props.isOpen
      ? `
	  	z-index: 1400;
  		right: 0;
  		transition: all .5s ease-out;
  	  `
      : ``}

  @media ${device.mobileNeg} {
    width: 100%;
    max-width: inherit;
  }
`;

export const SOverlay = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 10;
  transition: all 0.8s ease-out;
`;

export const SItem = styled.div`
  padding: 20px 0 20px 20px;
  text-align: left;
  &:active {
    background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  }

  & a {
    text-decoration: none;
  }
`;

export const SBtns = styled.div`
  padding-top: 45px;
  & > div {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    & button {
      width: 100%;
      max-width: 287px;
    }
  }
`;

export const SOutBtn = styled(MTSButton)`
  & .MuiButtonBase-root {
    & svg path {
      fill: black;
    }
  }
`;
